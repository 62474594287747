import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  NavLink, Outlet, useLocation, useOutletContext,
} from 'react-router-dom';
import Loader from '../../../components/Loader';

import styles from './custom.module.scss';

const Custom = () => {
  const {
    control, watch, setValue, reset, options, changedAt, submit, errors, setError, clearErrors,
  } = useOutletContext();
  const { t } = useTranslation();
  const { search } = useLocation();
  const { requestLoading } = useSelector((store) => store.indicatorReducer);

  const countType = watch('config.params.value.value');
  return (
    <div className={styles.custom}>
      <nav className={`${options?.data ? styles.active : ''}`}>
        <NavLink to={`params${search}`} className={({ isActive }) => (isActive ? styles.active : '')} >{t('indicator.nav.params')}</NavLink>
        <NavLink to={`graph${search}`} className={({ isActive }) => (isActive ? styles.active : `${!watch('config.params.value') ? styles.disabled : null}`)} >{t('indicator.nav.graph')}</NavLink>
        <NavLink to={`filters${search}`} className={({ isActive }) => (isActive ? styles.active : `${!watch('data') || countType === 'COUNT_ABSOLUT_BRUT' || countType === 'COUNT_ABSOLUT' ? styles.disabled : null}`)} >{t('indicator.nav.filters')}</NavLink>
        <NavLink to={`legend${search}`} className={({ isActive }) => (isActive ? styles.active : `${!watch('data') || watch('data')?.length === 0 ? styles.disabled : null}`)} >{t('indicator.nav.legend')}</NavLink>
        <NavLink to={`preview${search}`} className={({ isActive }) => (isActive ? styles.active : `${!watch('data') || watch('data')?.length === 0 ? styles.disabled : null}`)} >{t('btn.preview')}</NavLink>
      </nav>
      {requestLoading
        ? (<div className={styles.loader}>
          <Loader />
        </div>)
        : (<div className={styles.tabs}>
        <Outlet context={{
          control,
          options,
          watch,
          setValue,
          reset,
          changedAt,
          submit,
          errors,
          setError,
          clearErrors,
        }}/>
      </div>)
      }
    </div>
  );
};

export default Custom;
