import {
  DASHBOARDS_GET,
  DASHBOARDS_GET_BY_ID,
  DASHBOARD_POST,
  DASHBOARD_PUT,
  DASHBOARD_DUPLICATE,
  DASHBOARD_DELETE,
  DASHBOARD_LOADING,
  DASHBOARD_ERROR,
  GET_USER,
  GET_LIST,
  SET_TOAST,
  DASHBOARDS_UPDATE_FAVORITE,
  DASHBOARD_UPDATE_ERRORS,
} from '../actions/types';

const DEFAULT_STATE = {
  user: null,
  dashboard: null,
  dashboards: [],
  list: {},
  toast: null,
  isLoading: [],
  updateErrors: null,
  redirectFavorite: true,
  error: null,
  changedAt: Date.now(),
};

const dashboardReducer = (state = DEFAULT_STATE, action) => {
  let updatedState = {};
  const updateDashboards = state.dashboards || [];
  const dashboardIndex = (updateDashboards || []).findIndex((p) => (
    p._id === action.payload?.dashboard?._id
  ));
  switch (action.type) {
    case SET_TOAST:
      updatedState = {
        ...state,
        toast: action.payload,
      };
      break;
    case GET_LIST:
      updatedState = {
        ...state,
        list: action.payload.list,
      };
      break;
    case DASHBOARDS_UPDATE_FAVORITE:
      updatedState = {
        ...state,
        redirectFavorite: false,
        dashboards: action.payload,
      };
      break;
    case DASHBOARDS_GET:
      updatedState = {
        ...state,
        dashboard: null,
        dashboards: action.payload.dashboards,
        list: action.payload.list,
        redirectFavorite: true,
        updateErrors: null,
        error: null,
      };
      break;
    case DASHBOARDS_GET_BY_ID:
      updatedState = {
        ...state,
        dashboard: action.payload.dashboard,
        isLoading: state.isLoading?.filter((d) => d !== DASHBOARDS_GET_BY_ID),
        updateErrors: null,
        error: null,
      };
      break;
    case DASHBOARD_POST:
      updatedState = {
        ...state,
        changedAt: Date.now(),
        dashboard: action.payload?.dashboard,
        dashboards: [action.payload, ...state.dashboards],
        isLoading: state.isLoading?.filter((d) => d !== DASHBOARD_POST),
        error: null,
      };
      break;
    case DASHBOARD_PUT:
      if (typeof dashboardIndex === 'number') {
        updateDashboards[dashboardIndex] = action.payload.dashboard;
      }
      updatedState = {
        ...state,
        dashboards: updateDashboards,
        dashboard: action.payload?.dashboard,
        changedAt: Date.now(),
        isLoading: state.isLoading?.filter((d) => d !== `DASHBOARD_PUT-${action.payload.dashboard._id}`),
        error: null,
      };
      break;
    case DASHBOARD_DUPLICATE:
      updatedState = {
        ...state,
        changedAt: Date.now(),
        dashboards: [action.payload, ...state.dashboards],
        isLoading: state.isLoading?.filter((d) => d !== DASHBOARD_DUPLICATE),
        error: null,
      };
      break;
    case DASHBOARD_DELETE:
      updatedState = {
        ...state,
        changedAt: Date.now(),
        dashboards: state.dashboards.filter((comment) => comment._id !== action.payload),
        isLoading: state.isLoading?.filter((d) => d !== DASHBOARD_DELETE),
        updateErrors: null,
        error: null,
      };
      break;
    case DASHBOARD_LOADING:
      updatedState = { ...state, isLoading: [...state.isLoading, action.payload] };
      break;
    case GET_USER:
      updatedState = { ...state, user: action.payload, error: null };
      break;
    case DASHBOARD_UPDATE_ERRORS:
      updatedState = {
        ...state,
        updateErrors: action.payload,
      };
      break;
    case DASHBOARD_ERROR:
      updatedState = { ...state, error: action.payload };
      break;
    default:
      updatedState = { ...state };
      break;
  }
  return updatedState;
};

export default dashboardReducer;
