import React, {
  createRef,
  useState,
  useEffect,
} from 'react';
import Switch from 'react-switch';
import { BsStarFill } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import format from 'date-fns/format';

import { useTranslation } from 'react-i18next';
import dots from '../../assets/images/dots.svg';
import on from '../../assets/images/switch-on.svg';
import off from '../../assets/images/switch-off.svg';

import List from '../../lib/List';
import PositionalModal from '../../lib/PositionalModal';
import withModalConfirm from '../../lib/withModalConfirm';

import {
  duplicateDashboard,
  deleteDashboard,
  updateDashboard,
  updateFavoriteDashboard,
} from '../../actions/dashboards';

import styles from './dashboards-list.module.scss';

const CellAction = ({
  _id,
  name,
  state,
  isFavorite,
  dashboards,
  indicatorsKeys,
  confirm,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { dashboardReducer } = useSelector((store) => store);
  const { user } = dashboardReducer;
  const { search: searchQuery } = useLocation();
  const cellRef = createRef(`dashboard-${_id}`);
  const modalRef = createRef(`dashboard-modal-${_id}`);

  const userIsAllowed = indicatorsKeys?.length === 0 || indicatorsKeys?.filter(
    (i) => user?.keys?.find((k) => k.value === i),
  )?.length === indicatorsKeys?.length;

  function openModal(e) {
    e.preventDefault();
    e.stopPropagation();
    modalRef.current.open();
  }

  function handleDeleteDashboard(e) {
    e.preventDefault();
    e.stopPropagation();
    confirm(() => deleteDashboard(dispatch, _id), `${t('dashboard.confirmDelete')} : ${name} !`);
  }

  function handleDuplicateDashboard(e) {
    e.preventDefault();
    e.stopPropagation();
    duplicateDashboard(dispatch, _id, { keys: user?.keys || [] });
  }

  function handleUpdateDashboard(e) {
    e.preventDefault();
    e.stopPropagation();
    navigate(`/config-dashboard/${_id}${searchQuery}`);
  }

  function handleFavoriteDashboard(e) {
    e.preventDefault();
    e.stopPropagation();
    updateFavoriteDashboard(dispatch, {
      favorite: isFavorite ? null : _id,
      dashboards: dashboards.map((d) => d._id),
    });
  }

  return (
    <div className={`${styles.cellAction} ${userIsAllowed ? '' : styles.disabled}`}>
      <PositionalModal
        ref={modalRef}
        parent={cellRef}
        alignmentX={'right'}
        alignmentY={'middle'}
      >
        <div className={styles.containerActions}>
          {state && <div><p onClick={(e) => handleFavoriteDashboard(e)}>{isFavorite ? t('btn.deleteFavorite') : t('btn.favorite')}</p></div>}
          <div><p onClick={(e) => handleUpdateDashboard(e)}>{t('btn.edit')}</p></div>
          <div><p onClick={(e) => handleDuplicateDashboard(e)}>{t('btn.copy')}</p></div>
          <div><p onClick={(e) => handleDeleteDashboard(e)}>{t('btn.delete')}</p></div>
        </div>
      </PositionalModal>
      <div className={styles.toggle}>
        <span onClick={openModal}>
          <img src={dots} alt={`action sur le project: ${name}`} />
        </span>
      </div>
      <div ref={cellRef} className={styles.positionModal}></div>
    </div>
  );
};

const CellState = ({
  _id, state, isFavorite, indicatorsKeys,
}) => {
  const { dashboardReducer } = useSelector((store) => store);
  const { user } = dashboardReducer;
  const userIsAllowed = indicatorsKeys?.length === 0 || indicatorsKeys?.filter(
    (i) => user?.keys?.find((k) => k.value === i),
  )?.length === indicatorsKeys?.length;
  const dispatch = useDispatch();
  const [value, setValue] = useState(state);

  async function handleChange(bool, e) {
    e.stopPropagation();
    const res = await updateDashboard(dispatch, {
      state: bool,
      isFavorite: !bool ? false : isFavorite,
    }, _id);
    if (res.status === 200) setValue(bool);
  }

  return (
    <div className={`${styles.cellState} ${userIsAllowed ? '' : styles.disabled}`}>
      <Switch
        className={`${styles.switch}`}
        onChange={(bool, e) => handleChange(bool, e)}
        checked={value}
        offHandleColor={'#666666'}
        offColor={'#BDBDBD'}
        onHandleColor={'#6879C4'}
        onColor={'#6879C4'}
        activeBoxShadow={''}
        checkedIcon={<img src={on} alt='off' />}
        uncheckedIcon={<img src={off} alt='off' />}
        width={38}
        height={24}
        borderRadius={12}
      />
    </div>
  );
};

const ListDashboards = ({ dashboards = [], confirm }) => {
  const { search: searchQuery } = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [inputNameRefs, setInputNameRefs] = useState();
  const { dashboardReducer } = useSelector((store) => store);
  const { user } = dashboardReducer;

  function handleSelectRow(dashboard) {
    const indicatorsKeys = dashboard?.indicatorsKeys;
    const userIsAllowed = indicatorsKeys?.length === 0 || indicatorsKeys?.filter(
      (i) => user?.keys?.find((k) => k.value === i),
    )?.length === indicatorsKeys?.length;
    if (userIsAllowed) {
      return navigate(`/edit-dashboard/${dashboard._id}/indicators${searchQuery}`);
    }
    return navigate(`/preview-dashboard/${dashboard._id}/${searchQuery}`);
  }

  useEffect(() => {
    const listRefs = {};
    dashboards.forEach((d) => {
      listRefs[d._id] = createRef(`input-${d._id}`);
    });
    setInputNameRefs(listRefs);
  }, []);

  return (
    <div className={styles.dashboardsList}>
      {inputNameRefs && user?.keys && <List
        classNameRowHeader={styles.rowHeader}
        classNameRow={styles.row}
        defaultSort={{ key: 'name', order: 'asc' }}
        defaultOrder={['name']}
        col={[
          {
            title: t('dashboard.name'),
            sortable: true,
            itemProperty: 'name',
            component: ({
              name,
              isFavorite,
            }) => <div className={styles.cellName}>
                <p>{name}</p>
                {isFavorite && <BsStarFill />}
              </div>,
          },
          {
            title: t('dashboard.editors'),
            sortable: true,
            itemProperty: 'editor',
            component: ({
              editor,
            }) => <div className={styles.cellManager}><p>{editor}</p></div>,
          },
          {
            title: t('dashboard.date'),
            sortable: true,
            itemProperty: 'updatedAt',
            component: ({
              updatedAt,
            }) => <div className={styles.cellUpdatedAt}><p>{format(new Date(updatedAt), 'dd/MM/yy')}</p></div>,
          },
          {
            title: t('dashboard.state'),
            sortable: true,
            itemProperty: 'state',
            component: ({
              _id, state, indicatorsKeys, isFavorite,
            }) => <CellState
              _id={_id}
              state={state}
              isFavorite={isFavorite}
              indicatorsKeys={indicatorsKeys}
            />,
          },
          {
            title: '',
            component: ({
              _id,
              name,
              state,
              isFavorite,
              indicatorsKeys,
            }) => <CellAction _id={_id}
              isFavorite={isFavorite}
              dashboards={dashboards}
              name={name}
              state={state}
              indicatorsKeys={indicatorsKeys}
              confirm={confirm}
            />,
          },
        ]}
        items={dashboards}
        handleClickRow={handleSelectRow}
      >
      </List>}
    </div>
  );
};

export default withModalConfirm(ListDashboards);
