import React from 'react';
import Select, { components } from 'react-select';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import styles from '../styled-select.module.scss';
import {
  styleBase,
  styleNoBorder,
  stylePrimary,
  styleDark,
  styleGrey,
} from '../style';

const StyledSelect = ({
  name,
  control,
  rules,
  widthLabel = null,
  inline = false,
  placeholder = '',
  label = null,
  icon = null,
  className,
  isClearable = false,
  isSearchable = false,
  defaultValue,
  disabled = false,
  handleChange,
  ...props
}) => {
  function getClassName() {
    let style;
    if (className === 'primary') style = stylePrimary;
    else if (className === 'no-border') style = styleNoBorder;
    else if (className === 'grey') style = styleGrey;
    else if (className === 'dark') style = styleDark;
    else style = styleBase;
    return style;
  }
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      defaultValue={defaultValue}
      render={({
        field,
      }) => {
        function getStyleContainer() {
          let resClass = styles['container-select'];
          if (inline) {
            resClass += ` ${styles.inline}`;
          }
          return resClass;
        }

        return (
          <div
            className={getStyleContainer()}
          >
            {label && <label style={widthLabel ? { minWidth: widthLabel } : {}}>{label}</label>}
            <Select
              {...field}
              {...props}
              isDisabled={disabled}
              onChange={(value) => {
                let selected = value;
                const allOptions = props.isMulti && value.find((v) => v.value === '*')
                  && props?.options?.filter((opt) => opt.value !== '*');
                if (allOptions?.length > 0) {
                  selected = allOptions;
                }
                field.onChange(selected);
                if (handleChange) handleChange(selected);
              }}
              defaultValue={defaultValue}
              isClearable={isClearable}
              isSearchable={isSearchable}
              components={{
                DropdownIndicator: (p) => (icon || components.DropdownIndicator(p)),
              }}
              className={`${styles.select}`}
              placeholder={placeholder}
              styles={getClassName()}
            />
          </div>
        );
      }}
    />
  );
};

StyledSelect.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  rules: PropTypes.object,
  widthLabel: PropTypes.string,
  inline: PropTypes.bool,
  label: PropTypes.string,
  placeholder: PropTypes.string,
};

export default StyledSelect;
