import React, { useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';
import TileGraph from '../../../../components/TileGraph';
import graphOptions from '../../../../constants/constants';
import { ErrorField } from '../../../../lib/HooksFormFields';
import generateAssociatedElementsData from '../../../../utils/generateAssociatedElementsData';
import generateEntitiesData from '../../../../utils/generateEntitiesData';

import styles from './Graph.module.scss';

const Graph = () => {
  const {
    watch, setValue, options, errors, setError, clearErrors,
  } = useOutletContext();
  const config = watch('config');
  const graphType = watch('graph');
  const selectedName = watch('config.params.name');
  const selectedValue = watch('config.params.value');
  const data = watch('data');

  function createConfig(type) {
    setValue('graph', type);
    const obj = { ...config };
    setValue('data', null);
    setValue('config', obj);
  }

  async function handleChangeData() {
    const isAssociatedDb = config?.params?.name.parent;
    let generatedData = null;
    const associatedConfig = {
      ...config,
      associetedConfig: {
        api: watch('api')?.value,
        key: watch('dataset')?.value,
      },
    };
    if (!isAssociatedDb || config.isAssociatedDb) {
      generatedData = await generateEntitiesData(
        options,
        associatedConfig,
        graphType,
        data,
      );
    } else {
      generatedData = await generateAssociatedElementsData(
        options,
        associatedConfig,
        graphType,
        data,
      );
    }
    if (generatedData?.stacked) {
      setValue('config.legend.stacked', generatedData.stacked);
    }
    if (generatedData?.error?.message) {
      setError('error', { type: 'custom', message: generatedData.error?.message });
    } else {
      clearErrors('error');
    }
    if (generatedData?.data) {
      setValue('data', generatedData.data);
    }
  }

  useEffect(() => {
    if (options?.data
        && graphType
        && selectedName?.value
        && selectedValue?.value
    ) {
      handleChangeData();
    }
  }, [
    options?.data,
    config,
    selectedName,
    config?.params?.relative,
    selectedValue,
    graphType,
  ]);

  return (
    <>
    <div className={styles.container}>
      {graphOptions.map((g) => <TileGraph
        key={g}
        type={g}
        handleClick={() => createConfig(g)}
        disabled={!config?.params?.value?.graphs?.find((v) => v === g)}
        selected={watch('graph') === g}
      />)}

    </div>
      {errors?.error?.message
        && <ErrorField message={errors?.error?.message} />
      }
    </>
  );
};

export default Graph;
