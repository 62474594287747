import React, { useRef, useState } from 'react';
import {
  Bar, BarChart, XAxis, YAxis, Tooltip, CartesianGrid, ResponsiveContainer, Cell,
} from 'recharts';
import CustomTooltip from '../CustomTooltip/CustomTooltip';
import PortalTooltip from '../CustomTooltip/PortalTooltip';

export default function CustomBars({
  data, stacked = null, isReversed = false,
}) {
  const [position, setPos] = useState({});
  const containerRef = useRef(null);
  const viewBox = containerRef?.current?.current?.getBoundingClientRect();
  return (
    <ResponsiveContainer ref={containerRef} width="100%" height="100%">
      <BarChart
        width={'100%'}
        height={'100%'}
        data={data}
        layout={isReversed ? 'vertical' : 'horizontal'}
        margin={{
          top: 35,
          right: 25,
          left: isReversed ? 30 : -20,
          bottom: 0,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        {isReversed
          ? <>
          <XAxis type="number" />
          <YAxis type="category" dataKey={'name'}/>
        </>
          : <>
          <XAxis dataKey={'name'} />
          <YAxis />
        </>
        }
        {stacked
          ? <>
            <Tooltip
              content={
                <PortalTooltip position={position} viewBox={viewBox} isStacked={true}/>
              }
            />
            {stacked.map((s, i) => <Bar onMouseOver={(p) => setPos(p)} key={`${i}`} dataKey={s.dataKey} stackId="a" fill={s.color} />)}
          </>
          : <>
            <Tooltip content={<CustomTooltip />} />
          <Bar
            dataKey={'value'}
          >
          {data.map((entry, index) => (
              <Cell
                key={data[index].id}
                fill={data[index].color}>
              </Cell>
          ))}
        </Bar>
        </>
        }
      </BarChart>
    </ResponsiveContainer>
  );
}
