import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './tile-graph.module.scss';
import GeoIcon from './icons/Geo';
import BubblesIcon from './icons/Bubbles';
import PieIcon from './icons/Pie';
import LinesIcon from './icons/Lines';
import ArrayIcon from './icons/Array';
import TimelineIcon from './icons/Timeline';
import HistogramIcon from './icons/Histogram';
import TreemapIcon from './icons/Treemap';
import TextIcon from './icons/Text';
import BoxplotIcon from './icons/Boxplot';

const content = {
  geo: {
    key: 'geo',
    title: 'Geographique',
    description: 'Parfait pour des data comprenant des lattitudes et longitudes',
    icon: GeoIcon,
  },
  bubbles: {
    key: 'Bubbles',
    title: 'Bubbles',
    description: 'Parfait pour des data comprenant des lattitudes et longitudes',
    icon: BubblesIcon,
  },
  pie: {
    key: 'pie',
    title: 'Camembert',
    description: 'Parfait pour des data comprenant des lattitudes et longitudes',
    icon: PieIcon,
  },
  lines: {
    key: 'lines',
    title: 'Lignes',
    description: 'Parfait pour des data comprenant des lattitudes et longitudes',
    icon: LinesIcon,
  },
  array: {
    key: 'array',
    title: 'Tableaux',
    description: 'Parfait pour des data comprenant des lattitudes et longitudes',
    icon: ArrayIcon,
  },
  timeline: {
    key: 'timeline',
    title: 'Timeline',
    description: 'Parfait pour des data comprenant des lattitudes et longitudes',
    icon: TimelineIcon,
  },
  histogram: {
    key: 'histogram',
    title: 'Histogramme',
    description: 'Parfait pour des data comprenant des lattitudes et longitudes',
    icon: HistogramIcon,
  },
  treemap: {
    key: 'treemap',
    title: 'Treemap',
    description: 'Parfait pour des data comprenant des lattitudes et longitudes',
    icon: TreemapIcon,
  },
  word_cloud: {
    key: 'word_cloud',
    title: 'Nuage de mots',
    description: 'Parfait pour des data comprenant des lattitudes et longitudes',
    icon: TextIcon,
  },
  text: {
    title: 'Texte',
    description: 'Parfait pour des data comprenant des lattitudes et longitudes',
    icon: TextIcon,
  },
  key_number: {
    title: 'Chiffre clé avencé',
    description: 'Parfait pour des data comprenant des lattitudes et longitudes',
    icon: TextIcon,
  },
  key_number_extended: {
    title: 'Chiffre clé',
    description: 'Parfait pour des data comprenant des lattitudes et longitudes',
    icon: TextIcon,
  },
  boxplot: {
    title: 'Boxplot',
    description: 'Parfait pour des data comprenant des lattitudes et longitudes',
    icon: BoxplotIcon,
  },
};

const TileGraph = ({
  type = 'geo', selected = false, disabled = false, handleClick,
}) => {
  const { t } = useTranslation();
  return (
  <div
    className={
    `${styles.tileGraph}${selected ? ` ${styles.selected}` : ''}${disabled ? ` ${styles.disabled}` : ''}`
    }
    onClick={() => handleClick()}
  >
    <div className={styles.containerIcon}>
      {content[type].icon({ selected })}
    </div>
    <div className={styles.content}>
      <p className='bold'>{t(`graph.${type}.title`)}</p>
      {/* <p >{content[type].description}</p> */}
    </div>
  </div>
  );
};

export default TileGraph;
