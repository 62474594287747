import React from 'react';
import styles from './KeyNumber.module.scss';

export default function KeyNumber({ data, label = null }) {
  return (
    <div className={styles.container}>
      <h3 style={{ color: data.color }}>{data.value}</h3>
      <label>{label}</label>
    </div>
  );
}
