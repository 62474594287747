import {
  DASHBOARDS_GET_BY_ID,
  INDICATOR_SET,
  INDICATOR_RESET,
  INDICATOR_POST,
  INDICATOR_PUT,
  INDICATOR_DELETE,
  INDICATOR_LOADING,
  INDICATOR_ERROR,
  INDICATOR_SORT,
  INDICATORS_FIELDS,
  INDICATOR_CHANGE,
  INDICATORS_ASSOCIETED_DB,
  SET_REQUEST_LOADING,
} from '../actions/types';

const DEFAULT_STATE = {
  indicators: [],
  indicator: null,
  list: {},
  fields: null,
  associatedDbs: null,
  requestLoading: false,
  isLoading: [],
  error: null,
  changedAt: Date.now(),
};

const indicatorReducer = (state = DEFAULT_STATE, action) => {
  let updatedState = {};
  const updateIndicator = state.indicators || [];
  const indicatorIndex = (updateIndicator || []).findIndex((p) => (
    p._id === action.payload?.indicator?._id
  ));
  switch (action.type) {
    case SET_REQUEST_LOADING:
      updatedState = {
        ...state,
        requestLoading: action.payload,
      };
      break;
    case DASHBOARDS_GET_BY_ID: // SET ALL INDICATORS
      updatedState = {
        ...state,
        changedAt: Date.now(),
        indicators: action.payload.dashboard.indicators,
        isLoading: state.isLoading?.filter((d) => d !== INDICATOR_SET),
        error: null,
      };
      break;
    case INDICATOR_SORT: // SET ALL INDICATORS
      updatedState = {
        ...state,
        changedAt: Date.now(),
        indicators: action.payload,
        isLoading: state.isLoading?.filter((d) => d !== INDICATOR_SORT),
        error: null,
      };
      break;
    case INDICATORS_ASSOCIETED_DB:
      updatedState = {
        ...state,
        associatedDbs: action.payload,
      };
      break;
    case INDICATORS_FIELDS:
      updatedState = {
        ...state,
        fields: action.payload,
      };
      break;
    case INDICATOR_SET:
      updatedState = {
        ...state,
        indicator: state.indicators.find((d) => d._id === action.payload),
        error: null,
      };
      break;
    case INDICATOR_RESET:
      updatedState = {
        ...state,
        indicator: null,
        error: null,
      };
      break;
    case INDICATOR_POST:
      updatedState = {
        ...state,
        changedAt: Date.now(),
        indicator: action.payload,
        indicators: [...state.indicators, action.payload],
        isLoading: state.isLoading?.filter((d) => d !== INDICATOR_POST),
        error: null,
      };
      break;
    case INDICATOR_PUT:
      if (typeof indicatorIndex === 'number') {
        updateIndicator[indicatorIndex] = action.payload.indicator;
      }
      updatedState = {
        ...state,
        indicator: action.payload.indicator,
        indicators: updateIndicator,
        changedAt: Date.now(),
        isLoading: state.isLoading?.filter((d) => d !== `INDICATOR_PUT-${action.payload.indicator._id}`),
        error: null,
      };
      break;
    case INDICATOR_DELETE:
      updatedState = {
        ...state,
        changedAt: Date.now(),
        indicators: state.indicators.filter((comment) => comment._id !== action.payload),
        isLoading: state.isLoading?.filter((d) => d !== INDICATOR_DELETE),
        error: null,
      };
      break;
    case INDICATOR_LOADING:
      updatedState = { ...state, isLoading: [...state.isLoading, action.payload] };
      break;
    case INDICATOR_CHANGE:
      updatedState = { ...state, changedAt: Date.now() };
      break;
    case INDICATOR_ERROR:
      updatedState = { ...state };
      break;
    default:
      updatedState = { ...state };
      break;
  }
  return updatedState;
};

export default indicatorReducer;
