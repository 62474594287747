import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useSearchParams, useNavigate, useLocation, useParams,
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { BsArrowLeft } from 'react-icons/bs';

import { createDashboard, getDashboardById, updateDashboard } from '../../actions/dashboards';
import styles from './create-dashboard.module.scss';

import FormCreateDashboard from '../../components/form/FormCreateDashboard';

const CreateDashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const params = useParams();
  const { search: searchQuery } = useLocation();
  const { dashboard } = useSelector((store) => store.dashboardReducer);

  const [searchParams] = useSearchParams();
  const formRef = useRef();

  async function submit() {
    const resultSubmit = await formRef.current?.getData();
    if (!resultSubmit) return;
    const data = { ...formRef.current?.data };
    const user = searchParams.get('IdUtil');
    if (!data || !user) return;
    if (params.dashboardId) {
      updateDashboard(dispatch, {
        ...data,
      }, params.dashboardId);
      return;
    }
    const res = await createDashboard(dispatch, {
      user: searchParams.get('IdUtil'),
      IdCom: searchParams.get('IdCom'),
      IdDi: searchParams.get('IdDi'),
      IdWidget: searchParams.get('IdWidget'),
      IdApi: searchParams.get('IdApi'),
      ...data,
    });
    if (res.status === 201) navigate(`/edit-dashboard/${res.data.dashboard._id}/indicators${searchQuery}`);
  }

  useEffect(() => {
    if (!params.dashboardId) return;
    getDashboardById(dispatch, params.dashboardId);
  }, []);

  return (
    <div className={styles.createDashboard}>
      <div className={styles.container}>
        <div className={styles.header}>
          <p className={styles.backward} onClick={() => navigate(-1)}><BsArrowLeft /> {t('btn.back')}</p>
          <h1>{dashboard?.name || t('dashboard.title') }</h1>
          <div className={styles.containerButton}>
            <button onClick={() => submit()}>{t('btn.valid')}</button>
          </div>
        </div>
        <div className={styles.content}>
          <div className={styles.form}>
            <FormCreateDashboard ref={formRef} />
          </div>
          <div className={styles.containerLogo}>

          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateDashboard;
