import React, { useEffect } from 'react';
import {
  NavLink, useSearchParams, useLocation, useNavigate,
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { BsFillCaretDownFill } from 'react-icons/bs';
import { useTranslation } from 'react-i18next';
import { getDashboards } from '../../actions/dashboards';

import styles from './dashboard.module.scss';

import ListDashboards from '../../components/ListDashboards';
import {
  InputText,
  StyledSelect,
} from '../../lib/HooksFormFields';

const Dashboard = ({ isFavorite = false }) => {
  const { search: searchQuery } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const dashboardReducer = useSelector((store) => store.dashboardReducer);
  const {
    dashboards, user, list, redirectFavorite,
  } = dashboardReducer;
  const favoriteId = dashboards?.find((d) => {
    if (d.isFavorite) {
      return true;
    }
    return null;
  })?._id;
  const { control, watch, reset } = useForm();
  const watchSearch = watch('search');
  const userId = searchParams.get('IdUtil');
  const { t } = useTranslation();

  function handleSearchChange(value) {
    getDashboards(dispatch, value.length > 2 ? { search: value, user: userId } : { user: userId });
  }

  function handleChangeEditor(opt) {
    const params = { user };
    if (watchSearch && watchSearch.length > 2) params.search = watchSearch;
    if (opt?.value) params.editor = opt.value;
    getDashboards(dispatch, params);
  }

  useEffect(() => {
    const search = searchParams.get('search');
    const searchParamsCopy = new URLSearchParams(searchParams);
    const params = Object.fromEntries(searchParamsCopy);
    getDashboards(
      dispatch,
      search ? { search, user: userId, ...params } : { user: userId, ...params },
    );
    if (search) reset({ search });
  }, []);

  // SET SEARCH PARAMS
  useEffect(() => {
    const searchParamsCopy = new URLSearchParams(searchParams);
    if (watchSearch) searchParamsCopy.set('search', watchSearch);
    else searchParamsCopy.delete('search', watchSearch);
    setSearchParams(searchParamsCopy);
  }, [watchSearch]);

  useEffect(() => {
    if (isFavorite && redirectFavorite && favoriteId) {
      navigate(`/preview-dashboard/${favoriteId}/${searchQuery}`);
    }
  }, [isFavorite, favoriteId]);

  return (
    <div className={styles.dashboard}>
      <div className={styles.container}>
        <div className={styles.containerButton}>
          <NavLink to={`/create-dashboard${searchQuery}`}>
            <button>{t('dashboard.add')}</button>
          </NavLink>
        </div>
        <div className={styles.content}>
          <div className={styles.searchFields}>
            <InputText
              name="search"
              className="primary"
              control={control}
              handleChange={(value) => handleSearchChange(value)}
              placeholder={t('dashboard.search')}
            />
            <StyledSelect
              name="editors"
              className="primary"
              control={control}
              placeholder={t('dashboard.editors')}
              handleChange={(value) => handleChangeEditor(value)}
              isClearable
              icon={
                <div style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '36px',
                }}>
                  <BsFillCaretDownFill />
                </div>
              }
              options={list?.editors?.map((d) => ({ label: d, value: d }))}
            />
          </div>
          {dashboards && <ListDashboards dashboards={dashboards} />}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
