export const GET_USER = 'GET_USER';
export const SET_TOAST = 'SET_TOAST';

export const DELETE_FILE = 'DELETE_FILE';
export const POST_FILE = 'POST_FILE';
export const DASHBOARDS_GET = 'DASHBOARDS_GET';
export const DASHBOARDS_UPDATE_FAVORITE = 'DASHBOARDS_UPDATE_FAVORITE';
export const DASHBOARDS_GET_BY_ID = 'DASHBOARDS_GET_BY_ID';
export const DASHBOARDS_LIST_GET = 'DASHBOARDS_LIST_GET';
export const DASHBOARD_POST = 'DASHBOARD_POST';
export const DASHBOARD_PUT = 'DASHBOARD_PUT';
export const DASHBOARD_DUPLICATE = 'DASHBOARD_DUPLICATE';
export const DASHBOARD_UPDATE_ERRORS = 'DASHBOARD_UPDATE_ERRORS';
export const DASHBOARD_DELETE = 'DASHBOARD_DELETE';
export const DASHBOARD_LOADING = 'DASHBOARD_LOADING';
export const DASHBOARD_ERROR = 'DASHBOARD_ERROR';
export const GET_LIST = 'GET_LIST';

export const INDICATOR_SET = 'INDICATOR_SET';
export const INDICATOR_RESET = 'INDICATOR_RESET';
export const INDICATOR_POST = 'INDICATOR_POST';
export const INDICATOR_PUT = 'INDICATOR_PUT';
export const INDICATOR_DELETE = 'INDICATOR_DELETE';
export const INDICATOR_LOADING = 'INDICATOR_LOADING';
export const INDICATOR_ERROR = 'INDICATOR_ERROR';
export const INDICATOR_SORT = 'INDICATOR_SORT';
export const INDICATORS_FIELDS = 'INDICATORS_FIELDS';
export const INDICATORS_ASSOCIETED_DB = 'INDICATORS_ASSOCIETED_DB';
export const INDICATOR_CHANGE = 'INDICATOR_CHANGE';
export const SET_REQUEST_LOADING = 'SET_REQUEST_LOADING';
