import React from 'react';
import { useOutletContext } from 'react-router-dom';
import Indicator from '../../../../components/Graphs/Indicator';

import styles from './Preview.module.scss';

const Preview = () => {
  const {
    watch,
  } = useOutletContext();

  const indicator = watch();
  return (
    <div className={styles.container}>
      <div className={styles.graph}>
        {indicator && <Indicator indicator={indicator} />}
      </div>
    </div>
  );
};

export default Preview;
