const graphOptions = [
  'geo',
  'bubbles',
  'pie',
  'lines',
  'array',
  'timeline',
  'histogram',
  'treemap',
  'word_cloud',
  'key_number_extended',
  'key_number',
  'boxplot',
];

export default graphOptions;
