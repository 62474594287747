import React, { useRef } from 'react';
import { useWatch } from 'react-hook-form';
import {
  InputColor,
  SelectIcons,
} from '../../lib/HooksFormFields';

import styles from './select-colored-icon.module.scss';

const SelectColoredIcon = ({ control, name }) => {
  const watchColor = useWatch({ control, name: `${name}.color` });
  const watchIcon = useWatch({ control, name: `${name}.iconKey` });
  const colorRef = useRef();

  return (
    <div className={styles.container}>
      <SelectIcons
        name={`${name}.iconKey`}
        control={control}
        defaultValue={watchIcon}
        className="secondary"
        color={watchColor}
      />
      <InputColor
        name={`${name}.color`}
        control={control}
        ref={colorRef}
      />
    </div>
  );
};

export default SelectColoredIcon;
