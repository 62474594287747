import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  NavLink, Outlet, useLocation, useOutletContext,
} from 'react-router-dom';

import styles from './embed.module.scss';

const Embed = () => {
  const { control } = useOutletContext();
  const { search } = useLocation();
  const { t } = useTranslation();
  return (
    <div className={styles.embed}>
      <nav>
        <NavLink
          to={`share${search}`}
          className={({ isActive }) => (isActive ? styles.active : undefined)} >
          {t('dashboard.share')}
        </NavLink>
        <NavLink
          to={`allow${search}`}
          className={({ isActive }) => (isActive ? styles.active : undefined)} >
          {t('dashboard.acces')}
        </NavLink>
        {/* <NavLink
          to={`files${search}`}
          className={({ isActive }) => (isActive ? styles.active : undefined)} >
          Fichiers
        </NavLink>
        <NavLink
          to={`users${search}`}
          className={({ isActive }) => (isActive ? styles.active : undefined)} >
          Utilisateurs
        </NavLink> */}
      </nav>
      <div className={styles.tabs}>
        <Outlet context={{ control }}/>
      </div>
    </div>
  );
};

export default Embed;
