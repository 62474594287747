import React, { useState } from 'react';
import { TiArrowSortedDown } from 'react-icons/ti';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { updateIndicator } from '../../actions/indicators';

export default function LayoutRow({ item, styles }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    // control,
    watch,
  } = useForm({
    defaultValues: item,
  });
  const itemData = watch();
  const [isOpen, setIsOpen] = useState(false);

  async function handleChange(data) {
    setIsOpen(!isOpen);
    await updateIndicator(dispatch, data, itemData?._id);
  }

  return (
    <div className={`${styles.row}`}>
      <div className={styles.col}>
        <p>{item.position}</p>
      </div>
      <div className={styles.col}>
        <p>{item.name}</p>
      </div>
      <div className={styles.col} onClick={() => setIsOpen(!isOpen)} >
        <div className={styles.select}>
          <p>
            {itemData.size === 'col-1' && t('dashboard.size.1')}
            {itemData.size === 'col-2' && t('dashboard.size.2')}
            {itemData.size === 'col-3' && t('dashboard.size.3')}
            {itemData.size === 'col-4' && t('dashboard.size.4')}
          </p>
          {isOpen
            && <ul>
              <li onClick={() => handleChange({ ...itemData, size: 'col-1' })}>
                {t('dashboard.size.1')}
              </li>
              <li onClick={() => handleChange({ ...itemData, size: 'col-2' })}>
                {t('dashboard.size.2')}
              </li>
              <li onClick={() => handleChange({ ...itemData, size: 'col-3' })}>
                {t('dashboard.size.3')}
              </li>
              <li onClick={() => handleChange({ ...itemData, size: 'col-4' })}>
                {t('dashboard.size.4')}
              </li>
            </ul>
          }
        </div>
        <div
          className={styles.toggle}
        >
          <TiArrowSortedDown />
        </div>
      </div>
    </div>
  );
}
