import { deleteData, postData } from '.';
import { DASHBOARD_ERROR, DELETE_FILE } from './types';

export async function postFileAction(dispatch, formData) {
  const url = '/file';
  let file;

  await postData(DASHBOARD_ERROR, url, dispatch, formData, true).then((response) => {
    file = response;
  });

  return file;
}

export async function deleteFileAction(dispatch, id) {
  const url = `/file/${id}`;
  let file;

  await deleteData(DASHBOARD_ERROR, url, dispatch).then((response) => {
    file = response;
  });

  dispatch({
    type: DELETE_FILE,
    payload: {
      file: null,
      message: `${file.message} ${id}`,
    },
  });
}
