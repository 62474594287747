import React from 'react';
import errorImg from '../../assets/images/error.svg';
import styles from './Error.module.scss';

export default function Error() {
  return (
    <div className={styles.container}>
      <h2>Oops !</h2>
      <h4>Une erreur avec le contexte d'appel est survenue</h4>
      <img src={errorImg} alt="erreur" />
    </div>
  );
}
