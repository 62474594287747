import React from 'react';
import { useTranslation } from 'react-i18next';
import { FaRegCopy } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import styles from './share.module.scss';

const Share = () => {
  const { search } = useLocation();
  const { dashboardReducer } = useSelector((store) => store);
  const { dashboard } = dashboardReducer;
  const url = `${window.location.origin}/bdi-dashboard/${dashboard?._id}/${search}`;
  const { t } = useTranslation();
  const iframe = `<iframe
    title="${dashboard?.name}"
    width="100%"
    height="100%"
    allow="clipboard-write"
    src="${url}" >
  </iframe>`;

  const copyContent = (id) => {
    let copyMessage = null;
    const messages = document.getElementsByClassName(`${styles.success}`);
    Array.from(messages).forEach((el) => {
      // eslint-disable-next-line no-param-reassign
      el.innerHTML = '';
    });
    const copyText = document.getElementById(id);
    navigator.clipboard.writeText(copyText.value);
    copyMessage = document.getElementById(`${id}-success`);
    copyMessage.innerHTML = `${t(id)}`;
    console.log(navigator);
  };

  return (
    <div className={styles.share}>
      <div className={styles.field}>
        <label>{t('dashboard.url.label')}</label>
        <input id='dashboard.url.message' className={styles.input} value={url} disabled/>
        <div className={styles.copy}>
          <button
            onClick={() => copyContent('dashboard.url.message')}
          >
            {t('dashboard.url.message')} <FaRegCopy />
          </button>
          <p id="dashboard.url.message-success" className={styles.success}></p>
        </div>
      </div>
      <div className={styles.field}>
        <label>{t('dashboard.iframe.label')}</label>
        <textarea value={iframe} id='dashboard.iframe.message' className={styles.input} readOnly />
        <div className={styles.copy}>
          <button
            onClick={() => copyContent('dashboard.iframe.message')}
          >
            {t('dashboard.iframe.message')}  <FaRegCopy />
          </button>
          <p id="dashboard.iframe.message-success" className={styles.success}></p>
        </div>
      </div>
    </div>
  );
};

export default Share;
