import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './translations/en.json';
import fr from './translations/fr.json';
import es from './translations/es.json';

export const resources = {
  en: {
    translation: en,
  },
  fr: {
    translation: fr,
  },
  es: {
    translation: es,
  },
};

const lang = localStorage.getItem('lang');

i18next.use(initReactI18next).init({
  lng: lang || process.env.REACT_APP_LANG,
  debug: false,
  resources,
});

export default i18next;
