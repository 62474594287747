import * as React from 'react';

const ArrayIcon = ({ selected, ...rest }) => (
  <svg
    width={32}
    height={29}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M3.334.833h25.333c.84 0 1.645.36 2.24 1.001.593.64.927 1.51.927 2.416v20.5c0 .906-.334 1.775-.928 2.416-.594.64-1.4 1-2.239 1H3.334c-.84 0-1.646-.36-2.24-1a3.558 3.558 0 0 1-.927-2.416V4.25c0-.906.334-1.775.927-2.416.594-.64 1.4-1 2.24-1Zm14.25 17.084v6.833h11.083v-6.833H17.584Zm-14.25 0v6.833h11.083v-6.833H3.334Zm14.25-10.25V14.5h11.083V7.667H17.584Zm-14.25 0V14.5h11.083V7.667H3.334Z"
      fill={selected ? '#FFFFFF' : '#6879C4' }
    />
  </svg>
);

export default ArrayIcon;
