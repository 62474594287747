/* eslint-disable no-prototype-builtins */
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styles from './FilterModal.module.scss';
import { ErrorField, InputText, StyledSelect } from '../../lib/HooksFormFields';
import { getAssociatedLabelsAction } from '../../actions/indicators';

export default function FilterModal({
  indicator, filter, options, handleChangeFilters, closeModale,
}) {
  const {
    control,
    handleSubmit,
    watch,
    reset,
    formState: {
      errors,
    },
  } = useForm();
  const { t } = useTranslation();
  const [listsOptions, setListsOptions] = useState([]);
  const [keys0ptions, setKeyOptions] = useState();
  const filters = indicator('config.filters');
  const watchIndicator = indicator();
  const property = watch('property');

  const isList = property?.type === 'OrderedMultipleChoiceList' || property?.type === 'MultipleEntityLink' || property?.type === 'MultipleChoiceList';
  const isNumber = property?.type === 'Numeric' || property?.type === 'Float';
  const booleansOptions = [{ value: 'true', label: t('options.yes') }, { value: 'false', label: t('options.no') }];

  function onSubmit(values) {
    const updatedFilters = [...filters];
    const index = filters.findIndex((f) => f.id === values.id);
    if (index === -1) {
      handleChangeFilters([...updatedFilters, { ...values, id: new Date().toString() }]);
    } else {
      updatedFilters[index] = { ...values };
      handleChangeFilters(updatedFilters);
    }
    closeModale();
  }

  async function generateOptions() {
    const list = [];
    const labels = [];
    const associatedConfig = {
      lang: localStorage.getItem('lang') || 'fr',
      associatedId: property.associatedId || property.associatedDomainId,
      api: watchIndicator.requests,
      key: watchIndicator.key,
    };
    if (associatedConfig?.associatedId) {
      const res = await getAssociatedLabelsAction(associatedConfig);
      res.data.forEach((v) => {
        labels.push({
          element_id: v.element_id,
          label: v.label,
        });
      });
    }

    const numbers = [];
    if (isNumber) {
      options.data.filter((d) => (d[property.value] || d[property.parent] ? d : null)).forEach(
        (d) => {
          if (!property.parent && d[property.value]) {
            const value = parseFloat(d[property.value]);
            numbers.push(value);
          }
          if (property.parent && d[property.parent][property.value]) {
            const value = parseFloat(d[property.parent][property.value]);
            numbers.push(value);
          }
          if (property.parent && Array.isArray(d[property.parent])) {
            d[property.parent].forEach((p) => {
              if (p[property.value]) {
                const value = parseFloat(p[property.value]);
                numbers.push(value);
              }
            });
          }
        },
      );
    }
    if (numbers.length > 0) {
      const resetData = watch();
      resetData.min = property.value === filter?.property?.value
      && filter?.min ? filter?.min : Math.min(...numbers);
      resetData.max = property.value === filter?.property?.value
      && filter?.max ? filter?.max : Math.max(...numbers);
      reset(resetData);
    }
    if (isList) {
      const label = property?.value;
      options?.data?.forEach((d) => {
        if (d[label]) {
          d[label].forEach((a) => {
            const key = a?.libelle || a?.label || a?.name || a?.title || a?.core_hasListName;
            if (!list.find((o) => o.value === key)) {
              list.push({ value: key, label: key });
            }
          });
        }
        if (property.parent && Array.isArray(d[property.parent])) {
          d[property.parent].forEach((p) => {
            if (p[label]) {
              if (Array.isArray(p[label])) {
                p[label].forEach((a) => {
                  const key = a?.element_id;
                  const keyLabel = labels.find((l) => l.element_id === key)
                    || options.data.find((l) => l.entity_id === key);
                  if (key && !list.find((o) => o.value === key)) {
                    list.push({ value: key, label: keyLabel.label });
                  }
                });
              } else {
                const key = p[label]?.element_id;
                const keyLabel = labels.find((l) => l.element_id === key);
                if (key && !list.find((o) => o.value === key)) {
                  list.push({ value: key, label: keyLabel?.label });
                }
              }
            }
          });
        }
      });
    }
    if (property?.type === 'UniqueChoiceList') {
      const label = property?.value;
      options?.data?.forEach((d) => {
        if (d[label]) {
          const a = d[label];
          const key = a?.libelle || a?.label || a?.name || a?.title || a?.core_hasListName;
          if (!list.find((o) => o.value === key)) {
            list.push({ value: key, label: key });
          }
        }
        if (property.parent && Array.isArray(d[property.parent])) {
          d[property.parent].forEach((p) => {
            const key = p[label]?.element_id;
            const keyLabel = labels.find((l) => l.element_id === key);
            if (key && !list.find((o) => o.value === key)) {
              list.push({ value: key, label: keyLabel?.label });
            }
          });
        }
      });
    }
    setListsOptions(list);
  }

  useEffect(() => {
    if (options?.keys) {
      const grouped = [];
      // CREATE FILTERS OPTIONS
      options.keys.forEach((k) => {
        const isFilter = k.type === 'MultipleChoiceList'
        || k.type === 'UniqueChoiceList'
        || k.type === 'OrderedMultipleChoiceList'
        || k.type === 'Numeric'
        || k.type === 'Float'
        || k.type === 'Boolean'
        || k.type === 'Date';
        if (!k.group && isFilter) {
          grouped.push(k);
        }
      });
      options.keys.forEach((k) => {
        if (k.group && !grouped.find((g) => g.label === k.group)) {
          const optionsList = [];
          options.keys.filter((opt) => opt.group === k.group)
            .sort((a, b) => a.label.localeCompare(b.label))
            .forEach((opt) => {
              const isFilter = opt.type === 'MultipleChoiceList'
              || opt.type === 'UniqueChoiceList'
              || opt.type === 'OrderedMultipleChoiceList'
              || opt.type === 'Numeric'
              || opt.type === 'Float'
              || opt.type === 'Boolean'
              || opt.type === 'Date';
              if (!optionsList.find((o) => o.value === opt.value) && isFilter && !opt.value.includes('aggregation.')) {
                optionsList.push(opt);
              }
            });
          const group = {
            label: k.group,
            options: optionsList,
          };
          grouped.push({ ...group });
        }
      });
      setKeyOptions(grouped);
    }
  }, [options?.keys]);

  useEffect(() => {
    if (options?.data?.length > 0 && property?.value) {
      generateOptions();
    }
  }, [options?.data, property?.value]);

  useEffect(() => {
    if (filter) {
      reset(filter);
    }
  }, [filter]);

  return (
    <div className={styles.container}>
      <h2>{filter ? t('indicator.filters.update') : t('indicator.filters.new')}</h2>
      <div className={styles.row}>
        <div className={styles.col}>
          <InputText
            name="name"
            control={control}
            label={t('indicator.filters.name')}
          />
        </div>
      </div>
      {keys0ptions
        && <div className={styles.row}>
          <div className={styles.col}>
            <StyledSelect
              name="property"
              control={control}
              label={t('indicator.filters.property')}
              placeholder={t('indicator.filters.property')}
              isSearchable
              options={keys0ptions}
              rules={{
                required: t('input.required'),
              }}
            />
            {errors?.property?.message && <ErrorField message={errors.property.message} />}
            {property?.value && options.data.filter((d) => (d[property.value] || (d[property.parent] && d[property.parent]?.find((v) => v[property.value])) ? d : null)).length === 0 && <ErrorField message={t('indicator.noData', { name: property?.value })} />}
          </div>
        </div>
      }
      {listsOptions.length > 0 && (isList || property?.type === 'UniqueChoiceList')
        && <div className={styles.row}>
          <div className={styles.col}>
            <StyledSelect
              name="list"
              control={control}
              label={t('indicator.filters.fields')}
              placeholder={t('indicator.filters.fields')}
              isSearchable
              isClearable
              isMulti
              options={[{ label: t('selectAll'), value: '*' }, ...listsOptions]}
            />
          </div>
        </div>
      }
      {(property?.type === 'Boolean')
        && <div className={styles.row}>
          <div className={styles.col}>
            <StyledSelect
              name="booleans"
              control={control}
              label="Séléctionner une propriété"
              placeholder="Séléctionner une propriété"
              isSearchable
              isMulti
              options={booleansOptions}
            />
          </div>
        </div>
      }
      {(property?.type === 'Numeric' || property?.type === 'Float')
        && <>
          <div className={styles.row}>
            <InputText
              name="min"
              type='number'
              control={control}
              label={t('indicator.filters.min')}
            />
          </div>
          <div className={styles.row}>
            <InputText
              name="max"
              type='number'
              control={control}
              label={t('indicator.filters.max')}
            />
          </div>
        </>
      }
      {(property?.type === 'Date')
        && <>
          <div className={styles.row}>
            <InputText
              name="minDate"
              type='date'
              control={control}
              label={t('indicator.filters.minDate')}
            />
          </div>
          <div className={styles.row}>
            <InputText
              name="maxDate"
              type='date'
              control={control}
              label={t('indicator.filters.maxDate')}
            />
          </div>
        </>
      }
      <button
        onClick={handleSubmit(onSubmit)}
      >
        {t('btn.valid')}
      </button>
    </div>
  );
}
