import React, {
  useState, useImperativeHandle, forwardRef, useEffect,
} from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { API_URL } from '../../../actions';
import { updateDashboard } from '../../../actions/dashboards';
import { deleteFileAction, postFileAction } from '../../../actions/files';

import {
  InputEmail,
  InputText,
  Textarea,
  ErrorField,
} from '../../../lib/HooksFormFields';

import FileUploader from '../../FileUploader';

import styles from './formCreateDashboard.module.scss';

const FormCreateDashboard = (props, ref) => {
  const [file, setFile] = useState(null);
  const { dashboard } = useSelector((store) => store.dashboardReducer);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    control,
    handleSubmit,
    reset,
    watch,
    trigger: triggerValidation,
    formState: { errors },
  } = useForm();

  useImperativeHandle(ref, () => ({
    getData: async () => {
      let data = watch();
      const hasError = !(await triggerValidation());
      if (hasError) return null;
      await handleSubmit((d) => { data = d; })();
      return data;
    },
    data: watch(),
  }));

  async function handleChangeFile(f) {
    const logoId = dashboard?.logo?._id;
    if (logoId) {
      await deleteFileAction(dispatch, logoId);
    }

    let fileId = null;
    if (f) {
      const formData = new FormData();
      formData.append('file', f);
      formData.append('title', f.name);
      formData.append('description', f.name);
      formData.append('alt', `${f.name.split(' ').join('-')}-file`);
      const savedFile = await postFileAction(dispatch, formData);
      fileId = savedFile?.data?.file?._id;
    }

    await updateDashboard(dispatch, { logo: fileId }, dashboard._id);
    setFile(f);
  }

  useEffect(() => {
    if (dashboard) {
      reset({ ...dashboard });
    }
  }, [dashboard, dashboard?.changedAt]);

  return (
    <div className={styles.container}>
      <div className={styles.form}>
        <div className={styles.containerField}>
          <InputText
            name="name"
            control={control}
            rules={{
              required: t('input.required'),
            }}
            label={t('label.name')}
          />
          {errors?.name?.message && <ErrorField message={errors.name.message} />}
        </div>
        <div className={styles.row}>
          <div className={styles.containerField}>
            <InputText
              name="editor"
              control={control}
              label={t('label.editor')}
              rules={{
                required: t('input.required'),
              }}
            />
            {errors?.editor?.message && <ErrorField message={errors?.editor.message} />}
          </div>
          <div className={styles.containerField}>
            <InputEmail
              name="email"
              control={control}
              // label="Email responsable"
              label={t('label.editorMail')}
              required
              notValidMessage={t('input.mailNotValid')}
              requiredMessage={t('input.required')}
            />
            {errors?.email?.message && <ErrorField message={errors?.email.message} />}
          </div>
        </div>
        <div className={styles.containerField}>
          <Textarea
            name="source"
            control={control}
            label={t('label.source')}
            rules={{
              required: t('input.required'),
            }}
          />
          {errors?.source?.message && <ErrorField message={errors.source.message} />}
        </div>
      </div>
      <div className={styles.file}>
        {dashboard
          && <FileUploader
          label={t('label.logo')}
          handleChangeFile={(f) => handleChangeFile(f)}
          file={file}
          path={dashboard?.logo?.path && `${API_URL}/file/public/${dashboard?.logo?.path}`}
        />
        }
      </div>
    </div>
  );
};

export default forwardRef(FormCreateDashboard);
