import * as React from 'react';

const TimelineIcon = ({ selected, ...rest }) => (
  <svg
    width={34}
    height={27}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M8.5 11.813h17V8.928c-1.503-.696-2.55-2.136-2.55-3.866 0-2.33 1.902-4.218 4.25-4.218s4.25 1.889 4.25 4.218c0 1.73-1.047 3.17-2.55 3.866v2.884h3.4c.94 0 1.7.755 1.7 1.688 0 .933-.76 1.688-1.7 1.688H18.7v2.884a4.21 4.21 0 0 1 2.55 3.866c0 2.33-1.902 4.218-4.25 4.218s-4.25-1.888-4.25-4.218a4.21 4.21 0 0 1 2.55-3.866v-2.884H1.7c-.939 0-1.7-.755-1.7-1.688 0-.933.761-1.688 1.7-1.688h3.4V8.928c-1.5-.696-2.55-2.136-2.55-3.866C2.55 2.732 4.453.845 6.8.845c2.348 0 4.25 1.889 4.25 4.218 0 1.73-1.047 3.17-2.55 3.866v2.884ZM6.8 6.327c.707 0 1.275-.564 1.275-1.266 0-.698-.568-1.265-1.275-1.265s-1.275.567-1.275 1.265c0 .702.568 1.266 1.275 1.266Zm20.4-2.531c-.707 0-1.275.567-1.275 1.265 0 .702.568 1.266 1.275 1.266s1.275-.564 1.275-1.266c0-.698-.568-1.265-1.275-1.265ZM17 23.203c.707 0 1.275-.564 1.275-1.265 0-.702-.568-1.266-1.275-1.266s-1.275.564-1.275 1.265c0 .702.568 1.266 1.275 1.266Z"
      fill={selected ? '#FFFFFF' : '#6879C4' }
    />
  </svg>
);

export default TimelineIcon;
