import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useOutletContext } from 'react-router-dom';
import { Textarea } from '../../../../lib/HooksFormFields';

import styles from './Allow.module.scss';

const Allow = () => {
  const {
    control,
  } = useOutletContext();
  const { dashboard } = useSelector((store) => store.dashboardReducer);
  const { t } = useTranslation();
  useEffect(() => {}, []);
  return (
    <div className={styles.allow}>
      {dashboard
        && <div className={styles.containerField}>
          <Textarea
            name="allowList"
            label={t('dashboard.sites.label')}
            defaultValue={dashboard?.allowList}
            control={control}
          />
          <p>{t('dashboard.sites.message')}</p>
        </div>
      }
    </div>
  );
};

export default Allow;
