import React from 'react';
import {
  Line, LineChart, XAxis, YAxis, Tooltip, CartesianGrid, ResponsiveContainer,
} from 'recharts';
import { defaultColor } from '../../../utils/utils';
import CustomTooltip from '../CustomTooltip/CustomTooltip';

const CustomizedDot = (props) => {
  const {
    cx, cy, r, strokeWidth, payload,

  } = props;
  const color = payload.color || defaultColor;
  console.log();
  return (

      <circle r={r} cx={cx} cy={cy} fill={payload?.color || '#FFFFFF'} stroke={color} strokeWidth={strokeWidth} />
  );
};

export default function CustomLine({ data, color = defaultColor, isReversed = false }) {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart
        width={'100%'}
        height={'100%'}
        data={data}
        layout={isReversed ? 'vertical' : 'horizontal'}
        margin={{
          top: 35,
          right: 40,
          left: isReversed ? 30 : -10,
          bottom: 0,
        }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          {isReversed
            ? <>
            <XAxis type="number" />
            <YAxis type="category" dataKey={'name'}/>
          </>
            : <>
            <XAxis dataKey={'name'} />
            <YAxis />
          </>
        }
          <Tooltip content={<CustomTooltip />} />
          <Line type="monotone"
            dataKey="value"
            stroke={color}
            dot={<CustomizedDot />}
            activeDot={<CustomizedDot />}
            />
        </LineChart>
    </ResponsiveContainer>
  );
}
