import React, { useMemo } from 'react';
import { useOutletContext, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';
import {
  InputText,
  ErrorField,
} from '../../../lib/HooksFormFields';

import styles from './informations.module.scss';
import Loader from '../../../components/Loader';

const Informations = () => {
  const { control, errors } = useOutletContext();
  const params = useParams();
  const { indicatorReducer } = useSelector((store) => store);
  const { t } = useTranslation();
  const { indicator, requestLoading } = indicatorReducer;

  const shouldHaveDefaultValue = useMemo(() => (!!params?.indicatorId), [params?.indicatorId]);

  return (
    <div className={styles.informations}>
      {!requestLoading
         && ((shouldHaveDefaultValue && indicator) || !shouldHaveDefaultValue)
        ? <>
            <div>
              <div className={styles.containerField}>
                <InputText
                  name="name"
                  control={control}
                  label={t('indicator.name')}
                  defaultValue={indicator?.name}
                  rules={{
                    required: t('input.required'),
                  }}
                />
                {errors?.name?.message && <ErrorField message={errors?.name.message} />}
              </div>
            </div>
            <div>
              <div className={styles.containerField}>
                <InputText
                  name="subject"
                  control={control}
                  label={t('indicator.subject')}
                  defaultValue={indicator?.subject}
                  rules={{
                    required: t('input.required'),
                  }}
                />
                {errors?.subject?.message && <ErrorField message={errors.subject.message} />}
              </div>
            </div>
          </>
        : <div className={styles.loader}>
          <Loader />
      </div>
      }
    </div>
  );
};

export default Informations;
