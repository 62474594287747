import React from 'react';
import ReactDOM from 'react-dom';
import CustomTooltip from './CustomTooltip';

const modal = document.getElementById('modal');

export default function PortalTooltip(props) {
  const y = props?.position?.tooltipPosition?.y || 0;
  const x = props?.position?.tooltipPosition?.x || 0;
  const viewBox = props?.viewBox || { x: 0, y: 0, height: 0 };
  return ReactDOM.createPortal(
    <div style={{
      zIndex: 100,
      position: 'fixed',
      top: y + viewBox.top + viewBox.height,
      left: x + viewBox.left,
      transition: 'all 0.2s ease-in',
    }}>
      <CustomTooltip {...props}/>
    </div>,
    modal,
  );
}
