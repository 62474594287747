import * as React from 'react';

const TextIcon = ({ selected, ...rest }) => (
  <svg
    width={25}
    height={30}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path d="M12.5 2v27" stroke={selected ? '#FFFFFF' : '#6879C4' } strokeWidth={3} />
    <path
      d="M7 28h11M23 7V3a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v4"
      stroke={selected ? '#FFFFFF' : '#6879C4' }
      strokeWidth={3}
      strokeLinecap="round"
    />
  </svg>
);

export default TextIcon;
