/* eslint-disable consistent-return */
/* eslint-disable no-restricted-syntax */
/* eslint-disable prefer-regex-literals */
/* eslint-disable no-control-regex */
import * as d3 from 'd3';
// eslint-disable-next-line import/no-cycle
// import { format } from 'date-fns';

export const defaultColor = '#666666';

export const isValidUrl = (str) => {
  if (str?.includes('http://localhost:3013/api/files/public/')) return true;
  const pattern = new RegExp('^(http(s)?:\\/\\/)?' // protocol
    + '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' // domain name
    + '((\\d{1,3}\\.){3}\\d{1,3}))' // OR ip (v4) address
    + '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' // port and path
    + '(\\?[;&a-z\\d%_.~+=-]*)?' // query string
    + '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
  return !!pattern.test(str);
};

export const mostFrequentInArray = (arr) => Object.entries(
  arr.reduce((item, v) => {
    const a = { ...item };
    a[v] = (a[v] ?? 0) + 1;
    return a;
  }, {}),
).reduce((a, v) => (v[1] >= a[1] ? v : a), [null, 0])[0];

export function hexIsLight(color) {
  const hex = color.replace('#', '');
  const r = parseInt(hex.substring(0, 0 + 2), 16);
  const g = parseInt(hex.substring(2, 2 + 2), 16);
  const b = parseInt(hex.substring(4, 4 + 2), 16);
  const brightness = ((r * 299) + (g * 587) + (b * 114)) / 1000;
  return brightness > 150;
}

export const formatLabel = (value, props) => {
  if (props && value && props?.payload && props?.payload?.unit === '%') {
    return `${value?.toFixed(2)} ${props.payload.unit}`;
  }
  return `${value}`;
};

export const colorScale = (value, min, max) => {
  const color = d3.scaleSequential(d3.interpolatePlasma)
    .domain([min, max]);
  return color(value);
};

export function getPercantage(value, max) {
  const range = (value * 100) / max;
  if (range > 0) {
    return range;
  }
  return range;
}

export function isValidDate(d) {
  return d instanceof Date && !Number.isNaN(d);
}

export const translateDate = {
  fr: {
    week: 'semaine',
    semester: 'semestre',
  },
  en: {
    week: 'week',
    semester: 'semester',
  },
};

export const createIndicatorOptions = (obj, fields) => {
  const visualisation = [];
  const { aggregations } = obj;
  let data = obj?.entities || obj?.elements || obj.stats;
  const keys = [];
  const options = [];
  const foundAll = fields?.find((f) => f.value === 'all');
  foundAll?.options.forEach((opt) => {
    options.push({ ...opt, key: foundAll.value });
    opt.graphs.forEach((g) => {
      visualisation.push(g);
    });
  });
  if (foundAll) {
    keys.push({
      value: foundAll.value,
      label: foundAll.label,
      graphs: foundAll.graphs,
      type: foundAll.type,
      group: foundAll.group,
      parent: foundAll?.parent,
    });
  }

  data = data.map((d) => {
    const objValue = { ...d };
    Object.entries(objValue).forEach(([key]) => {
      const isDate = fields.find((f) => f.value === key && f.type === 'Date');
      if (isDate) {
        const date = new Date(objValue[key]);
        if (date && isValidDate(date)) { objValue[key] = date; }
      }
    });
    return objValue;
  });

  fields.forEach((key) => {
    const foundKey = keys.find((f) => f.value === key.value);
    const foundField = { ...key };
    if (!foundKey) {
      foundField.options.forEach((opt) => {
        options.push({ ...opt, key: foundField.value });
        opt.graphs.forEach((g) => {
          if (!visualisation.find((v) => v === g)) {
            visualisation.push(g);
          }
        });
      });
      keys.push({
        value: foundField.value,
        label: foundField.label,
        graphs: foundField.graphs,
        type: foundField.type,
        group: foundField.group,
        parent: foundField?.parent,
        associatedDomainId: foundField.associatedDomainId,
        associatedId: foundField.associatedId,
      });
    }
    if (key.group === 'Aggregation') {
      keys.push(foundField);
    }
  });

  data = [...data];
  return {
    data,
    aggregations,
    visualisation,
    keys,
    options,
    entitiesData: obj?.entitiesData,
    elementsLabels: obj?.elementsLabels,
  };
};

export const parseHeader = (string) => {
  if (!string.includes('{')) return {};
  const json = JSON.parse(string);
  const validateHeader = new Headers(json);
  let count = 0;
  validateHeader.forEach(() => count++);
  if (count > 0) return json;
  return {};
};
