import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import LayoutRow from '../../../components/LayoutRow ';

import styles from './layout.module.scss';
import { sortIndicators } from '../../../actions/indicators';

const Layout = () => {
  const { indicatorReducer, dashboardReducer } = useSelector((store) => store);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { dashboard } = dashboardReducer;
  const { indicators } = indicatorReducer;

  const sortedIndicators = indicators
    ?.filter((i) => i.isPublished).sort((a, b) => a.position - b.position);

  // a little function to help us with reordering the result
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    background: isDragging ? '#E7EAF7' : 'white',
    // change background colour if dragging
    // styles we need to apply on draggables
    ...draggableStyle,
  });

  function onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      sortedIndicators,
      result.source.index,
      result.destination.index,
    );

    const sort = items.map((d, i) => ({ _id: d._id, position: i + 1 }));
    sortIndicators(dispatch, dashboard._id, { sort });
  }

  return (
    <div className={styles.container}>
      <div className={styles.layout}>
        <label>{t('dashboard.lists.label')}</label>
        <div className={styles.list}>
          <div className={`${styles.row} ${styles.label}`}>
            <div className={styles.col}>
              <p>{t('dashboard.lists.order')}</p>
            </div>
            <div className={styles.col}>
              <p>{t('dashboard.lists.name')}</p>
            </div>
            <div className={styles.col}>
              <p>{t('dashboard.lists.size')}</p>
            </div>
          </div>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {sortedIndicators?.map((item, index) => (
                    <Draggable key={item._id} draggableId={item._id} index={index}>
                      {(prov, snaps) => (
                        <div
                          ref={prov.innerRef}
                          {...prov.draggableProps}
                          {...prov.dragHandleProps}
                          style={getItemStyle(
                            snaps.isDragging,
                            prov.draggableProps.style,
                          )}
                        >
                          <LayoutRow
                            key={item._id + item.size}
                            item={item}
                            styles={styles}
                          />
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      </div>
      <div className={styles.layout}>
        <label>{t('dashboard.insight')}</label>
        <div className={styles.preview}>
          {sortedIndicators?.map((n) => (
            <div
              key={n._id + n.size}
              className={`${styles[n.size]}`}>
              <div className={styles.block}>
              {n.name}
              </div>
            </div>))
          }
        </div>
      </div>
    </div>
  );
};

export default Layout;
