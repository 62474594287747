import React, { useState } from 'react';
import AsyncSelect from 'react-select/async';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import styles from '../styled-select.module.scss';
import { styleBase, stylePrimary } from '../style';

const AsyncStyledSelect = ({
  name,
  control,
  rules,
  widthLabel = null,
  inline = false,
  placeholder = '',
  label = null,
  loadOptions,
  className,
  isClearable = false,
  isSearchable = true,
  ...props
}) => {
  const [inputValue, setInputValue] = useState('');
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({
        field,
      }) => (
        <div className={inline ? `${styles['container-select']} ${styles.inline}` : `${styles['container-select']}`}>
          {label && <label style={widthLabel ? { minWidth: widthLabel } : {}}>{label}</label>}
          <AsyncSelect
            {...field}
            {...props}
            onInputChange={(v) => { setInputValue(v); }}
            loadOptions={loadOptions(inputValue)}
            defaultOptions
            isClearable={isClearable}
            isSearchable={isSearchable}
            className={`${styles.select}`}
            placeholder={placeholder}
            styles={className === 'primary' ? stylePrimary : styleBase}
          />
        </div>
      )}
    />
  );
};

AsyncStyledSelect.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  rules: PropTypes.object,
  widthLabel: PropTypes.string,
  inline: PropTypes.bool,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  loadOptions: PropTypes.func,
};

export default AsyncStyledSelect;
