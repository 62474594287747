/* eslint-disable import/no-named-as-default */
/* eslint-disable no-prototype-builtins */
import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';
import {
  ErrorField, InputText, ListTags, StyledSelect,
} from '../../../../lib/HooksFormFields';
import generateAssociatedElementsData from '../../../../utils/generateAssociatedElementsData';
import generateEntitiesData from '../../../../utils/generateEntitiesData';
import { defaultColor, isValidDate, translateDate } from '../../../../utils/utils';

import styles from './Params.module.scss';

const Params = () => {
  const {
    watch, options, control, setValue, setError, clearErrors, errors,
  } = useOutletContext();
  const { t } = useTranslation();
  const [keys0ptions, setKeyOptions] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [relativeOptions, setRelativeOptions] = useState();
  const [relativeKeysOptions, setRelativeKeysOptions] = useState();

  const selectedName = watch('config.params.name');
  const selectedValue = watch('config.params.value');
  const graphType = watch('graph');
  const config = watch('config');
  const data = watch('data');

  const calcOptions = options?.options?.filter(
    (opt) => opt.key === selectedName?.value,
  ) || [];

  const noDataFound = selectedName?.value !== 'all' && !selectedName?.value?.includes('aggregation') && options?.data?.filter(
    (d) => {
      if (d.hasOwnProperty(selectedName?.value)) {
        return d;
      }
      const parent = selectedName?.value?.split('.')[0];
      if (d.hasOwnProperty(parent)) {
        return d;
      }
      if (selectedName?.parent
        && d[selectedName.parent]
        && d[selectedName.parent].find((c) => c.hasOwnProperty(selectedName?.value))) {
        return d;
      }
      return null;
    },
  ).length === 0;
  const aggregatedId = selectedName?.value?.split('.')[1];
  const noAggregateFound = selectedName?.value !== 'all' && aggregatedId && options?.aggregations && !options?.aggregations[aggregatedId];
  const noDataStackedFound = options?.data?.filter(
    (d) => {
      const relative = config?.params?.relative?.value;
      const relativeParent = relative?.parent;
      if (d[relativeParent]) {
        if (d[relativeParent]?.find((r) => r[relative?.value])) {
          return d;
        }
        return null;
      }
      return d.hasOwnProperty(relative?.value) ? d : null;
    },
  ).length === 0;

  function resetIndicator(value = null, params = null) {
    if (value) {
      setValue('config.params.value', null);
    }
    setValue('config.filters', []);
    setValue('config.legend.stacked', null);
    setValue('config.params.relative', null);
    setValue('config.reverseAxis', false);
    setValue('config.ascending', true);
    setValue('config.legend.color', defaultColor);
    setValue('config.ascendingLabel', null);
    setValue('config.splitWords', false);
    setValue('data', null);
    setValue('graph', null);
    if (params?.value === 'SUM_ABSOLUT_DATES') {
      setValue('config.ascending', null);
      setValue('config.ascendingLabel', false);
    }
    if (config.params?.name?.type === 'Date') {
      if (params.value === 'COUNT_ABSOLUT_LIST' || params.value === 'COUNT_SEPARE_LIST') {
        setValue('config.ascending', null);
        setValue('config.ascendingLabel', false);
      }
    }
  }

  useEffect(() => {
    if (options?.keys) {
      const grouped = [];
      const relativeGrouped = [];
      setIsLoading(true);
      options.keys.forEach((k) => {
        if (!k.group) {
          grouped.push(k);
        }
      });
      options.keys.forEach((k) => {
        if (!k.group && k.type !== 'all') {
          relativeGrouped.push(k);
        }
        if (k.group && !grouped.find((g) => g.label === k.group)) {
          const optionsList = [];
          options.keys.filter((opt) => opt.group === k.group)
            .sort((a, b) => a.label.localeCompare(b.label))
            .forEach((opt) => {
              if (!optionsList.find((o) => o.value === opt.value)) {
                optionsList.push(opt);
              }
            });
          const group = {
            label: k.group,
            options: optionsList,
          };
          grouped.push({ ...group });
          group.options = group.options
            .filter((or) => or.value !== selectedName?.value)
            .filter((or) => (selectedName?.parent && selectedName?.parent === or.parent)
            || !or.parent)
            .filter((or) => !or.value.includes('aggregation.')
            && (or?.type === 'OrderedMultipleChoiceList'
            || or?.type === 'RevertedFromEntity'
            || or?.type === 'MultipleChoiceList'
            || or.type === 'UniqueChoiceList'
            || or.type === 'Boolean'
            || or.type === 'Textarea'
            || or.type === 'NonLocalizedText'
            || or.type === 'Date'
            || or.type === 'Numeric'
            || or.type === 'Float'));

          if (group.options.length > 0) {
            relativeGrouped.push(group);
          }
        }
      });
      setKeyOptions(grouped);
      setRelativeOptions(relativeGrouped);
      setIsLoading(false);
    }
  }, [options?.keys, selectedName?.value]);

  useEffect(() => {
    if (options?.data && selectedName?.value && selectedValue?.value === 'COUNT_RELATIVE') {
      const keys = [];
      options?.data?.forEach((d) => {
        if (d[selectedName.value]
          && Array.isArray(d[selectedName.value])
          && d[selectedName.value]?.length > 0) {
          d[selectedName.value].forEach((s) => {
            const value = s.name || s.core_hasListName || s.label || s.CC_VALEUR || s.title;
            if (!keys.find((k) => k.label === value)) {
              keys.push({ label: value, value });
            }
          });
        } else if (d[selectedName.value]) {
          let value = d[selectedName.value];
          let label = value;
          if (selectedName?.type === 'UniqueChoiceList') {
            value = d[selectedName.value]?.label
            || d[selectedName.value]?.CC_VALEUR
            || d[selectedName.value]?.core_hasListName
            || d[selectedName.value]?.name
            || d[selectedName.value]?.title;
            label = value;
          }
          if (selectedName?.type === 'LinkedChoice') {
            value = null;
            label = null;
          }
          if (selectedName?.type === 'Boolean') {
            label = value === 'true' ? t('options.yes') : t('options.no');
          }
          if (selectedName?.type === 'Date' && config.dateFormat) {
            const date = value && new Date(value);
            const lang = localStorage.getItem('lang') || 'fr';
            if (date && isValidDate(date)) {
              if (config.dateFormat === 'date') {
                value = format(date, 'dd/MM/yyyy');
              }
              if (config.dateFormat === 'month') {
                value = new Intl.DateTimeFormat(lang, { month: 'long', year: 'numeric' }).format(date);
              }
              if (config.dateFormat === 'week') {
                const startDate = new Date(date.getFullYear(), 0, 1);
                const days = Math.floor((date - startDate) / (24 * 60 * 60 * 1000));
                const weekNumber = Math.ceil(days / 7);
                value = `${translateDate[lang].week} ${weekNumber} ${date.getFullYear()}`;
              }
              if (config.dateFormat === 'semester') {
                const semester = date.getMonth() < 7 ? 1 : 2;
                value = `${translateDate[lang].semester} ${semester} ${date.getFullYear()}`;
              }
              if (config.dateFormat === 'year') {
                value = date.getFullYear();
              }
              label = value;
            }
          }
          if (label && !keys.find((k) => k.value === value)) {
            keys.push({ label, value });
          }
        }
      });
      setRelativeKeysOptions(keys.sort((a, b) => a.label
      && a.label.toString().localeCompare(b.label)));
    }
  }, [selectedName?.value, selectedValue?.value, config?.dateFormat]);

  async function handleChangeData() {
    const isAssociatedDb = !!config?.params?.name.parent;
    let generatedData = null;
    const associatedConfig = {
      ...config,
      associetedConfig: {
        api: watch('api')?.value,
        key: watch('dataset')?.value,
      },
    };
    if (!isAssociatedDb || config.isAssociatedDb) {
      generatedData = await generateEntitiesData(
        options,
        associatedConfig,
        graphType,
        data,
      );
    } else {
      generatedData = await generateAssociatedElementsData(
        options,
        associatedConfig,
        graphType,
        data,
      );
    }
    if (generatedData?.stacked) {
      setValue('config.legend.stacked', generatedData.stacked);
    }
    if (generatedData?.error?.message) {
      setError('error', { type: 'custom', message: generatedData.error?.message });
    } else {
      clearErrors('error');
    }
    if (generatedData?.data) {
      setValue('data', generatedData.data);
    }
  }

  useEffect(() => {
    if (options?.data
        && graphType
        && selectedName?.value
        && selectedValue?.value
    ) {
      handleChangeData();
    }
  }, [
    options?.data,
    config,
    config?.reverseAxis,
    config?.ascending,
    config?.splitWords,
    config?.dateFormat,
    config?.regex,
    config?.params?.relative,
    config?.params?.relative?.dateFormat,
    selectedName,
    selectedValue,
    graphType,
  ]);

  useEffect(() => {
    if (!config?.splitWords) {
      setValue('config.regex', null);
    }
  }, [config?.splitWords]);

  useEffect(() => {
    if (!config?.dateFormat && selectedName?.type === 'Date') {
      setValue('config.dateFormat', 'date');
    }
    console.log(selectedName);
  }, [selectedName?.type]);

  return (
    <div className={styles.container}>
      {!isLoading
      && <>
      <div className={styles.row}>
        <div className={styles.col}>
        {keys0ptions?.length > 0
          && <StyledSelect
            name="config.params.name"
            control={control}
            label={t('indicator.params.property.label')}
            isSearchable
            placeholder={t('indicator.params.property.placeholder')}
            options={keys0ptions}
            handleChange={() => resetIndicator('value')}
          />
        }
        </div>
        {keys0ptions?.length > 0
          && <div className={styles.col}>
            <StyledSelect
              name="config.params.value"
              control={control}
              label={t('indicator.params.value.label')}
              placeholder={t('indicator.params.value.placeholder')}
              options={calcOptions}
              handleChange={(v) => resetIndicator(null, v)}
            />
          </div>
        }
      </div>
      {(noDataFound || noAggregateFound)
        && selectedName?.value && <ErrorField message={`Aucune donné trouvés "${selectedName?.value}"`} />}
      {selectedValue?.value === 'COUNT_RELATIVE'
        && <div className={styles.row}>
          <div className={styles.col}>
          {relativeKeysOptions?.length > 0
            && <StyledSelect
              name="config.params.relative.filters"
              control={control}
              label={t('indicator.params.relative.filter', { value: selectedName.label.toString() }).replace('&#39;', "'")}
              isSearchable
              isClearable
              isMulti
              placeholder={t('search')}
              options={[{ label: t('selectAll'), value: '*' }, ...relativeKeysOptions]}
            />
          }
          </div>
          <div className={styles.col}>
          {selectedValue?.value === 'COUNT_RELATIVE'
            && <StyledSelect
              name="config.params.relative.value"
              control={control}
              label={t('indicator.params.relative.value')}
              isSearchable
              placeholder={t('search')}
              options={relativeOptions}
            />
          }
          {config?.params?.relative?.value?.type === 'Date'
            && <div className={styles.row}>
              <ListTags
                name="config.params.relative.dateFormat"
                control={control}
                label={t('indicator.params.date.label')}
                tags={[
                  { label: t('indicator.params.date.values.date'), value: 'date' },
                  { label: t('indicator.params.date.values.week'), value: 'week' },
                  { label: t('indicator.params.date.values.month'), value: 'month' },
                  { label: t('indicator.params.date.values.semester'), value: 'semester' },
                  { label: t('indicator.params.date.values.year'), value: 'year' },
                ]}
              />
          </div>
          }
          {noDataStackedFound
            && config?.params?.relative?.value?.value && <ErrorField message={t('indicator.noData', { name: config?.params?.relative?.value?.value })} />}
          </div>
        </div>
      }
      {(selectedName?.type === 'Textarea' || selectedName?.type === 'NonLocalizedText') && selectedValue?.graphs?.find((g) => g === 'word_cloud')
        && <div className={styles.row}>
          <div className={styles.col}>
            <ListTags
              name="config.splitWords"
              control={control}
              label={t('indicator.params.split')}
              tags={[
                { label: t('options.yes'), value: 'yes' },
                { label: t('options.no'), value: 'no' },
                { label: t('options.regex'), value: 'regex' },
              ]}
            />
          </div>

        </div>
      }
      {selectedName?.type === 'Textarea' && config?.splitWords === 'regex'
        && <div className={styles.row}>
          <div className={styles.col}>
          <InputText
          name="config.regex"
          control={control}
          label={t('indicator.params.regex')}
          placeholder="/\b(de|la|les)\b\s*/gi"
        />
        {config?.regex && !config?.regex?.match('^/(.*?)/([gimy]*)$')
          && <ErrorField message={t('indicator.regexError')} />
        }
        </div>
      </div>
      }
      {errors?.error?.message
        && <ErrorField message={errors?.error?.message} />
      }
      {selectedName?.type === 'Date'
        && <div className={styles.row}>
          <ListTags
            name="config.dateFormat"
            control={control}
            label={t('indicator.params.date.label')}
            tags={[
              { label: t('indicator.params.date.values.date'), value: 'date' },
              { label: t('indicator.params.date.values.week'), value: 'week' },
              { label: t('indicator.params.date.values.month'), value: 'month' },
              { label: t('indicator.params.date.values.semester'), value: 'semester' },
              { label: t('indicator.params.date.values.year'), value: 'year' },
            ]}
          />
      </div>
      }
      {(selectedValue?.value === 'SUM_ABSOLUT_DATES' || selectedValue?.value === 'RELATIVES_DATES')
        && <div className={styles.row}>
          <ListTags
            name="config.dateFormat"
            control={control}
            label={t('indicator.params.date.label')}
            tags={[
              { label: t('indicator.params.date.values.date'), value: 'date' },
              { label: t('indicator.params.date.values.week'), value: 'week' },
              { label: t('indicator.params.date.values.month'), value: 'month' },
              { label: t('indicator.params.date.values.semester'), value: 'semester' },
              { label: t('indicator.params.date.values.year'), value: 'year' },
            ]}
          />
      </div>
      }
      </>
      }
    </div>
  );
};

export default Params;
