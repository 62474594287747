import React, {
  useEffect, useState, useRef, useLayoutEffect,
} from 'react';
import * as d3 from 'd3';
import CustomTooltip from '../CustomTooltip/CustomTooltip';
import styles from './Bubbles.module.scss';
import { hexIsLight } from '../../../utils/utils';

export default function Bubbles({ data }) {
  const containerRef = useRef();
  const [bubbles, setBubbles] = useState([]);
  const [width, setWidth] = useState();
  const [height, setHeight] = useState();
  const [activeTooltip, setActiveTooltip] = useState();

  const radiusScale = (value) => {
    const max = d3.max(data.map((d) => d.value));
    const min = d3.min(data.map((d) => d.value));
    const fx = d3.scaleSqrt().range([10, 30]).domain([min, max]);
    return fx(value);
  };

  function isFloat(n, v) {
    return Number(n) === n && n % 1 !== 0 ? n?.toFixed(v) : n;
  }

  const simulatePositions = (dataset) => {
    const nodes = dataset.map((d) => ({
      ...d,
      radius: radiusScale(d.value),
      size: d.value,
      id: d.id + new Date(),
    }));
    d3.forceSimulation()
      .nodes(nodes, (d) => d.id)
      .force('charge', d3.forceManyBody().strength(5))
      .force('center', d3.forceCenter(width / 2, height / 2))
      .force('collision', d3.forceCollide((d) => d.radius + 1))
      .on('tick', () => {
        const updated = nodes.map((d) => {
          const transform = `translate(${d.x - d.radius}px, ${d.y - d.radius}px)`;
          return ({
            ...d,
            transform,
          });
        });
        setBubbles(updated);
      });
  };

  useLayoutEffect(() => {
    setWidth(containerRef.current.clientWidth);
    setHeight(containerRef.current.clientHeight);
  }, []);

  useEffect(() => {
    if (width && height && data.length > 0) {
      simulatePositions(data);
    }
  }, [data, width, height]);

  return (
    <div className={styles.container} ref={containerRef}>
      {bubbles.map((d) => <div
        key={d.id}
        id={d.id}
        className={styles.circle}
        style={{
          transformOrigin: 'center',
          transform: d.transform,
          backgroundColor: d.color,
          width: d.radius * 2,
          height: d.radius * 2,
          borderRadius: d.radius * 2,
          color: hexIsLight(d.color) ? '#000000' : '#FFFFFF',
        }}
        onMouseOver={() => setActiveTooltip(`tooltip-${d.id}`)}
        onMouseLeave={() => setActiveTooltip()}
        >
        <p>{d?.value && isFloat(d?.value, 2)}</p>
      </div>)}
      {bubbles.map((d) => <div
        key={`tooltip-${d.id}`}
        id={`tooltip-${d.id}`}
        className={`${styles.tooltip} ${activeTooltip === `tooltip-${d.id}` ? styles.active : null}`}
        style={{
          transform: `translate(${d.x + (d.radius * 0.8)}px, ${d.y + (d.radius * 0.8)}px)`,
        }}
        >
        <CustomTooltip active={true} payload={[{ payload: d, value: d.value }]} label={d?.name || ''} />
      </div>)}
    </div>
  );
}
