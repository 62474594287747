export const filterData = (items = [], filters = []) => {
  if (filters?.length > 0) {
    let data = [...items];
    filters?.forEach((f) => {
      data = data.filter((item) => {
        let property = item[f.property.value];
        if (item.entity_id
          && !item[f.property.value] && f.property.parent && item[f.property.parent]) {
          if (Array.isArray(item[f.property.parent])) {
            property = item[f.property.parent]
              .filter((e) => e[f.property.value])
              .map((e) => e[f.property.value]);
          }
        }
        let isIn = false;
        const isNumber = f.property.type === 'Numeric' || f.property.type === 'Float';
        const isDate = f.property.type === 'Date';
        const isBoolean = f.property.type === 'Boolean';

        if (property && f.list) {
          if (f?.list.length === 0) {
            isIn = true;
          }
          const properties = Array.isArray(property) && property?.map(
            (p) => p?.libelle
              || p?.label
              || p?.title
              || p?.name
              || p?.core_hasListName
              || p?.element_id
              || p,
          );

          const label = property?.libelle
          || property?.label
          || property?.element_id
          || property?.name
          || property?.title
          || property?.core_hasListName;
          f.list.forEach((l) => {
            const value = l.libelle
            || l.value
            || l.label
            || l.title
            || l.name || l.core_hasListName;
            if ((properties && properties?.find((p) => p === value)) || label === value) {
              isIn = true;
            }
          });
        }
        if (isNumber && property) {
          const value = parseFloat(property);
          const min = parseFloat(f.min);
          const max = parseFloat(f.max);
          if (Array.isArray(property)) {
            const found = property.find((p) => {
              const v = parseFloat(p);
              let isValid = true;
              if (v < min || v > max) {
                isValid = false;
              }
              return isValid ? p : null;
            });
            if (found) {
              isIn = true;
            }
          } else {
            isIn = true;
            if (value < min || value > max) {
              isIn = false;
            }
          }
        }
        if (isBoolean && property) {
          isIn = false;
          if (f?.booleans?.find((opt) => opt.value === property)) {
            isIn = true;
          }
        }
        if (isDate && property) {
          const min = new Date(f.minDate).getTime();
          const max = new Date(f.maxDate).getTime();
          if (Array.isArray(property)) {
            const found = property.find((p) => {
              const v = new Date(p).getTime();
              let isValid = true;
              if (v < min || v > max) {
                isValid = false;
              }
              return isValid ? p : null;
            });
            if (found) {
              isIn = true;
            }
          } else {
            const value = new Date(property).getTime();
            isIn = true;
            if (value < min || value > max) {
              isIn = false;
            }
          }
        }
        return isIn ? item : null;
      });
    });
    return data;
  }
  return items;
};

export default filterData;
