import React from 'react';
import { useSelector } from 'react-redux';
import { useOutletContext } from 'react-router-dom';
import styles from './users.module.scss';

import { ListTags } from '../../../../lib/HooksFormFields';

const Users = () => {
  const { control } = useOutletContext();
  const { dashboardReducer } = useSelector((store) => store);
  const { dashboard } = dashboardReducer;
  return (
    <div className={styles.users}>
      {dashboard && (
        <>
          <div className={styles.containerField}>
            <ListTags
              name="allowRefresh"
              control={control}
              label="Autoriser le rafraichissement « On demand »."
              defaultValue={dashboard.allowRefresh}
              tags={[
                { label: 'Oui', value: true },
                { label: 'Non', value: false },
              ]}
            />
          </div>
          <div className={styles.containerField}>
            <ListTags
              name="timeline"
              control={control}
              label="Activer l’option de timeline"
              defaultValue={dashboard.timeline}
              tags={[
                { label: 'Oui', value: true },
                { label: 'Non', value: false },
              ]}
            />
          </div>
          <div className={styles.containerField}>
            <ListTags
              name="customThumbnail"
              control={control}
              label="Activer vignette configurable"
              defaultValue={dashboard.customThumbnail}
              tags={[
                { label: 'Oui', value: true },
                { label: 'Non', value: false },
              ]}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default Users;
