import * as React from 'react';

const PieIcon = ({ selected, ...rest }) => (
  <svg
    width={46}
    height={46}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M24.917 3.931v17.152h17.152c-.9-9.04-8.111-16.251-17.152-17.152Zm9.524 34.41c4.195-3.138 7.08-7.935 7.628-13.424H26.385l8.056 13.424Z"
      fill={selected ? '#FFFFFF' : '#6879C4' }
    />
    <path
      d="M21.083 23V3.931C11.412 4.897 3.833 13.08 3.833 23c0 10.569 8.598 19.167 19.167 19.167a19.032 19.032 0 0 0 8.155-1.848s-10.068-17.087-10.07-17.31c0-.003 0-.006-.002-.009Z"
      fill={selected ? '#FFFFFF' : '#6879C4' }
    />
  </svg>
);

export default PieIcon;
