import React from 'react';
import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import styles from './list-tags.module.scss';

const ListTags = ({
  name,
  control,
  rules,
  label,
  widthLabel = null,
  inline = false,
  isMulti = false,
  tags = [],
  defaultValue,
  callBack,
}) => (
    <Controller
      name={name}
      control={control}
      rules={rules}
      defaultValue={defaultValue}
      render={({
        field: {
          onChange,
          value: values,
          // onBlur,
        },
      }) => {
        function handleClick(val) {
          if (callBack) {
            callBack();
          }
          if (!isMulti) {
            onChange(val);
          } else {
            let arrValues = values ? [...values] : [];
            if (arrValues.includes(val)) arrValues = arrValues.filter((value) => value !== val);
            else arrValues.push(val);
            onChange(arrValues);
          }
        }

        function isSelected(val) {
          if (!values && typeof values !== 'boolean') return false;
          if (isMulti && values.includes(val)) return true;
          return val === values;
        }

        return (
          <div className={inline ? `${styles['list-tags']} ${styles.inline}` : `${styles['list-tags']}`}>
            { label && <label style={widthLabel ? { width: widthLabel } : {}}>{label}</label>}
            { tags
              && <ul>
                {tags.map((tag) => (
                  <li
                    key={`name-${tag.label}`}
                    onClick={() => handleClick(tag.value)}
                    className={isSelected(tag.value) ? `${styles.selected}` : ''}
                  >
                    {tag.label}
                  </li>
                ))}
              </ul>
            }
          </div>
        );
      }

      }
    />
);

ListTags.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  rules: PropTypes.object,
  label: PropTypes.string,
  widthLabel: PropTypes.string,
  inline: PropTypes.bool,
  isMulti: PropTypes.bool,
  tags: PropTypes.array,
};

export default ListTags;
