import React from 'react';
import ReactWordcloud from 'react-wordcloud';
import styles from './WordCloud.module.scss';

export default function WordCloud({ data }) {
  const words = data.map((d) => ({ text: d.name, value: d.value, color: d.color }));
  const callbacks = {
    onWordMouseOver: null,
    getWordColor: (word) => (word.color),
  };
  const options = {
    rotations: 0,
    fontFamily: 'OpenSans-Bold',
    enableTooltip: false,
  };

  return (
    <div className={styles.container}>
      <ReactWordcloud
        callbacks={callbacks}
        options={options}
        words={words}
      />
    </div>
  );
}
