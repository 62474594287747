import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './KeyNumber.module.scss';

export default function KeyNumberExtended({ data, label = null }) {
  const { t } = useTranslation();
  return (
    <div className={styles.container}>
      <h3 style={{ color: data[0].color }}>{data[0].value}</h3>
      <label>{label}</label>
      <div className={styles.infos}>
        {t('indicator.average')} : {data[1].value}
      </div>
      <div className={styles.infos}>
        {t('indicator.period', { date: data[2].value })}
      </div>
    </div>
  );
}
