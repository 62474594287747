import React from 'react';
import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import Switch from 'react-switch';
import styles from './switch.module.scss';

const StyledSwitch = ({
  name,
  control,
  rules,
  label,
  defaultValue = false,
}) => (
    <Controller
      name={name}
      control={control}
      rules={rules}
      defaultValue={defaultValue}
      render={({
        field: {
          // name,
          value = false,
          // ref,
          onChange,
          // onBlur,
        },
      }) => (
        <div className={`${styles['container-switch']}`}>
          {label && <label>{label}</label>}
          <Switch
            className={`${styles.switch}`}
            onChange={(checked) => onChange(checked)}
            checked={value}
            offHandleColor={'#BDBDBD'}
            offColor={'#F3F3F3'}
            onHandleColor={'#6879C4'}
            onColor={'#E7EAF7'}
            activeBoxShadow={''}
            checkedIcon={false}
            uncheckedIcon={false}
            width={46}
            height={24}
            borderRadius={5}
          />
        </div>

      )}
    />

);

StyledSwitch.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  rules: PropTypes.object,
  label: PropTypes.string,
};

export default StyledSwitch;
