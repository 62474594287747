import React, {
  useEffect,
  useLayoutEffect,
  useState,
  useRef,
} from 'react';
import { useController } from 'react-hook-form';
import { BsFillCaretDownFill } from 'react-icons/bs';
import * as IconsExported from 'react-icons/io';
import styles from './select-icons.module.scss';

const Icons = {};
Object.entries(IconsExported).forEach(([name, exported]) => {
  Icons[name] = exported;
});

const SelectIcons = ({
  size = 'sm',
  color = '#6879C4',
  className = 'primary',
  name,
  control,
  reset,
  defaultValue = null,
  rules,
}) => {
  const [isOpen, setIsOpen] = useState();
  const [gridIconPosition, setGridIconPosition] = useState({
    top: '50px',
  });
  const [selectedIcon, setSelectedIcon] = useState(defaultValue || 'IoIosListBox');
  const gridIconRef = useRef();
  const toggleRef = useRef();

  const { field: { onChange, value } } = useController({
    name,
    control,
    defaultValue,
    rules,
  });

  useEffect(() => {
    const root = document.getElementById('root');
    const closeModal = (e) => {
      if (isOpen && !e.target.closest("[data-close-modal='false']")) {
        setIsOpen(false);
      }
    };
    root.addEventListener('click', closeModal);
    return () => {
      root.removeEventListener('click', closeModal);
    };
  }, [isOpen]);

  useEffect(() => {
    if (value || !reset) onChange(selectedIcon);
    else reset({ [name]: selectedIcon });
  }, [selectedIcon]);

  useLayoutEffect(() => {
    if (!gridIconRef.current) return;
    const boundModal = gridIconRef.current.getBoundingClientRect();
    if (boundModal.bottom > window.innerHeight) {
      setGridIconPosition({ top: `-${boundModal.height + 5}px` });
    } else {
      const boundToggle = toggleRef.current.getBoundingClientRect();
      setGridIconPosition({ top: `${boundToggle.height + 5}px` });
    }
  }, [isOpen]);

  const renderIcon = () => {
    const icon = Object.entries(IconsExported).find(([key]) => key === selectedIcon)[1];
    return icon({ color, size: size === 'md' ? 30 : 20 });
  };

  return (
    <div className={`${styles.selectIcons} ${styles[size]}`} ref={toggleRef}>
      <div
        className={`${styles.selectedIcon} ${styles[className]}`}
        onClick={() => setIsOpen((state) => !state)}
      >
        <div className={styles.containerIcon}>
          {renderIcon()}
        </div>
        <div className={styles.caret}>
          <BsFillCaretDownFill color='#2C2C2C' />
        </div>
      </div>
      {isOpen && (
        <div ref={gridIconRef} className={styles.gridIcon} style={{
          fontFamily: 'Font-Icons',
          ...gridIconPosition,
        }}>
          <div>
            {Object.entries(IconsExported).map(([key, icon]) => (
              <div
                key={`icon-${key}`}
                className={styles.icon}
                onClick={() => setSelectedIcon(key)}
              >
                {icon()}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default SelectIcons;
