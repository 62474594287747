import React from 'react';
import {
  PieChart, Pie, Tooltip, Cell, ResponsiveContainer,
} from 'recharts';
import CustomTooltip from '../CustomTooltip/CustomTooltip';

export default function CustomPie({ data, isLabel }) {
  const renderCustomizedLabel = (label) => {
    const {
      cx, cy, midAngle, innerRadius, outerRadius, name, value, unit,
    } = label;
    const RADIAN = Math.PI / 180;
    const radius = 25 + innerRadius + (outerRadius - innerRadius);
    // eslint-disable-next-line
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    // eslint-disable-next-line
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    return (
        <text x={x} y={y} fill="#666666" fontSize="14px" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
          {name}
          {value
            && ` (${unit ? value?.toFixed(2) : value}${unit || ''})`}
        </text>
    );
  };

  return (
    <ResponsiveContainer width="100%" height="100%">
      <PieChart>
        <Pie
          dataKey="value"
          data={data}
          cx="50%"
          cy="50%"
          innerRadius={'50%'}
          outerRadius={'85%'}
          label={isLabel ? renderCustomizedLabel : null}
          fill="#82ca9d"
        >
        {data.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={entry.color} stroke={data.length > 1000 ? entry.color : '#FFFFFF'} />
        ))}
        </Pie>
        <Tooltip content={<CustomTooltip />} />
      </PieChart>
    </ResponsiveContainer>
  );
}
