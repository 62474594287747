import React, {
  useEffect, useState,
} from 'react';
import axios from 'axios';
import {
  NavLink,
  Outlet,
  useParams,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { BsArrowLeft, BsCheck } from 'react-icons/bs';
import { VscSave } from 'react-icons/vsc';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styles from './edit-indicator.module.scss';

import {
  INDICATOR_POST, INDICATOR_PUT, INDICATOR_RESET, SET_REQUEST_LOADING, SET_TOAST,
} from '../../actions/types';
import { createIndicatorOptions, isValidUrl, parseHeader } from '../../utils/utils';
import { getDashboardById } from '../../actions/dashboards';
import {
  setIndicator, createIndicator, updateIndicator, getIndicatorsFieldsAction,
} from '../../actions/indicators';

import Loader from '../../components/Loader';
import { API_URL } from '../../actions';

const EditIndicator = () => {
  const params = useParams();
  const { search, ...location } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const lang = i18n?.language || 'fr';
  const { dashboardReducer, indicatorReducer } = useSelector((store) => store);
  const { dashboard, user } = dashboardReducer;
  const {
    indicators, indicator, isLoading, fields, changedAt, requestLoading,
  } = indicatorReducer;
  const {
    control,
    handleSubmit,
    watch,
    reset,
    setValue,
    setError,
    clearErrors,
    formState: { errors, isDirty },
  } = useForm();
  const currentForm = watch();
  const watchQuery = watch('query');
  const watchConfig = watch('config');
  const [options, setOptions] = useState();

  useEffect(() => () => {
    dispatch({ type: INDICATOR_RESET });
    if (params?.dashboardId && user) {
      getDashboardById(dispatch, params?.dashboardId);
    }
  }, [params?.dashboardId, user]);

  async function fetchRequest() {
    if (requestLoading) {
      return null;
    }
    const {
      key, requests, header,
    } = indicator;

    const field = fields?.find(
      (f) => f.value === currentForm?.config?.params?.name?.value,
    );
    dispatch({
      type: SET_REQUEST_LOADING,
      payload: true,
    });
    const query = encodeURI(watchQuery || '');
    setOptions();
    const res = await axios.post(`${API_URL}/craft/request`, {
      key,
      requests,
      lang,
      query,
      header: parseHeader(header),
      associatedDomainId: watchConfig?.isAssociatedDb
        && indicator?.config?.associatedDb?.value?.associatedDomainId,
      associatedId: field?.type === 'RevertedFromElement' && field?.associatedId,
      startDate: watchConfig?.startDate,
      endDate: watchConfig?.endDate,
    }).catch((err) => {
      if (err.response) {
        setOptions();
        dispatch({
          type: SET_TOAST,
          payload: {
            type: 'error',
            message: err?.response?.data?.error || 'toaster.errors.500',
          },
        });
      }
    });
    if (res?.data) {
      const { data } = res.data;
      await setOptions(createIndicatorOptions(data, fields, requests.includes('/api-stats')));
    }
    dispatch({
      type: SET_REQUEST_LOADING,
      payload: false,
    });
    return null;
  }

  useEffect(() => {
    if (indicators && params?.indicatorId && user) {
      setIndicator(dispatch, params?.indicatorId);
    }
  }, [indicators, params?.indicatorId, user]);

  useEffect(() => {
    if (user
        && isValidUrl(indicator?.requests)
        && fields?.length > 0) {
      fetchRequest();
    }
  }, [
    user,
    fields,
  ]);

  useEffect(() => {
    if (indicator?.key && indicator?.requests) {
      const body = {
        lang,
        key: indicator?.key,
        request: indicator?.requests,
        header: parseHeader(indicator?.header),
        query: watchQuery ? encodeURI(watchQuery) : '',
        associatedDomainId: indicator?.config?.associatedDb?.value?.associatedDomainId,
      };
      dispatch({
        type: SET_REQUEST_LOADING,
        payload: true,
      });
      getIndicatorsFieldsAction(
        dispatch,
        body,
      );
      dispatch({
        type: SET_REQUEST_LOADING,
        payload: false,
      });
    }
  }, [
    indicator,
    currentForm._id,
    indicator?.config.associatedDb?.value?.associatedDomainId,
  ]);

  useEffect(() => {
    if (indicator && user && options?.options) {
      let resetIndicator = { ...indicator };
      if (currentForm?._id) {
        resetIndicator = { ...indicator, ...currentForm };
      }
      if (indicator?.config?.associatedDb?.value?.associatedDomainId) {
        resetIndicator.config.associatedDb = indicator.config.associatedDb;
      }
      if (indicator?.config?.isAssociatedDb) {
        resetIndicator.config.isAssociatedDb = indicator?.config?.isAssociatedDb;
      }
      const name = resetIndicator?.config?.params?.name;
      const key = options?.keys?.find((k) => k.value === name);
      if (key) {
        resetIndicator.config.params.name = key;
      }
      const value = resetIndicator?.config?.params?.value;
      const option = options?.options.find(
        (k) => k.value === value && k.key === name,
      );
      if (option) {
        resetIndicator.config.params.value = option;
      }
      const api = user?.apis?.find((k) => k.value === resetIndicator?.requests);
      if (api) {
        resetIndicator.api = api;
      }
      const apiStat = user?.stats?.find((k) => k.value === resetIndicator?.requests);
      if (apiStat) {
        resetIndicator.api = apiStat;
      }
      const dataset = user?.keys?.find((k) => k.value === resetIndicator?.key);
      if (dataset) {
        resetIndicator.dataset = dataset;
      }
      resetIndicator.query = indicator.query;
      reset(resetIndicator);
    }
  }, [indicator, options?.options, user]);

  useEffect(() => {
    if (!params?.indicatorId && dashboard) {
      reset({
        key: dashboard?.key,
        requests: dashboard?.requests,
        header: dashboard?.header,
        position: dashboard?.indicators?.length ? dashboard.indicators.length + 1 : 0,
        inheritance: true,
      });
    }
  }, [dashboard]);

  async function submit() {
    const newIndicatorData = { config: {}, ...watch() };
    const name = newIndicatorData?.config?.params?.name?.value;
    const value = newIndicatorData?.config?.params?.value?.value;
    if (!newIndicatorData?.config?.isAssociatedDb) {
      newIndicatorData.config.associatedDb = null;
    }
    if (name) {
      newIndicatorData.config.params.name = name;
    }
    if (value) {
      newIndicatorData.config.params.value = value;
    }
    if (!indicator?._id) {
      const res = await createIndicator(dispatch, {
        dashboard: dashboard?._id,
        position: dashboard.indicators.length + 1,
        ...newIndicatorData,
      });
      if (res.status === 201) {
        navigate(`/edit-indicator/${params.dashboardId}/${res.data.indicator._id}/datas${search}`);
      }
    }
    if (indicator?._id) {
      await updateIndicator(dispatch, {
        dashboard: dashboard._id,
        ...newIndicatorData,
      }, indicator?._id);
    }
    return null;
  }

  return (
    <div className={styles.editIndicator}>
      <div className={styles.container}>
        <div className={styles.header}>
          <p className={styles.backward} onClick={() => navigate(`/edit-dashboard/${params?.dashboardId}/indicators${search}`)}><BsArrowLeft /> {t('btn.back')}</p>
          <h1>{indicator?.name ? indicator.name : t('indicator.create')}</h1>
          <div className={styles.containerButton}>
            <button
              className={`${!isDirty ? styles.saved : ''}`}
              type="button"
              onClick={handleSubmit(submit)}
            >
              <span>{isDirty ? t('btn.save') : t('btn.saved')}</span>
              {(isLoading.includes(INDICATOR_POST) || isLoading.includes(INDICATOR_PUT)) ? (
                <span className='icon loader'>
                  <Loader size={18} className='secondary' />
                </span>
              ) : (
                <span className='icon'>
                  {!isDirty ? <BsCheck size={20}/> : <VscSave /> }
                </span>
              )}
            </button>
          </div>
        </div>
        <div className={styles.content}>
          <nav onClick={handleSubmit(submit)}>
            <NavLink to={`informations${search}`} className={({ isActive }) => (isActive ? styles.active : undefined)} >{t('indicator.nav.info')}</NavLink>
            {indicator?._id
              ? <NavLink to={`datas${search}`} className={({ isActive }) => (isActive ? styles.active : undefined)} >{t('indicator.nav.datas')}</NavLink>
              : <a className={styles.disabled}>{t('indicator.nav.datas')}</a>
            }
            {indicator?._id
              ? <NavLink
                to={`custom/params${search}`}
                className={({ isActive }) => (isActive || location.pathname.includes('custom') ? styles.active : undefined)}
              >
                {t('indicator.nav.perso')}
              </NavLink>
              : <a className={styles.disabled}>{t('indicator.nav.perso')}</a>
            }
          </nav>
          <div className={styles.tabs}>
            <Outlet context={{
              control,
              errors,
              watch,
              handleSubmit,
              submit,
              setValue,
              reset,
              options,
              setOptions,
              setError,
              clearErrors,
              changedAt,
            }} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditIndicator;
