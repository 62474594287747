import React, {
  useRef,
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from 'react';
import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';
import { animated, useSpring } from '@react-spring/web';
import styles from './modal.module.scss';

const PositionalModal = forwardRef(({
  children,
  parent,
  alignmentX,
  alignmentY,
  domNode = 'modal',
  defaultOpen = false,
  handleClose,
}, ref) => {
  const modalRoot = document.getElementById(domNode);
  const modalRef = useRef();
  const [open, setOpen] = useState(defaultOpen);
  const [position, setPosition] = useState({ x: -1000, y: -1000 });
  const [toggleAnimation, setToggleAnimation] = useState(false);

  function setPositionModal() {
    const target = parent.current;
    if (!target || !modalRef.current) return;
    const boundParent = target.getBoundingClientRect();
    const boundModal = modalRef.current.getBoundingClientRect();
    let x = alignmentX === 'right' ? boundParent.x - boundModal.width : boundParent.x;
    x = alignmentX === 'middle' ? boundParent.x + (boundParent.width / 2) : boundParent.x;
    if ((x + boundModal.width) > (window.innerWidth - 20)) {
      x = (window.innerWidth - boundModal.width) - 20;
    }

    let { y } = boundParent;
    if (alignmentY === 'middle') {
      y -= boundModal.height / 2;
      y += boundParent.height / 2;
    }
    if (alignmentY === 'bottom') {
      y += boundParent.height;
    }
    if (y < 20) {
      y = 20;
    } else if (y + boundModal.height > (window.innerHeight - 20)) {
      y = (window.innerHeight - boundModal.height) - 20;
    }
    setPosition({
      x,
      y,
    });
  }

  useImperativeHandle(ref, () => ({
    toggle: () => setOpen((state) => {
      if (handleClose && !state) handleClose();
      return !state;
    }),
    open: () => setOpen(true),
    close: () => {
      setOpen(false);
      if (handleClose) handleClose();
    },
    setPositionModal: () => setPositionModal(),
  }));

  function toggle() { setOpen((state) => !state); }

  const spring = useSpring({
    opacity: toggleAnimation ? 1 : 0,
    onRest: (state) => {
      if (state.value.opacity === 0) toggle();
    },
  });

  useEffect(() => {
    if (!ref) console.error('PositionalModal components need require a ref');
  }, [ref]);

  useEffect(() => {
    if (!modalRoot) {
      console.error(`Can't find the dom element (#${domNode}) where this modal should be mount \nYou should add a div with id : "${domNode}" to public/index.html
     `);
    }
  }, [modalRoot, domNode]);

  // HANDLE CLOSE MODAL WHEN CLICK OUTSITE
  // EXCEPT IT TARGET ELEMENT HAS ATTRIBUTE
  // data-close-modal='false'
  useEffect(() => {
    const root = document.getElementById('root');
    const closeModal = (e) => {
      if (open && !e.target.closest("[data-close-modal='false']")) {
        setToggleAnimation(false);
        if (handleClose) handleClose();
      }
    };
    root.addEventListener('click', closeModal);
    root.addEventListener('wheel', closeModal);
    return () => {
      root.removeEventListener('wheel', closeModal);
      root.removeEventListener('click', closeModal);
    };
  }, [open]);

  useEffect(() => {
    if (open) {
      setToggleAnimation(true);
    }
  }, [open]);

  useEffect(() => {
    if (!open) return;
    setPositionModal();
  }, [parent, open]);

  function handleBlur(e) {
    const focusElement = e.relatedTarget?.closest("[data-close-modal='false']");
    if (!focusElement) {
      setToggleAnimation(false);
      if (handleClose) handleClose();
    }
  }

  return (
    <>
      {modalRoot
        && <>
          {createPortal(
            <>
              {(ref && open)
                && <>
                  <animated.div
                    ref={modalRef}
                    style={{ ...spring, top: `${position.y}px`, left: `${position.x}px` }}
                    className={styles.modal}
                    tabIndex="0"
                    onBlur={(e) => handleBlur(e)}
                  >
                    {children}
                  </animated.div>
                </>
              }
            </>,
            modalRoot,
          )}
        </>
      }
    </>
  );
});

PositionalModal.propTypes = {
  domNode: PropTypes.string,
};

export default PositionalModal;
