import React from 'react';
import { useController } from 'react-hook-form';
import styles from './input-color.module.scss';

const InputColor = ({
  name,
  control,
  label = null,
  widthLabel = null,
  defaultValue = '#6779C4',
  placeholder = '',
  noHex = true,
  size = 'sm',
  id,
  rules,
}) => {
  const { field } = useController({
    name,
    control,
    defaultValue,
    rules,
  });

  const getSize = () => {
    let dimension = 50;
    if (size === 'sm') dimension = 30;
    return `${dimension}px`;
  };

  return (
    <div className={styles.input}>
      {label && (
        <label htmlFor={id} style={widthLabel ? { minWidth: widthLabel } : {}}>{label}</label>
      )}
      <div
        className={styles.color}
        style={{
          backgroundColor: field.value,
          width: getSize(),
          height: getSize(),
        }}
      >
        {field.value
        && <input
          className={styles.inputColor}
          name={name}
          id={id}
          type="color"
          {...field}
        />
        }
      </div>
      {!noHex && (
        <input
          className={styles.inputText}
          id={id}
          type="text"
          {...field}
          placeholder={placeholder}
        />
      )}
    </div>
  );
};

export default InputColor;
