import * as React from 'react';

const HistogramIcon = ({ selected, ...rest }) => (
  <svg
    width={28}
    height={28}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M18 3a3 3 0 0 0-3-3h-2a3 3 0 0 0-3 3v25h8V3Zm2 3v22h7a1 1 0 0 0 1-1V10a4 4 0 0 0-4-4h-4ZM4 12h4v16H1a1 1 0 0 1-1-1V16a4 4 0 0 1 4-4Z"
      fill={selected ? '#FFFFFF' : '#6879C4' }
    />
  </svg>
);

export default HistogramIcon;
