/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import React, {
  useEffect, useState, useRef, useLayoutEffect,
} from 'react';
import * as d3 from 'd3';
import InputRange from 'react-input-range';
import * as IconsExported from 'react-icons/io';
import 'react-input-range/lib/css/index.css';
import { BsFillInfoCircleFill } from 'react-icons/bs';
import { IoFilter, IoClose } from 'react-icons/io5';
import './slider.scss';
import styles from './Indicator.module.scss';
import CustomPie from '../Pie';
import KeyNumber from '../KeyNumber';
import CustomLine from '../Line';
import CustomBars from '../Bars';
import Bubbles from '../Bubbles';
import CustomTreemap from '../Treemap';
import WordCloud from '../WordCloud';
import KeyNumberExtended from '../KeyNumberExtended';

export default function Indicator({ indicator = {}, isFilter = false }) {
  const {
    graph, data, name, config,
  } = indicator;

  const [legendIsActive, setLegendIsActive] = useState();
  const [filterIsActive, setFilterIsActive] = useState();
  const [filteredData, setFilteredData] = useState([]);
  const [range, setRange] = useState([0, 1]);
  const [selectedRange, setSelectedRange] = useState();
  const [changeCompleted, setChangeCompleted] = useState(false);
  const [titlePosition, setTitlePosition] = useState({
    position: 'absolute',
    display: 'block',
  });
  const containerRef = useRef(null);
  const titleRef = useRef(null);

  const renderIcon = (selectedIcon, color) => {
    const icon = Object.entries(IconsExported).find(([key]) => key === selectedIcon)[1];
    return icon({ color, size: 20 });
  };

  useEffect(() => {
    if (data?.length > 0 && !config?.legend?.stacked) {
      const values = data?.map((d) => d.value) || [];
      const max = (d3.max(values) + 1);
      const min = d3.min(values);
      setRange({ min, max });
      setSelectedRange({ min, max });
      setFilteredData(data);
    }
    if (data?.length > 0 && config?.legend?.stacked) {
      let max = 0;
      let min = 0;
      data?.forEach((d) => {
        for (const property in d) {
          if (typeof d[property] === 'number') {
            if (d[property] > max) {
              max = d[property] + 1;
            }
            if (d[property] < min) {
              min = d[property];
            }
          }
        }
      });
      setRange({ min, max });
      setSelectedRange({ min, max });
      setFilteredData(data);
    }
  }, [data, config?.legend?.stacked]);

  useEffect(() => {
    if (data?.length > 0) {
      const filtered = [];
      data.forEach((d) => {
        if (config?.legend?.stacked) {
          const obj = { ...d };
          for (const property in d) {
            if (typeof obj[property] === 'number') {
              if (obj[property] > selectedRange?.max) {
                obj.count -= obj[property];
                delete obj[property];
              }
              if (obj[property] < selectedRange?.min) {
                obj.count -= obj[property];
                delete obj[property];
              }
            }
          }
          return filtered.push(obj);
        } if (d.value < selectedRange?.min || d.value > selectedRange?.max) {
          return null;
        }
        return filtered.push(d);
      });
      setFilteredData(filtered);
    }
  }, [changeCompleted]);

  useEffect(() => {
    if (config?.legend?.display === 'already') {
      setLegendIsActive(true);
    }
  }, [config?.legend?.display]);

  const legendHover = () => {
    if (config?.legend?.display === 'hover') {
      setLegendIsActive(true);
    }
  };

  const legendLeave = () => {
    if (config?.legend?.display === 'hover') {
      setLegendIsActive(false);
    }
  };

  useLayoutEffect(() => {
    const rect = containerRef?.current?.getBoundingClientRect();
    const titleRect = titleRef?.current?.getBoundingClientRect();
    const style = {
      position: 'absolute',
      display: 'block',
    };
    if (!config?.legend?.titlePosition || config?.legend?.titlePosition === 'TL') {
      style.top = 10;
      style.left = 15;
    }
    if (config?.legend?.titlePosition === 'TC') {
      style.top = 10;
      style.left = (rect.width / 2) - (titleRect.width / 2);
    }
    if (config?.legend?.titlePosition === 'TR') {
      style.top = 10;
      style.right = 15;
    }
    if (config?.legend?.titlePosition === 'LC') {
      style.top = (rect.height / 2) - (titleRect.height / 2);
      style.left = 15;
    }
    if (config?.legend?.titlePosition === 'RC') {
      style.top = (rect.height / 2) - (titleRect.height / 2);
      style.right = 15;
    }
    if (config?.legend?.titlePosition === 'BL') {
      style.bottom = 10;
      style.left = 15;
    }
    if (config?.legend?.titlePosition === 'BC') {
      style.bottom = 10;
      style.left = (rect.width / 2) - (titleRect.width / 2);
    }
    if (config?.legend?.titlePosition === 'BR') {
      style.bottom = 10;
      style.right = 15;
    }
    setTitlePosition(style);
  }, [containerRef?.current, titleRef?.current]);

  return (
    <div ref={containerRef} className={`${styles.container} ${isFilter ? styles.filter : ''}`}>
      <label
        ref={titleRef}
        style={titlePosition}
        className={styles.name}>
          {config?.legend?.title || name}
      </label>
      <div className={styles.actions}>
        {config?.legend?.isActive && graph !== 'key_number'
          && (<button
            onClick={() => setFilterIsActive(!filterIsActive)}
            className={`${styles.filter} ${filterIsActive ? styles.active : ''}`}
          >
              <IoFilter />
              {(range?.max !== selectedRange?.max || range?.min !== selectedRange?.min)
                && <div className={styles.puce} />
              }
          </button>
          )}
        {config?.legend?.isActive && (config?.legend?.display !== 'already')
          && (<button
            onClick={() => setLegendIsActive(!legendIsActive)}
            onMouseOver={() => legendHover()}
            className={legendIsActive ? styles.active : ''}
          >
            {config?.legend?.icon?.iconKey && IconsExported[config?.legend?.icon?.iconKey]
              ? renderIcon(config?.legend?.icon?.iconKey, config?.legend?.icon?.color)
              : <BsFillInfoCircleFill />
            }
          </button>
          )}
      </div>
      <div
        onMouseLeave={() => legendLeave()}
        className={`${styles.legend} ${legendIsActive ? styles.active : ''}`}>
          {!config?.legend?.stacked && data?.length > 0 && (<ul>
            {data?.filter((d) => d.isOnLegend).sort((a, b) => b.value - a.value).map((d) => (
              <li key={`${name}-${d.id}`}>
                <div className={styles.icon} style={{ backgroundColor: d.color }}/>
                <p>{d.name} {d?.value && config?.legend?.isValueOnLegend && `(${d.unit ? d.value.toFixed(2) : d.value}${d.unit ? d.unit : ''})`}</p>
              </li>
            ))}
          </ul>)}
          {config?.legend?.stacked && (<ul>
            {config?.legend?.stacked?.filter((d) => d.isOnLegend).map((d) => (
              <li key={`${name}-${d.name}`}>
                <div className={styles.icon} style={{ backgroundColor: d.color }}/>
                <p>{d.name} {d?.value && config?.legend?.isValueOnLegend && `(${d.unit ? d.value.toFixed(2) : d.value}${d.unit ? d.unit : ''})`}</p>
              </li>
            ))}
          </ul>)}
      </div>
      <div className={`${styles.filters} ${filterIsActive ? styles.active : ''}`}>
        <div className={styles.title}>
          <p>Filtres</p>
          <button
            onClick={() => setFilterIsActive(!filterIsActive)}
          >
              <IoClose />
          </button>
        </div>
        <div className={styles.range}>
          {range?.max > range?.min
            && <InputRange
              maxValue={range.max + 0.5}
              minValue={range.min - 0.5}
              value={selectedRange}
              formatLabel={(value) => `${data[0] && data[0].unit === '%' ? `${value?.toFixed(0)} %` : value}`}
              onChange={(value) => setSelectedRange(value)}
              onChangeComplete={() => setChangeCompleted(!changeCompleted)}
            />
          }
        </div>
      </div>
      <div className={`${styles.graph} ${config?.legend?.display === 'already' ? styles['is-legend'] : ''}`}>
      {graph === 'key_number' && data?.length > 0
        && <>
            <KeyNumber data={data[0]} label={config?.legend?.description} />
        </>
      }
      {graph === 'key_number_extended' && data?.length > 0
        && <>
            <KeyNumberExtended data={data} label={config?.legend?.description} />
        </>
      }
      {graph === 'word_cloud' && data?.length > 0
        && <>
          <WordCloud data={data} />
        </>
      }
      {graph === 'lines' && filteredData?.length > 0
        && <>
            <CustomLine
              data={filteredData}
              color={config?.legend?.color}
              isReversed={config.reverseAxis}
          />
        </>
      }
      {graph === 'bubbles' && filteredData?.length > 0
        && <>
            <Bubbles data={filteredData} />
        </>
      }
      {graph === 'histogram' && filteredData?.length > 0
        && <>
          <CustomBars
            data={filteredData}
            stacked={config?.legend?.stacked}
            isReversed={config.reverseAxis}
          />
        </>
      }
      {graph === 'treemap' && filteredData?.length > 0
        && <>
            <CustomTreemap data={filteredData} />
        </>
      }
      {graph === 'pie' && filteredData?.length > 0
        && <>
            <CustomPie data={filteredData} isLabel={config?.legend?.isLabelOnGraph} />
        </>
      }
      </div>
    </div>
  );
}
