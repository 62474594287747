import * as React from 'react';

const BubblesIcon = ({ selected, ...rest }) => (
  <svg
    width={49}
    height={49}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M14.7 35.933a6.533 6.533 0 1 0 0-13.066 6.533 6.533 0 0 0 0 13.066ZM30.217 40.833a4.083 4.083 0 1 0 0-8.166 4.083 4.083 0 0 0 0 8.166ZM31.033 27.767c5.413 0 9.8-4.388 9.8-9.8 0-5.413-4.387-9.8-9.8-9.8-5.412 0-9.8 4.387-9.8 9.8 0 5.412 4.388 9.8 9.8 9.8Z"
      fill={selected ? '#FFFFFF' : '#6879C4' }
    />
  </svg>
);

export default BubblesIcon;
