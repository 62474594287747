import React, { useEffect, useRef } from 'react';
import { FileUploader } from 'react-drag-drop-files';
import { useTranslation } from 'react-i18next';
import { MdCloudUpload } from 'react-icons/md';
import { RiDeleteBin6Line } from 'react-icons/ri';
import styles from './file-upload.module.scss';

const fileTypes = ['jpg', 'jpeg', 'png', 'gif'];

const FileUploaderComponent = ({
  label = '',
  handleChangeFile,
  required = false,
  types = null,
  file,
  path = null,
}) => {
  const dropZoneRef = useRef();
  const prevElementTrigger = useRef();
  const { t } = useTranslation();
  useEffect(() => {
    dropZoneRef.current.parentNode.onclick = (e) => {
      if (e.target.id === 'trigger') {
        prevElementTrigger.current = e.target.id;
      } else {
        if (prevElementTrigger.current !== 'trigger') {
          e.preventDefault();
        }
        prevElementTrigger.current = null;
      }
    };
  }, []);

  console.log(path);

  return (
    <div >
      <FileUploader
        handleChange={(value) => {
          handleChangeFile(value);
        }}
        name="file"
        label={label}
        types={types || fileTypes}
        classes={styles.inputFile}
        hoverTitle={' '}
        onTypeError={(e) => console.log(e)}
        onSizeError={(e) => console.log(e)}
      >
        <div
          ref={dropZoneRef}
          className={styles.dropZone}
        >
          {label && (
            <label>{label} {!required && <span>({t('optional')})</span>}</label>
          )}
          <div className={styles.containerButton}>
            <button
              id='trigger'
              className={`${file ? ` ${styles.withFile}` : ''}`}
            >
              {path ? (
                <img src={path} />
              ) : (
                <>
                  <MdCloudUpload size='40px' />
                  <span>
                    {t('file.drop')}{' '}
                    <span className={styles.underline}>
                    {t('file.select')}</span>
                  </span>
                </>
              )}

            </button>

            {path && (
              <div className={styles.removeFile}>
                <p id='trigger'>{t('file.replace')}</p>
                <span className={styles.icon} id='remove' onClick={() => {
                  handleChangeFile(null);
                }}>
                  <RiDeleteBin6Line size='20px' />
                </span>
              </div>
            )}
          </div>
        </div>
      </FileUploader>
    </div>
  );
};

export default FileUploaderComponent;
