import React, { useCallback, useEffect } from 'react';
import {
  Routes,
  Route,
  useSearchParams,
  useNavigate,
  useLocation,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Dashboard from './pages/dashboard';
import DashboardView from './pages/dashboard-view';
import CreateDashboard from './pages/create-dashboard';
import EditDashboard from './pages/edit-dashboard';
import Indicators from './pages/edit-dashboard/indicators';
import Layout from './pages/edit-dashboard/layout';
import Embed from './pages/edit-dashboard/embed';
import Share from './pages/edit-dashboard/embed/share';
import Files from './pages/edit-dashboard/embed/files';
import Users from './pages/edit-dashboard/embed/users';
import EditIndicator from './pages/edit-indicator';
import Informations from './pages/edit-indicator/informations';
import Data from './pages/edit-indicator/data';
import Custom from './pages/edit-indicator/custom';

import Gui from './pages/gui';
import Graph from './pages/edit-indicator/custom/Graph';
import Params from './pages/edit-indicator/custom/Params';
import Legend from './pages/edit-indicator/custom/Legend';
import Filters from './pages/edit-indicator/custom/Filters';
import Preview from './pages/edit-indicator/custom/Preview';
import Error from './pages/Error/Error';
import { getDashboardList, getUser } from './actions/dashboards';
import Allow from './pages/edit-dashboard/embed/Allow/Allow';
import Toaster from './components/Toaster/Toaster';
import { DASHBOARD_ERROR } from './actions/types';

const languges = ['fr', 'en', 'es'];

const AppRoutes = () => (
  <Routes>
    <Route path='/*' element={<ContainerRoutes />} />
  </Routes>
);

const ContainerRoutes = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { i18n } = useTranslation();
  const { toast, error } = useSelector((store) => store.dashboardReducer);
  const IdUtil = searchParams.get('IdUtil');
  const language = searchParams.get('lang');

  function handleChangeLang(lang) {
    localStorage.setItem('lang', lang);
    i18n.changeLanguage(lang);
  }

  const handleError = useCallback(() => {
    navigate('/error');
    dispatch({
      type: DASHBOARD_ERROR,
      payload: null,
    });
  }, [dispatch]);

  useEffect(() => {
    if (!IdUtil) {
      navigate('/error');
    } else {
      try {
        getUser(dispatch, location.search);
        getDashboardList(dispatch);
      } catch (err) {
        navigate('/error');
      }
    }
  }, [IdUtil]);

  useEffect(() => {
    if (window.navigator.language) {
      let lang = language || window.navigator.language.split('-')[0];
      if (!languges.find((l) => l === lang)) {
        lang = 'fr';
      }
      handleChangeLang(lang);
    }
  }, []);

  useEffect(() => {
    if (error) {
      handleError();
    }
  }, [error]);

  return (
    <>
      <Routes>
        <Route index element={<Dashboard isFavorite={true} />} />
        <Route path='dashboard-list' element={<Dashboard />} />
        <Route path='bdi-dashboard/:dashboardId' element={<DashboardView />} />
        <Route path='preview-dashboard/:dashboardId' element={<DashboardView isPreview />} />
        <Route path='config-dashboard/:dashboardId' element={<CreateDashboard />} />
        <Route path='create-dashboard' element={<CreateDashboard />} />
        <Route path='edit-dashboard/:dashboardId' element={<EditDashboard />}>
          <Route path='indicators' element={<Indicators />} />
          <Route path='layout' element={<Layout />} />
          <Route path='embed' element={<Embed />}>
            <Route path='share' element={<Share />} />
            <Route path='allow' element={<Allow />} />
            <Route path='files' element={<Files />} />
            <Route path='users' element={<Users />} />
          </Route>
        </Route>
        <Route path='edit-indicator/:dashboardId' element={<EditIndicator />}>
          <Route path='informations' element={<Informations />} />
          <Route path='datas' element={<Data />} />
          <Route path='custom' element={<Custom />} />
        </Route>
        <Route path='edit-indicator/:dashboardId/:indicatorId' element={<EditIndicator />}>
          <Route path='informations' element={<Informations />} />
          <Route path='datas' element={<Data />} />
          <Route path='custom' element={<Custom />}>
            <Route path='graph' element={<Graph />} />
            <Route path='params' element={<Params />} />
            <Route path='legend' element={<Legend />} />
            <Route path='filters' element={<Filters />} />
            <Route path='preview' element={<Preview />} />
          </Route>
        </Route>
        <Route path='/error' element={<Error/>} />

        {process.env.NODE_ENV === 'development' && (
          <Route path='/gui' element={<Gui />} />
        )}
      </Routes>
      <Toaster dispatch={dispatch} toast={toast}/>
    </>
  );
};

export default AppRoutes;
