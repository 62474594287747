import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { BiLock, BiLockOpen } from 'react-icons/bi';
import { RiDeleteBin6Line } from 'react-icons/ri';
import styles from './input-text.module.scss';

const InputText = ({
  name,
  control,
  rules,
  widthLabel = null,
  inline = false,
  label = null,
  placeholder,
  defaultValue = '',
  className,
  handleChange,
  disabled,
  type = 'text',
  isLockable = false,
  isClearable = false,
}) => {
  const [lock, setLock] = useState(isLockable);
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      defaultValue={defaultValue}
      render={({
        field: {
          // name,
          value = '',
          ref,
          onChange,
          onBlur,
        },
      }) => {
        function handlePressEnter(e) {
          if (e.key === 'Enter' && e.target) {
            e.target.blur();
          }
        }

        function getStyle() {
          let resClass = styles['container-input'];
          if (className) {
            resClass += ` ${styles[className]}`;
          }
          if (inline) {
            resClass += ` ${styles.inline}`;
          }
          return resClass;
        }

        return (
          <div
            onKeyUp={(e) => handlePressEnter(e)}
            className={getStyle()}
          >
            {label && <label style={widthLabel ? { minWidth: widthLabel } : {}}>{label}</label>}
            <div className={
                (isLockable || isClearable) ? `${styles.containerInput} ${styles.withIcon}` : styles.containerInput
              }>
              <input
                ref={ref}
                disabled={isLockable ? lock : disabled}
                value={value}
                type={type}
                onBlur={onBlur}
                onChange={(e) => {
                  if (handleChange) handleChange(e.target.value);
                  onChange(e.target.value);
                }}
                className={styles.input}
                placeholder={placeholder}
              />
              {isLockable && (
                <span className={styles.locker}>
                  {!lock && <p onClick={() => onChange(defaultValue)}>Reset</p>}
                  <span onClick={() => setLock((state) => !state)}>
                    {lock ? <BiLock size={24}/> : <BiLockOpen size={24}/>}
                  </span>
                </span>
              )}
              {(!isLockable && isClearable) && (
                <span onClick={() => onChange('')} className={styles.clearable}>
                  <span>
                    <RiDeleteBin6Line size={20}/>
                  </span>
                </span>
              )}
            </div>
          </div>
        );
      }}
    />
  );
};

InputText.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  defaultValue: PropTypes.string,
  rules: PropTypes.object,
  label: PropTypes.string,
  inline: PropTypes.bool,
  widthLabel: PropTypes.string,
  placeholder: PropTypes.string,
};

export default InputText;
