import * as React from 'react';

const BoxplotIcon = ({ selected, ...rest }) => (
  <svg
    width={45}
    height={19}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M0 9.5h44M1.5 5v9M43 5v9"
      stroke={selected ? '#FFFFFF' : '#6879C4' }
      strokeWidth={3}
    />
    <path
      fill={!selected ? '#FFFFFF' : '#6879C4' }
      stroke={selected ? '#FFFFFF' : '#6879C4' }
      strokeWidth={3}
      d="M14.5 1.5h16v16h-16z"
    />
  </svg>
);

export default BoxplotIcon;
