import React from 'react';
import styles from './CustomTooltip.module.scss';

export default function CustomTooltip({
  active, payload, label, isStacked,
}) {
  if (active && payload && payload.length > 0) {
    const [data] = payload;
    let value = `${data.value}`;
    if (data?.payload?.unit === '%') {
      value = `${data.value.toFixed(2)} ${data?.payload?.unit}`;
    }
    return (
      <div className={styles.container}>
        {isStacked
          ? <>
            <p>{data?.payload?.name || label}</p>
            <div className={styles.stacked}>
              {payload
                .sort((a, b) => (b.value - a.value))
                .map((p) => <p key={p.dataKey}>
                <span style={{ color: p.color }}>{p.dataKey}</span> {p.value}
              </p>)}
            </div>
          </>
          : <p>{`${data?.payload?.name || label} : ${value}`}</p>

        }
      </div>
    );
  }
}
