import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { InputColor, StyledSwitch } from '../../lib/HooksFormFields';

export default function LegendRow({ item, styles, handleChange }) {
  const {
    control,
    watch,
    formState: { isDirty },
  } = useForm({
    defaultValues: item,
  });
  const itemData = watch();
  const color = isDirty && itemData.color !== item.color ? itemData.color : null;

  useEffect(() => {
    if (item.isOnLegend !== itemData.isOnLegend) {
      handleChange(itemData);
    }
  }, [itemData.isOnLegend]);

  return (
    <div className={`${styles.row} ${!itemData.isOnLegend ? styles.disabled : ''}`}>
      <div className={styles.col}>
        <p>{item.name}</p>
      </div>
      <div
        onBlur={color ? () => handleChange({ ...itemData, color }) : null}
        className={styles.col}>
        <InputColor
          name='color'
          control={control}
          id={item.name}
          noHex={true}
        />
      </div>
      <div className={styles.col}>
        <StyledSwitch
          name="isOnLegend"
          control={control}
        />
      </div>
    </div>
  );
}
