/* eslint-disable import/no-named-as-default */
/* eslint-disable no-restricted-syntax */
/* eslint-disable prefer-regex-literals */
/* eslint-disable no-control-regex */
import * as d3 from 'd3';
import { format } from 'date-fns';
import { getAssociatedLabelsAction } from '../actions/indicators';
import filterData from './filterData';
// eslint-disable-next-line import/no-cycle
import {
  colorScale,
  defaultColor, getPercantage, isValidDate, translateDate,
} from './utils';

const generateEntitiesData = async (
  options = {},
  config,
  graph,
  prevData = [],
) => {
  const initData = options?.data || [];
  const aggregation = options?.aggregations;
  const entitiesData = options?.entitiesData || [];
  const filters = config?.filters;
  const lang = localStorage.getItem('lang') || 'fr';
  // APPLY FILTERS ON DATA
  let array = filterData([...initData], filters);
  let data = [];
  const property = config?.params?.name;
  const calc = config?.params?.value?.value;
  const labels = [];
  const result = {
    data: [],
    options: {
      property,
    },
  };
  if (process.env.NODE_ENV === 'development') {
    console.log('generateEntitiesData', config, options);
  }
  const associatedId = property?.associatedId || property?.associatedDomainId;
  if (associatedId && config?.associetedConfig) {
    const associatedConfig = {
      lang,
      associatedId,
      ...config.associetedConfig,
    };
    const res = await getAssociatedLabelsAction(associatedConfig);
    console.log(res);
    res.data.forEach((v) => {
      labels.push({
        element_id: v.element_id,
        label: v.label,
      });
    });
  }

  // GET PREV COLOR
  function getPrevColor(id) {
    const color = prevData?.find((p) => p.id === id)?.color;
    if (Number.isInteger(color)) {
      return null;
    }
    return color;
  }

  // PREPARE DATA FOR AGGREGARTION

  const aggregatedId = property?.value?.split('.')[1];
  if (aggregatedId && property?.value.includes('aggregation.')) {
    const aggregated = aggregation
    // eslint-disable-next-line no-prototype-builtins
    && aggregation?.hasOwnProperty(aggregatedId) && aggregation[aggregatedId];
    if (!aggregated?.buckets) {
      return result;
    }
    aggregated?.buckets.forEach((b) => {
      let name = null;
      initData.forEach((d) => {
        const items = d[aggregatedId];
        const foundItem = Array.isArray(items) && items?.find((el) => el.element_id === b.key);
        name = foundItem?.label
        || foundItem?.core_hasListName
        || foundItem?.title
        || foundItem?.name;
        if (items?.element_id === b.key) {
          name = items?.label
          || items?.core_hasListName
          || items?.title
          || items?.name;
        }
        const entityLabel = entitiesData?.find((e) => e.entity_id === b.key)?.Nom;
        if (entityLabel) {
          name = entityLabel;
        }
        const associatedLabel = labels.find((e) => e.element_id === b.key)?.label;
        if (associatedLabel) {
          name = associatedLabel;
        }
        if (typeof d[aggregatedId] === 'string' && !name) {
          name = b.key;
        }
        if (b.key === 'false' || b.key === 'true') {
          name = b.key === 'false' ? 'Non' : 'Oui';
        }
        if (property?.type === 'Date' && !name) {
          const date = b.key && new Date(b.key);
          if (date && isValidDate(date)) {
            if (config.dateFormat === 'date') {
              name = format(date, 'dd/MM/yyyy');
            }
            if (config.dateFormat === 'month') {
              name = new Intl.DateTimeFormat(lang, { month: 'long', year: 'numeric' }).format(date);
            }
            if (config.dateFormat === 'week') {
              const startDate = new Date(date.getFullYear(), 0, 1);
              const days = Math.floor((date - startDate) / (24 * 60 * 60 * 1000));
              const weekNumber = Math.ceil(days / 7);
              name = `${translateDate[lang].week} ${weekNumber} ${date.getFullYear()}`;
            }
            if (config.dateFormat === 'semester') {
              const semester = date.getMonth() < 7 ? 1 : 2;
              name = `${translateDate[lang].semester} ${semester} ${date.getFullYear()}`;
            }
            if (config.dateFormat === 'year') {
              name = date.getFullYear();
            }
          }
        }
      });
      if (name && !result?.data?.find((r) => r.name === name)) {
        const aggItem = {
          name,
          value: b.count,
          key: calc,
          isOnLegend: true,
          id: name,
        };
        result.data.push(aggItem);
      }
      const index = result.data.findIndex((r) => r.name === name);
      if (property?.type === 'Date' && index !== -1) {
        result.data[index].value += b.count;
      }
    });
    let count = [...result.data].reduce((acc, obj) => acc + obj.value, 0);
    if (graph === 'key_number') {
      if (calc === 'SUM_ABSOLUT') {
        count = aggregated?.buckets.map((e) => parseFloat(e.key))
          .reduce((acc, val) => acc + val, 0);
      }
      data.push({
        name: property.label,
        value: count,
        color: defaultColor,
        key: 'COUNT_ABSOLUT',
        isOnLegend: true,
        id: property.label,
      });
      result.data = data;
    }
    if (calc === 'COUNT_SEPARE_LIST') {
      result.data = result.data.map((d) => ({
        ...d, value: getPercantage(d.value, count), unit: '%',
      }));
      if (!config.ascending) {
        result.data = result.data.reverse();
      }
    }
    if (graph === 'histogram' || graph === 'word_cloud') {
      result.data = result.data.map((d) => ({
        ...d,
        color: defaultColor,
      })).sort((a, b) => a.value - b.value);
      if (!config.ascending) {
        result.data = result.data.reverse();
      }
    }
    if (graph === 'pie' || graph === 'bubbles' || graph === 'treemap') {
      const max = d3.max(result.data.map((d) => d.value));
      const min = d3.min(result.data.map((d) => d.value));
      result.data = result.data
        .sort((a, b) => (b.value - a.value))
        .map((d) => ({ ...d, color: getPrevColor(d.id) || colorScale(d.value, max, min) }));
    }
    if (graph === 'lines') {
      result.data = result.data.map((d) => ({
        ...d,
        color: getPrevColor(d.id) || defaultColor,
      })).sort((a, b) => a.value - b.value);
    }
    return result;
  }

  // CLEAR DATA IF NO RESULT
  if (array?.length === 0 || !property?.value) {
    return result;
  }
  // GET LABEL FOR ONLY ENTITI_ID

  if (property?.type === 'RevertedFromElement') {
    array = array.map((a) => {
      const item = { ...a };
      if (a[property?.value] && Array.isArray(a[property?.value])) {
        item[property?.value] = a[property?.value].map((e) => {
          const entity = { ...e };
          return entity;
        });
      }
      return item;
    });
  }

  if (property?.type === 'RevertedFromEntity' || property?.type === 'MultipleEntityLink') {
    array = array.map((a) => {
      const item = { ...a };
      if (a[property?.value] && Array.isArray(a[property?.value])) {
        item[property?.value] = a[property?.value].map((e) => {
          const label = initData?.find((d) => d.entity_id === e.element_id)?.label
            || initData?.find((d) => d.element_id === e.element_id)?.label
            || labels?.find((d) => d.element_id === e.element_id)?.label
            || entitiesData?.find((d) => d.entity_id === e.element_id)?.Nom
            || e.element_id;
          const entity = { ...e, label };
          return entity;
        });
      }
      return item;
    });
  }
  // FORMAT DATE
  if (property?.type === 'Date' && config.dateFormat) {
    array = array?.map((a) => {
      const obj = { ...a };
      const date = a[property.value] && new Date(a[property.value]);
      if (date && isValidDate(date)) {
        obj.date = date;
        if (config.dateFormat === 'date') {
          obj[property.value] = format(date, 'dd/MM/yyyy');
        }
        if (config.dateFormat === 'month') {
          obj[property.value] = new Intl.DateTimeFormat(lang, { month: 'long', year: 'numeric' }).format(date);
        }
        if (config.dateFormat === 'week') {
          const startDate = new Date(date.getFullYear(), 0, 1);
          const days = Math.floor((date - startDate) / (24 * 60 * 60 * 1000));
          const weekNumber = Math.ceil(days / 7);
          obj[property.value] = `${translateDate[lang].week} ${weekNumber} ${date.getFullYear()}`;
        }
        if (config.dateFormat === 'semester') {
          const semester = date.getMonth() < 7 ? 1 : 2;
          obj[property.value] = `${translateDate[lang].semester} ${semester} ${date.getFullYear()}`;
        }
        if (config.dateFormat === 'year') {
          obj[property.value] = date.getFullYear();
        }
      }
      return obj;
    }).sort(
      (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime(),
    );
  }
  // FORMAT BOOLEAN
  if (property?.type === 'Boolean') {
    array = array?.map((a) => ({ ...a, [property.value]: a[property.value] === 'true' ? 'Oui' : 'Non' }));
  }
  // FORMAT TEXTAREA
  if (property?.type === 'Textarea') {
    let splitWords = [];
    if (config.splitWords === 'no') {
      array?.forEach((a) => {
        const sentence = a[property.value] || null;
        if (sentence) {
          splitWords.push({ [property.value]: sentence });
        }
      });
    }
    if (config.splitWords === 'yes') {
      array?.forEach((a) => {
        const sentence = a[property.value] || null;
        const words = sentence?.split(' ');
        words?.forEach((w) => {
          if (w.length > 4) {
            const word = w.toLowerCase()
              .replace('(', '')
              .replace(')', '')
              .replace(',', '');
            splitWords.push({ [property.value]: word });
          }
        });
      });
    }
    if (config.splitWords === 'regex' && config.regex) {
      try {
        const match = config.regex.match(new RegExp('^/(.*?)/([gimy]*)$'));
        if (match) {
          const regex = new RegExp(match[1], match[2]);
          let words = '';
          array?.forEach((a) => {
            const sentence = a[property.value] || null;
            if (sentence) {
              words += sentence;
            }
          });
          words?.split(regex)?.map((w) => ({ [property.value]: w })).forEach((w) => {
            const index = splitWords.findIndex((a) => a[property.value] === w[property.value]);
            if (index !== -1) {
              splitWords[index].value += 1;
            } else {
              splitWords.push({ ...w, value: 1 });
            }
          });
        } else {
          result.error = {
            type: 'regex',
            message: "l'expression n'est pas valide",
          };
        }
        // }
      } catch (error) {
        console.error(error);
      }
    }
    splitWords = splitWords.filter((s) => {
      const count = splitWords.filter((sp) => sp[property.value] === s[property.value])?.length;
      if (count < 3) {
        return null;
      }
      return { ...s, count };
    });
    if (config.splitWords !== 'no' && splitWords.length > 100) {
      result.error = {
        type: 'config.splitWords',
        message: `Le résultat contient ${splitWords.length} mots`,
      };
    }
    if (graph === 'word_cloud') {
      array = splitWords;
    }
  }

  // CALC DATA
  if (calc === 'COUNT_ABSOLUT_BRUT') {
    const count = array.length;
    if (graph === 'key_number') {
      data.push({
        name: property.label,
        value: count,
        color: defaultColor,
        key: 'COUNT_ABSOLUT',
        isOnLegend: true,
        id: property.label,
      });
      result.data = data;
    }
  }
  if (calc === 'SUM_ABSOLUT') {
    let count = array.map((a) => (
      parseFloat(a[property.value]) > 0 ? parseFloat(a[property.value]) : null
    )).reduce(
      (previousValue, currentValue) => previousValue + currentValue,
      0,
    );
    if (property.type === 'OrderedMultipleChoiceList' || property.type === 'MultipleChoiceList') {
      count = array.map((a) => (
        a[property.value].length ? 1 : 0
      )).reduce(
        (previousValue, currentValue) => previousValue + currentValue,
        0,
      );
    }
    if (graph.includes('key_number')) {
      data.push({
        name: property.label,
        value: count,
        color: defaultColor,
        key: 'COUNT_ABSOLUT',
        isOnLegend: true,
        id: property.label,
      });
      if (graph === 'key_number_extended') {
        data.push({
          name: 'Moyenne',
          value: count / array.length,
          color: defaultColor,
          key: 'COUNT_ABSOLUT',
          isOnLegend: true,
          id: `${property.label}Moyenne`,
        });
        data.push({
          name: 'Nombre de jour',
          value: array.length,
          color: defaultColor,
          key: 'COUNT_ABSOLUT',
          isOnLegend: true,
          id: `${property.label}Nombre de jour`,
        });
      }
      result.data = data;
    }
  }
  if (calc === 'SUM_ABSOLUT_DATES') {
    array = array?.map((a) => {
      const obj = { ...a };
      const date = a.date && new Date(a.date);
      if (date && isValidDate(date)) {
        obj.date = date;
        if (config.dateFormat === 'date' || !config.dateFormat) {
          obj.label = format(date, 'dd/MM/yyyy');
        }
        if (config.dateFormat === 'month') {
          obj.label = new Intl.DateTimeFormat(lang, { month: 'long', year: 'numeric' }).format(date);
        }
        if (config.dateFormat === 'week') {
          const startDate = new Date(date.getFullYear(), 0, 1);
          const days = Math.floor((date - startDate) / (24 * 60 * 60 * 1000));
          const weekNumber = Math.ceil(days / 7);
          obj.label = `${translateDate[lang].week} ${weekNumber} ${date.getFullYear()}`;
        }
        if (config.dateFormat === 'semester') {
          const semester = date.getMonth() < 7 ? 1 : 2;
          obj.label = `${translateDate[lang].semester} ${semester} ${date.getFullYear()}`;
        }
        if (config.dateFormat === 'year') {
          obj.label = date.getFullYear();
        }
      }
      return obj;
    }).sort(
      (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime(),
    );
    array.forEach((a) => {
      const index = data.findIndex((d) => d.id === a.label);
      if (index === -1) {
        data.push({
          name: a.label,
          value: a[property.value],
          key: calc,
          date: a?.date || null,
          color: defaultColor,
          isOnLegend: true,
          id: a.label,
        });
      } else if (index >= 0) {
        data[index].value += a[property.value];
      }
    });
    result.data = data;
    if (graph === 'pie' || graph === 'bubbles') {
      const max = d3.max(data.map((d) => d.value));
      const min = d3.min(data.map((d) => d.value));
      result.data = data
        .sort((a, b) => (b.value - a.value))
        .map((d) => ({ ...d, color: getPrevColor(d.id) || colorScale(d.value, max, min) }));
    }
    if (graph === 'histogram' || graph === 'lines') {
      if (graph === 'histogram') {
        result.data = result.data.sort((a, b) => b.value - a.value);
      }
      if (graph === 'lines') {
        result.data = result.data.reverse();
      }
      if (config.ascending !== null) {
        result.data = result.data.sort((a, b) => (b.value < a.value ? 1 : -1));
      }
      if (config.ascending) {
        result.data = result.data.reverse();
      }
      if (config.ascending === null) {
        result.data = result.data.sort((a, b) => (b.date < a.date ? 1 : -1));
        if (config.ascendingLabel) {
          result.data = result.data.reverse();
        }
      }
    }
  }
  if (calc === 'RELATIVES_DATES') {
    const currentLegend = config?.legend.stacked;
    const stackedLegend = [];

    array = array?.map((a) => {
      const obj = { ...a };
      const date = a.date && new Date(a.date);
      if (date && isValidDate(date)) {
        obj.date = date;
        if (config.dateFormat === 'date' || !config.dateFormat) {
          obj.label = format(date, 'dd/MM/yyyy');
        }
        if (config.dateFormat === 'month') {
          obj.label = new Intl.DateTimeFormat(lang, { month: 'long', year: 'numeric' }).format(date);
        }
        if (config.dateFormat === 'week') {
          const startDate = new Date(date.getFullYear(), 0, 1);
          const days = Math.floor((date - startDate) / (24 * 60 * 60 * 1000));
          const weekNumber = Math.ceil(days / 7);
          obj.label = `${translateDate[lang].week} ${weekNumber} ${date.getFullYear()}`;
        }
        if (config.dateFormat === 'semester') {
          const semester = date.getMonth() < 7 ? 1 : 2;
          obj.label = `${translateDate[lang].semester} ${semester} ${date.getFullYear()}`;
        }
        if (config.dateFormat === 'year') {
          obj.label = date.getFullYear();
        }
      }
      return obj;
    }).sort(
      (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime(),
    );
    array.forEach((a) => {
      const splited = property.value.split('.');
      const propertyArray = splited[0];
      const key = splited[1];
      const label = splited.length > 2 ? splited[2] : null;
      const relativeItem = {
        name: a.label,
        count: 0,
      };
      a[propertyArray].forEach((item) => {
        const value = item?.results || item[key];
        const keyValue = label ? item[label] : item[key];
        if (keyValue && value) {
          if (!relativeItem[keyValue]) {
            relativeItem[keyValue] = value;
            const foundLegendIndex = stackedLegend.findIndex(
              (s) => s.dataKey === keyValue,
            );
            if (foundLegendIndex !== -1) {
              stackedLegend[foundLegendIndex].count += value;
            } else {
              const legend = {
                dataKey: keyValue,
                name: keyValue,
                count: value,
                isOnLegend: true,
                color: currentLegend?.find((c) => c.name === keyValue)?.color,
              };
              stackedLegend.push(legend);
            }
          }
        }
      });
      const dataIndex = result.data.findIndex((da) => da.name === relativeItem?.name);
      const entries = relativeItem && Object.keys(relativeItem).filter((k) => k !== 'name' && k !== 'count' && k !== 'date');
      if (dataIndex !== -1) {
        for (const prop of entries) {
          const value = result.data[dataIndex][prop];
          if (value > 0 && Object.hasOwnProperty.call(relativeItem, prop)) {
            result.data[dataIndex][prop] = value + relativeItem[prop];
          } else {
            result.data[dataIndex][prop] = relativeItem[prop];
          }
        }
      } else if (relativeItem?.name) {
        result.data.push(relativeItem);
      }
    });
    result.stacked = stackedLegend.sort((a, b) => (b.count - a.count))
      .map((d, i) => ({
        ...d,
        color: d.color || colorScale(i, 0, stackedLegend.length),
      }));

    result.data = result.data.map((d) => {
      const item = { ...d };
      const entries = Object.keys(d).filter((k) => k !== 'name' && k !== 'count');
      for (const key of entries) {
        if (d[key] > 0) {
          item.count += d[key];
        }
      }
      return item;
    });

    if (config.ascending) {
      result.data = result.data.reverse();
    }
  }
  if (calc === 'COUNT_ABSOLUT') {
    let count = array.filter((a) => {
      if (Object.keys(a).find((k) => k === property.value)) {
        return a;
      }
      return null;
    }).length;
    if (property.type === 'OrderedMultipleChoiceList' || property.type === 'MultipleChoiceList') {
      count = array.filter((a) => (!!(a[property.value] && a[property.value].length > 0))).length;
    }
    if (graph === 'key_number') {
      data.push({
        name: property.label,
        value: count,
        color: defaultColor,
        key: 'COUNT_ABSOLUT',
        isOnLegend: true,
        id: property.label,
      });
      result.data = data;
    }
  }
  if (calc === 'COUNT_NOT_INCLUDE') {
    let count = array.filter((a) => !Object.keys(a).find((k) => k === property.value)).length;
    if (property.type === 'OrderedMultipleChoiceList' || property.type === 'MultipleChoiceList') {
      count = array.filter((a) => (!a[property.value]
        || (a[property.value] && a[property.value].length === 0))).length;
    }
    if (graph === 'key_number') {
      data.push({
        name: property.label,
        value: count,
        color: defaultColor,
        key: 'COUNT_NOT_INCLUDE',
        isOnLegend: true,
        id: property.label,
      });
      result.data = data;
    }
  }
  if (calc === 'COUNT_SEPARE_LIST' || calc === 'COUNT_ABSOLUT_LIST') {
    let total = 0;
    array.forEach((a, i) => {
      const sort = i;
      let unit = null;
      const label = a[property?.value];
      if (calc === 'COUNT_SEPARE_LIST') {
        unit = '%';
        if (!property?.value) return;
      }
      if (Array.isArray(a[property.value])) {
        total += a[property.value].length;
      } else {
        total += 1;
      }
      if (!label) return;
      if (property?.type === 'OrderedMultipleChoiceList' || property?.type === 'RevertedFromElement' || property.type === 'LinkedChoice' || property.type === 'UniqueEntityLink' || property?.type === 'RevertedFromEntity' || property?.type === 'MultipleEntityLink' || property?.type === 'MultipleChoiceList' || property?.type === 'UniqueChoiceList') {
        let cumulLabel = a[property.value]?.libelle
      || a[property.value]?.label
      || a[property.value]?.CC_VALEUR
      || a[property.value]?.core_hasListName
      || a[property.value]?.title
      || a[property.value]?.name;

        let lists = Array.isArray(a[property.value])
          && a[property.value].length > 0
          && a[property.value].filter((l) => l).map(
            (l) => l?.libelle
            || l.name
            || l.title
            || l.core_hasListName
            || labels?.find((d) => d.element_id === l.element_id)?.label
            || array?.find((d) => d.entity_id === a[property.value])?.Nom
            || entitiesData?.find((e) => e.entity_id === l.element_id)?.label
            || entitiesData?.find((e) => e.entity_id === l.element_id)?.Nom
            || initData.find((e) => e.element_id === l.element_id)?.label
            || initData.find((d) => d.entity_id === l.element_id)?.Nom
            || l.label,
          );

        if (property.type === 'UniqueEntityLink') {
          cumulLabel = array?.find((d) => d.entity_id === a[property.value])?.Nom
            || entitiesData.find((e) => e.entity_id === a[property.value])?.label;
          lists = [cumulLabel];
        }
        if (property.type === 'UniqueChoiceList' || property.type === 'LinkedChoice') {
          lists = [cumulLabel];
        }
        lists?.forEach((t) => {
          const index = data.findIndex((d) => d.id === t);
          if (index === -1) {
            data.push({
              name: t,
              value: 1,
              key: calc,
              isOnLegend: true,
              id: t,
              unit,
              sort: 1,
            });
          } else if (index >= 0) {
            data[index].value += 1;
            data[index].sort += 1;
          }
        });
      } else if (label) {
        const index = data.findIndex((d) => d.id === label);
        if (index === -1) {
          data.push({
            name: label,
            value: 1,
            key: calc,
            date: a?.date || null,
            isOnLegend: true,
            id: label,
            unit,
            sort,
          });
        } else if (index >= 0) {
          data[index].value += 1;
        }
      }
    });

    if (data.length && data[0].unit === '%') {
      data = data.map((d) => ({
        ...d, value: getPercantage(d.value, total),
      }));
    }

    if (graph === 'pie' || graph === 'bubbles') {
      const max = d3.max(data.map((d) => d.value));
      const min = d3.min(data.map((d) => d.value));
      result.data = data
        .sort((a, b) => (b.value - a.value))
        .map((d) => ({ ...d, color: getPrevColor(d.id) || colorScale(d.value, max, min) }));
    }
    if (graph === 'word_cloud') {
      result.data = data.map((d) => ({
        ...d,
        color: defaultColor,
      })).sort((a, b) => a.value - b.value);
    }
    if (graph === 'treemap') {
      result.data = data.map((d) => ({
        ...d,
        color: defaultColor,
      })).sort((a, b) => a.value - b.value);
    }

    if (graph === 'histogram' || graph === 'lines') {
      if (property?.type === 'Float' || property?.type === 'Numeric') {
        result.data = data.map((d) => ({
          ...d,
          color: getPrevColor(d.id) || defaultColor,
        })).sort((a, b) => a.name - b.name);
        if (graph === 'histogram') {
          result.data = result.data.sort((a, b) => b.value - a.value);
        }
        if (graph === 'lines') {
          result.data = result.data.sort(
            (a, b) => parseFloat(a.name) - parseFloat(b.name),
          ).reverse();
        }
        if (config.ascending) {
          result.data = result.data.reverse();
        }
        if (config.ascending === null) {
          result.data = result.data.sort((a, b) => a.name.localeCompare(b.name));
          console.log(result.data);
          if (config.ascendingLabel) {
            result.data = result.data.reverse();
          }
        }
      } else {
        result.data = data.map((d) => ({
          ...d,
          color: getPrevColor(d.id) || defaultColor,
        })).sort((a, b) => a.value - b.value);
        if (graph === 'lines') {
          result.data = result.data.sort((a, b) => b.value - a.value);
        }
        if (graph === 'histogram') {
          console.log(data);
          result.data = result.data.sort((a, b) => b.value - a.value);
        }
        if (config.ascending) {
          result.data = result.data.reverse();
        }
        if (config.ascending === null) {
          if (property?.type === 'Date') {
            result.data = result.data.sort(
              (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime(),
            );
          } else {
            result.data = result.data.sort((a, b) => a.name.localeCompare(b.name));
          }
          if (config.ascendingLabel) {
            result.data = result.data.reverse();
          }
        }
      }
    }
  }
  if (calc === 'COUNT_RELATIVE') {
    const relative = config?.params?.relative?.value?.value;
    const relativeFilters = config?.params?.relative.filters;
    const currentLegend = config?.legend.stacked;
    const stackedLegend = [];
    const relativeType = config?.params?.relative?.value?.type;

    if (relativeType === 'Date') {
      if (config?.params?.relative.dateFormat) {
        array = array.sort(
          (a, b) => new Date(a[relative]).getTime() - new Date(b[relative]).getTime(),
        ).map((d) => {
          const date = new Date(d[relative]);
          let dateLabel = null;
          if (config?.params?.relative.dateFormat === 'date') {
            dateLabel = format(date, 'dd/MM/yyyy');
          }
          if (config?.params?.relative.dateFormat === 'month') {
            dateLabel = new Intl.DateTimeFormat(lang, { month: 'long', year: 'numeric' }).format(date);
          }
          if (config?.params?.relative.dateFormat === 'week') {
            const startDate = new Date(date.getFullYear(), 0, 1);
            const days = Math.floor((date - startDate) / (24 * 60 * 60 * 1000));
            const weekNumber = Math.ceil(days / 7);
            dateLabel = `${translateDate[lang].week} ${weekNumber} ${date.getFullYear()}`;
          }
          if (config?.params?.relative.dateFormat === 'semester') {
            const semester = date.getMonth() < 7 ? 1 : 2;
            dateLabel = `${translateDate[lang].semester} ${semester} ${date.getFullYear()}`;
          }
          if (config?.params?.relative.dateFormat === 'year') {
            dateLabel = `${date.getFullYear()}`;
          }
          return { ...d, dateLabel, date };
        });
      }
    }
    array.forEach((item) => {
      let date;
      let name = item[relative]?.label
      || item[relative]?.CC_VALEUR
      || item[relative]?.core_hasListName
      || item[relative]?.name
      || item[relative]?.title;
      if (relativeType === 'Numeric' || relativeType === 'Float' || relativeType === 'Textarea' || relativeType === 'NonLocalizedText') {
        name = item[relative];
      }
      if (Array.isArray(item[relative])) {
        const propertyName = item[property.value]?.name
              || item[property.value]?.label
              || item[property.value]?.title
              || item[property.value]?.core_hasListName
              || item[property.value]?.CC_VALEUR || item[property.value];

        if (!Array.isArray(propertyName)) {
          const count = 0;
          let relativeItem = {
            name: propertyName,
            count,
          };
          item[relative].forEach((re) => {
            const prop = re?.name
            || re?.label
            || re?.title
            || re?.core_hasListName
            || re?.CC_VALEUR || re;
            if (!Array.isArray(prop)) {
              if (!relativeItem[prop]) {
                relativeItem[prop] = 1;
                if (relativeType !== 'Numeric' && relativeType !== 'Float') {
                  relativeItem.count = 1;
                }
              } else {
                relativeItem[prop] += 1;
                if (relativeType !== 'Numeric' && relativeType !== 'Float') {
                  relativeItem.count += 1;
                }
              }
              const foundLegendIndex = stackedLegend.findIndex(
                (s) => s.dataKey === prop,
              );
              if (foundLegendIndex !== -1) {
                if (relativeType !== 'Numeric' && relativeType !== 'Float') {
                  stackedLegend[foundLegendIndex].count += 1;
                }
              } else {
                const legend = {
                  dataKey: prop,
                  name: prop,
                  count: 1,
                  isOnLegend: true,
                  color: currentLegend?.find((c) => c.name === prop)?.color,
                };
                stackedLegend.push(legend);
              }
            }
          });
          if (property?.type !== 'Boolean' && relativeFilters?.length > 0
            && !relativeFilters.find((r) => r.value === relativeItem?.name)) {
            relativeItem = null;
          }
          if (property?.type === 'Boolean' && relativeFilters?.length > 0
          && !relativeFilters.find((r) => r.label === propertyName)) {
            relativeItem = null;
          }

          const dataIndex = result.data.findIndex((da) => da.name === relativeItem?.name);
          const entries = relativeItem && Object.keys(relativeItem).filter((k) => k !== 'name' && k !== 'count' && k !== 'date');
          if (dataIndex !== -1) {
            for (const key of entries) {
              const value = result.data[dataIndex][key];
              if (value > 0 && Object.hasOwnProperty.call(relativeItem, key)) {
                result.data[dataIndex][key] = value + 1;
              } else {
                result.data[dataIndex][key] = 1;
              }
            }
          } else if (relativeItem?.name) {
            result.data.push(relativeItem);
          }
        } else {
          item[property.value].forEach((p) => {
            const label = p?.name
              || p?.title
              || p?.core_hasListName
              || p?.CC_VALEUR
              || array?.find((d) => d.entity_id === p.element_id)?.Nom
              || p?.label;
            const count = 0;
            let relativeItem = {
              name: label,
              count,
            };
            item[relative].forEach((re) => {
              const prop = re?.name
              || re?.label
              || re?.title
              || re?.core_hasListName
              || re?.CC_VALEUR || re;
              if (!Array.isArray(prop)) {
                if (!relativeItem[prop]) {
                  relativeItem[prop] = 1;
                  if (relativeType !== 'Numeric' && relativeType !== 'Float') {
                    relativeItem.count = 1;
                  }
                } else {
                  relativeItem[prop] += 1;
                  if (relativeType !== 'Numeric' && relativeType !== 'Float') {
                    relativeItem.count += 1;
                  }
                }
                const foundLegendIndex = stackedLegend.findIndex(
                  (s) => s.dataKey === prop,
                );
                if (foundLegendIndex !== -1) {
                  if (relativeType !== 'Numeric' && relativeType !== 'Float') {
                    stackedLegend[foundLegendIndex].count += 1;
                  }
                } else {
                  const legend = {
                    dataKey: prop,
                    name: prop,
                    count: 1,
                    isOnLegend: true,
                    color: currentLegend?.find((c) => c.name === prop)?.color,
                  };
                  stackedLegend.push(legend);
                }
              }
            });
            if (relativeFilters?.length > 0
              && !relativeFilters.find((r) => r.value === relativeItem?.name)) {
              relativeItem = null;
            }
            const dataIndex = result.data.findIndex((da) => da.name === relativeItem?.name);
            const entries = relativeItem && Object.keys(relativeItem).filter((k) => k !== 'name' && k !== 'count' && k !== 'date');
            if (dataIndex !== -1) {
              for (const key of entries) {
                const value = result.data[dataIndex][key];
                if (value > 0 && Object.hasOwnProperty.call(relativeItem, key)) {
                  result.data[dataIndex][key] = value + 1;
                } else {
                  result.data[dataIndex][key] = 1;
                }
              }
            } else if (relativeItem?.name) {
              relativeItem.count = 0;
              result.data.push(relativeItem);
            }
          });
        }
      }
      if (relativeType === 'Date') {
        const dateValue = item[relative] && new Date(item[relative]);
        if (dateValue && isValidDate(dateValue)) {
          name = item.dateLabel;
          date = dateValue;
        }
      }

      if (name && !result.data.find((d) => d.name === name)) {
        const count = 0;
        let relativeItem = {
          name,
          count,
          date,
        };
        array.filter(
          (a) => a[relative]?.name === name
          || a[relative]?.core_hasListName === name
          || a[relative]?.CC_VALEUR === name
          || a[relative]?.label === name
          || a.dateLabel === name
          || a[relative]?.title === name
          || (relativeType === 'Date' && a[relative] && new Date(a[relative]) && isValidDate(new Date(a[relative])) && format(new Date(a[relative]), 'dd/MM/yyyy') === name)
          || a[relative] === name,
        ).forEach((d) => {
          if (Array.isArray(d[property.value])) {
            d[property.value].filter((p) => {
              const propertyName = p.name
              || p.label || p.title || p.core_hasListName || p.CC_VALEUR;
              if (relativeFilters?.length > 0
                && !relativeFilters?.find((f) => f.value === propertyName)) {
                return null;
              }
              return p;
            }).forEach((p) => {
              const propertyName = p.name
              || p.label || p.title || p.core_hasListName || p.CC_VALEUR;
              if (propertyName) {
                if (!relativeItem[propertyName]) {
                  relativeItem[propertyName] = 1;
                  if (relativeType !== 'Numeric' && relativeType !== 'Float') {
                    relativeItem.count = 1;
                  }
                } else {
                  relativeItem[propertyName] += 1;
                  if (relativeType !== 'Numeric' && relativeType !== 'Float') {
                    relativeItem.count += 1;
                  }
                }
                const foundLegendIndex = stackedLegend.findIndex(
                  (s) => s.dataKey === propertyName,
                );
                if (foundLegendIndex !== -1) {
                  if (relativeType !== 'Numeric' && relativeType !== 'Float') {
                    stackedLegend[foundLegendIndex].count += 1;
                  }
                } else {
                  const legend = {
                    dataKey: propertyName,
                    name: propertyName,
                    count: 1,
                    isOnLegend: true,
                    color: currentLegend?.find((c) => c.name === propertyName)?.color,
                  };
                  stackedLegend.push(legend);
                }
              }
            });
          } else if (d[property.value]) {
            const propertyName = d[property.value]?.name
              || d[property.value]?.label
              || d[property.value]?.title
              || d[property.value]?.core_hasListName
              || d[property.value]?.CC_VALEUR || d[property.value];
            if (propertyName) {
              if (!relativeItem[propertyName]) {
                relativeItem[propertyName] = 1;
                if (relativeType !== 'Numeric' && relativeType !== 'Float') {
                  relativeItem.count = 1;
                }
              } else {
                relativeItem[propertyName] += 1;
                if (relativeType !== 'Numeric' && relativeType !== 'Float') {
                  relativeItem.count += 1;
                }
              }
              const foundLegendIndex = stackedLegend.findIndex(
                (s) => s.dataKey === propertyName,
              );
              if (foundLegendIndex !== -1) {
                if (relativeType !== 'Numeric' && relativeType !== 'Float') {
                  stackedLegend[foundLegendIndex].count += 1;
                }
              } else {
                const legend = {
                  dataKey: propertyName,
                  name: propertyName,
                  count: 1,
                  isOnLegend: true,
                  color: currentLegend?.find((c) => c.name === propertyName)?.color,
                };
                stackedLegend.push(legend);
              }
            }
          }
        });
        if (property?.type === 'Boolean' && relativeFilters?.length > 0
          && !relativeFilters.find((r) => r.value === relativeItem.name)) {
          relativeItem = null;
        }

        if (property?.type !== 'Boolean' && relativeFilters?.length > 0) {
          // eslint-disable-next-line no-restricted-syntax
          for (const prop in relativeItem) {
            if (prop !== 'name'
            && prop !== 'count' && !relativeFilters.find((r) => r.value.toString() === prop)) {
              delete relativeItem[prop];
            }
          }
        }
        if (relativeItem) {
          result.data.push(relativeItem);
        }
      }
      if (relativeType === 'Boolean' && Array.isArray(item[property.value])) {
        item[property.value].forEach((p) => {
          const label = p.name
          || p.label || p.title || p.core_hasListName || p.CC_VALEUR;
          const relativeItem = {
            name: label,
            count: 1,
            Oui: item[relative] === 'true' ? 1 : 0,
            Non: item[relative] !== 'true' ? 1 : 0,
          };
          const indexFound = result.data.findIndex((d) => d.name === label);
          if (indexFound === -1) {
            result.data.push(relativeItem);
          } else if (item[relative] === 'true') {
            result.data[indexFound].Oui += 1;
          } else if (item[relative] !== 'true') {
            result.data[indexFound].Non += 1;
          }
          const dataKey = item[relative] === 'true' ? 'Oui' : 'Non';
          const foundLegendIndex = stackedLegend.findIndex(
            (s) => s.dataKey === dataKey,
          );
          if (foundLegendIndex !== -1) {
            if (relativeType !== 'Numeric' && relativeType !== 'Float') {
              stackedLegend[foundLegendIndex].count += 1;
            }
          } else {
            const legend = {
              dataKey,
              name: dataKey,
              count: 1,
              isOnLegend: true,
              color: currentLegend?.find((c) => c.name === dataKey)?.color,
            };
            stackedLegend.push(legend);
          }
        });
      }
    });

    result.data = result.data.map((d) => {
      const item = { ...d };
      const entries = Object.keys(d).filter((k) => k !== 'name' && k !== 'count' && k !== 'date');
      for (const key of entries) {
        if (d[key] > 0) {
          item.count += d[key];
        }
      }
      return item;
    }).sort((a, b) => (a.count - b.count));

    result.stacked = stackedLegend.sort((a, b) => (b.count - a.count))
      .map((d, i) => ({
        ...d,
        color: d.color || colorScale(i, 0, stackedLegend.length),
      }));

    if (relativeType === 'Boolean' && property?.type === 'Boolean') {
      const relativeLabel = config?.params?.relative?.value?.label;
      result.data = result.data.map((d) => {
        const item = { ...d };
        if (d.name === 'true' || d.name === 'false') {
          item.name = `${property.label} : ${d.name === 'true' ? 'Oui' : 'Non'}`;
          item[`${relativeLabel} : Oui`] = item.Oui;
          item[`${relativeLabel} : Non`] = item.Non;
        }
        return item;
      });
      result.stacked = result.stacked.map((s) => {
        const item = { ...s };
        if (s.dataKey === 'Oui' || s.dataKey === 'true') {
          item.dataKey = `${relativeLabel} : Oui`;
          item.name = `${relativeLabel} : Oui`;
        }
        if (s.dataKey === 'Non' || s.dataKey === 'false') {
          item.dataKey = `${relativeLabel} : Non`;
          item.name = `${relativeLabel} : Non`;
        }
        return item;
      });
    } else if (property?.type === 'Boolean') {
      const { label } = property;
      result.data = result.data.map((d) => {
        const item = { ...d };
        if (item) {
          item[`${label} : Oui`] = item.Oui;
          item[`${label} : Non`] = item.Non;
        }
        return item;
      });
      result.stacked = result.stacked.map((s) => {
        const item = { ...s };
        if (s.dataKey === 'Oui' || s.dataKey === 'true') {
          item.dataKey = `${label} : Oui`;
          item.name = `${label} : Oui`;
        }
        if (s.dataKey === 'Non' || s.dataKey === 'false') {
          item.dataKey = `${label} : Non`;
          item.name = `${label} : Non`;
        }
        return item;
      });
    } else if (relativeType === 'Boolean') {
      result.data = result.data.map((d) => {
        const item = { ...d };
        if (d.name === 'true' || d.name === 'false') {
          item.name = `${config?.params?.relative?.value?.label} : ${d.name === 'true' ? 'Oui' : 'Non'}`;
        }
        return item;
      });
      result.stacked = result.stacked.map((s) => {
        const item = { ...s };
        return item;
      });
    }
    if (config.ascending !== null) {
      result.data = result.data.sort((a, b) => (b.count < a.count ? 1 : -1));
    }
    if (config.ascending) {
      result.data = result.data.reverse();
    }
    if (config.ascending === null) {
      result.data = result.data.sort((a, b) => a.name.localeCompare(b.name));
      if (relativeType === 'Date') {
        result.data = result.data.sort((a, b) => (b.date < a.date ? 1 : -1));
      }
      if (config.ascendingLabel) {
        result.data = result.data.reverse();
      }
    }
  }
  if (property.type === 'TripleState') {
    result.data = result.data.map((d) => {
      const da = { ...d };
      if (d.name === 'Yes') {
        da.name = 'Oui';
      }
      if (d.name === 'No') {
        da.name = 'Non';
      }
      if (d.name === 'None') {
        da.name = 'Aucun';
        da.color = '#999999';
      }
      return da;
    });
  }
  // APPLY FILTERS ON LIST
  if (Array.isArray(filters)) {
    const filtered = filters?.find((f) => f.property.value === property.value)?.list;
    if (calc === 'COUNT_SEPARE_LIST' || calc === 'COUNT_ABSOLUT_LIST') {
      return result;
    }
    if (filtered && calc === 'COUNT_RELATIVE') {
      result.data = result.data.map((d) => {
        const item = { ...d };
        const keys = Object.keys(d);
        keys.forEach((k) => {
          const foundKey = filtered.find((f) => k === f.value);
          if (!foundKey?.value && k !== 'name') {
            delete item[k];
          }
        });
        return item;
      });
    }
  }
  return result;
};

export default generateEntitiesData;
