import React, { useEffect } from 'react';
import {
  NavLink,
  Outlet,
  useParams,
  useNavigate,
  useLocation,
} from 'react-router-dom';
import { BsArrowLeft, BsCheck } from 'react-icons/bs';
import { VscSave } from 'react-icons/vsc';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';

import { useTranslation } from 'react-i18next';
import { getDashboardById, updateDashboard } from '../../actions/dashboards';

import styles from './edit-dashboard.module.scss';
import { DASHBOARD_POST, DASHBOARD_PUT } from '../../actions/types';
import Loader from '../../components/Loader';

const EditDashboard = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { search, pathname } = useLocation();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { dashboard, isLoading, user } = useSelector((store) => store.dashboardReducer);
  const {
    control,
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { errors, isDirty },
  } = useForm();
  const data = watch();

  useEffect(() => {
    if (!params.dashboardId) return;
    getDashboardById(dispatch, params.dashboardId);
  }, []);

  function submit() {
    updateDashboard(dispatch, data, params.dashboardId);
  }
  // const api = watch('api');
  // const dataset = watch('dataset');
  useEffect(() => {
    if (dashboard && user) {
      const dashboardData = {
        ...dashboard,
        api: user.apis.find((a) => a.value === dashboard.requests),
        dataset: user.keys.find((a) => a.value === dashboard.key),
      };
      reset(dashboardData);
    }
  }, [dashboard, user]);

  return (
    <div className={styles.editDashboard}>
      <div className={styles.container}>
        <div className={styles.header}>
          <p className={styles.backward} onClick={() => navigate(`/dashboard-list/${search}`)}><BsArrowLeft /> {t('btn.back')}</p>
          <h1>{dashboard?.name}</h1>
          <div className={styles.containerButton}>
            <button
              className='secondary'
              onClick={() => navigate(`/preview-dashboard/${params.dashboardId}/${search}`)}
            >{t('dashboard.preview')}
            </button>
            <button
              className={`${!isDirty ? styles.saved : ''}`}
              onClick={handleSubmit(submit)}
            >
              <span>{isDirty ? t('btn.save') : t('btn.saved')}</span>
              {(isLoading.includes(DASHBOARD_POST) || isLoading.includes(DASHBOARD_PUT)) ? (
                <span className='icon loader'>
                  <Loader size={18} className='secondary' />
                </span>
              ) : (
                <span className='icon'>
                  {!isDirty ? <BsCheck size={20}/> : <VscSave /> }
                </span>
              )}
            </button>
          </div>
        </div>
        <div className={styles.content}>
          <nav>
            <NavLink to={`indicators${search}`} className={({ isActive }) => (isActive ? styles.active : undefined)} >{t('dashboard.choose')}</NavLink>
            <NavLink to={`layout${search}`} className={({ isActive }) => (isActive ? styles.active : undefined)} >{t('dashboard.layout')}</NavLink>
            {dashboard?.state
              ? <NavLink to={`embed/share${search}`} className={({ isActive }) => (isActive || pathname.includes('embed') ? styles.active : undefined)} >{t('dashboard.integration')}</NavLink>
              : <a className={styles.disabled}>{t('dashboard.integration')}</a>
            }
          </nav>
          <div className={styles.tabs}>
            <Outlet context={{
              control, errors, handleSubmit, watch, setValue,
            }} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditDashboard;
