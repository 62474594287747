import React, { useEffect } from 'react';

import styles from './files.module.scss';

const Files = () => {
  useEffect(() => {}, []);
  return (
    <div className={styles.share}>
      <h1>Fichiers</h1>
    </div>
  );
};

export default Files;
