import React from 'react';
import {
  Treemap, Tooltip, ResponsiveContainer,
} from 'recharts';
import CustomTooltip from '../CustomTooltip/CustomTooltip';

export default function CustomTreemap({ data }) {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <Treemap
        dataKey="value"
        data={[{
          name: 'data',
          children: data.map((d) => ({ ...d, fill: d.color })),
        }]}
        ratio={4 / 3}
        stroke="#fff"
        >
        <Tooltip content={<CustomTooltip />} />
      </Treemap>
    </ResponsiveContainer>
  );
}
