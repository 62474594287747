/* eslint-disable no-return-assign */
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useOutletContext } from 'react-router-dom';
import { INDICATOR_CHANGE } from '../../../../actions/types';
import LegendRow from '../../../../components/LegendRow';
import SelectColoredIcon from '../../../../components/SelectColoredIcon';
import { InputColor, InputText, ListTags } from '../../../../lib/HooksFormFields';
import { defaultColor, mostFrequentInArray } from '../../../../utils/utils';

import styles from './Legend.module.scss';

const positions = ['TL', 'TC', 'TR', 'LC', 'RC', 'BL', 'BC', 'BR'];

const Legend = () => {
  const {
    watch, setValue, control, changedAt,
  } = useOutletContext();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const data = watch('data');
  const legend = watch('config.legend');
  const stacked = watch('config.legend.stacked');

  const itemsRef = useRef([]);

  const handleChangeItem = (value, index) => {
    const items = stacked ? [...stacked] : [...data];
    items[index] = value;
    if (stacked) {
      setValue('config.legend.stacked', [...items]);
    } else {
      setValue('data', [...items]);
    }
    dispatch({
      type: INDICATOR_CHANGE,
    });
  };

  useEffect(() => {
    if (legend?.color && data?.length > 0) {
      const items = data.map((d) => {
        const item = { ...d };
        const sameColor = mostFrequentInArray(data.map((da) => da.color));
        if (item.color === defaultColor
          || sameColor === item.color) {
          item.color = legend.color;
        }
        return item;
      });
      setValue('data', [...items]);
      dispatch({
        type: INDICATOR_CHANGE,
      });
    }
  }, [legend?.color]);

  return (
    <div className={styles.container}>
      <div className={`${styles.legend} ${styles.border}`}>
        <div className={styles.col}>
          <ListTags
            name="config.legend.isActive"
            control={control}
            label={t('indicator.legend.display.toggle')}
            tags={[
              { label: t('options.yes'), value: true },
              { label: t('options.no'), value: false },
            ]}
          />
        </div>
        <div className={styles.col}>
          <ListTags
            name="config.legend.display"
            control={control}
            label="Type d'affichage"
            tags={[
              { label: t('indicator.legend.display.hover'), value: 'hover' },
              { label: t('indicator.legend.display.click'), value: 'click' },
              { label: t('indicator.legend.display.always'), value: 'already' },
            ]}
          />
        </div>
        <div className={styles.color}>
          <label>{t('indicator.legend.icon')}</label>
          {watch('config.legend') && <SelectColoredIcon name='config.legend.icon' control={control} />}
        </div>
      </div>
      {(watch('graph') === 'pie' || watch('graph') === 'histogram') && watch('config.params.value')?.value !== 'COUNT_RELATIVE'
        && <div className={`${styles.legend} ${styles.border}`}>
          {watch('graph') === 'pie'
            && <div className={styles.col} style={{ width: '50%' }}>
                <ListTags
                  name="config.legend.isLabelOnGraph"
                  control={control}
                  label={t('indicator.legend.display.label')}
                  tags={[
                    { label: t('options.yes'), value: true },
                    { label: t('options.no'), value: false },
                  ]}
                />
              </div>
          }
          {(watch('graph') === 'pie' || watch('graph') === 'histogram') && watch('config.params.value')?.value !== 'COUNT_RELATIVE'
            && <div className={styles.col} style={{ width: '50%' }}>
              <ListTags
                name="config.legend.isValueOnLegend"
                control={control}
                label={t('indicator.legend.display.value')}
                tags={[
                  { label: t('options.yes'), value: true },
                  { label: t('options.no'), value: false },
                ]}
              />
            </div>
          }
        </div>
      }

      <div className={styles.border}>
        <InputText
          name="config.legend.title"
          control={control}
          label={t('indicator.legend.title')}
          placeholder={t('indicator.legend.placeholder')}
        />
        <div className={styles.positions}>
          <label>{t('indicator.legend.position')}</label>
          <div className={styles.options}>
            {positions.map((pos) => <button
              key={pos}
              onClick={() => setValue('config.legend.titlePosition', pos)}
              className={`${styles[pos] ? styles[pos] : ''} ${watch('config.legend.titlePosition') === pos ? styles.active : ''}`}
              >
              {t(`indicator.legend.positions.${pos}`)}
            </button>)}
          </div>
        </div>
      </div>

      {(watch('graph') === 'lines')
        && <div className={`${styles.legend} ${styles.border}`}>
          <div className={styles.col} style={{ width: '50%' }}>
            <InputColor
              name="config.legend.color"
              control={control}
              label={t('indicator.legend.lineColor')}
            />
          </div>
        </div>
      }

      <div className={styles.list}>
        <div className={`${styles.row} ${styles.label}`}>
          <div className={styles.col}>
            <p>{t('indicator.legend.name')}</p>
          </div>
          <div className={styles.col}>
            <p>{t('indicator.legend.color')}</p>
          </div>
          <div className={styles.col}>
            <p>{t('indicator.legend.active')}</p>
          </div>
        </div>
        {!stacked && data?.map((item, i) => <LegendRow
          key={i + changedAt}
          handleChange={(value) => handleChangeItem(value, i)}
          item={item}
          ref={itemsRef.current[i]}
          styles={styles}
        />)}
        {stacked && stacked?.map((item, i) => <LegendRow
          key={i + changedAt}
          handleChange={(value) => handleChangeItem(value, i)}
          item={item}
          ref={itemsRef.current[i]}
          styles={styles}
        />)}
      </div>
    </div>
  );
};

export default Legend;
