import { combineReducers, createStore } from 'redux';
import dashboardReducer from './dashboardReducer';
import indicatorReducer from './indicatorReducer';

const rootReducer = combineReducers({
  dashboardReducer,
  indicatorReducer,
});

const store = createStore(
  rootReducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
);

export default store;
