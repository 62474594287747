import React from 'react';
import { useTranslation } from 'react-i18next';
import editIcon from './edit.svg';
import deleteIcon from './delete.svg';

export default function FilterRow({
  styles,
  filter,
  deleteFilter,
  editFilter,
}) {
  const { t } = useTranslation();
  return (
    <div className={`${styles.row} ${styles.filter}`}>
      <div className={styles.col}>
        {filter?.property?.label}
      </div>
      <div className={styles.col}>
        {filter.name}
      </div>
      <div className={styles.col}>
        {filter?.list?.length > 1 && t('indicator.filters.list')}
        {filter?.list?.length === 1 && filter.list[0].label}
      </div>
      <div className={styles.col}>
        <button
         type='button'
         onClick={() => editFilter()}
        >
          <img src={editIcon} alt='edit' />
        </button>
        <button
         type='button'
         onClick={() => deleteFilter()}
        >
          <img src={deleteIcon} alt='edit' />
        </button>
      </div>
    </div>
  );
}
