import * as React from 'react';

const GeoIcon = ({ selected, ...rest }) => (
  <svg
    width={44}
    height={44}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M22 .333C10.053.333.333 10.053.333 22c0 11.947 9.72 21.667 21.667 21.667 11.947 0 21.666-9.72 21.666-21.667C43.666 10.053 33.946.333 22 .333ZM4.666 22c0-1.948.338-3.818.934-5.566l3.4 3.4 4.333 4.333V28.5l4.333 4.333L19.833 35v4.184C11.299 38.11 4.666 30.823 4.666 22Zm31.049 10.558c-1.415-1.14-3.56-1.891-5.049-1.891V28.5a4.333 4.333 0 0 0-4.333-4.333h-8.667v-6.5A4.334 4.334 0 0 0 22 13.333v-2.166h2.166A4.334 4.334 0 0 0 28.5 6.833v-.89C34.844 8.519 39.333 14.742 39.333 22c0 3.824-1.273 7.538-3.618 10.558Z"
      fill={selected ? '#FFFFFF' : '#6879C4' }
    />
  </svg>
);

export default GeoIcon;
