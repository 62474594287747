import React, { useEffect } from 'react';
import { BsArrowLeft } from 'react-icons/bs';
import {
  useWindowWidth,
} from '@react-hook/window-size';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getDashboardById } from '../../actions/dashboards';
import Indicator from '../../components/Graphs/Indicator';
import styles from './dashboard-view.module.scss';
import { API_URL } from '../../actions';

export default function DashboardView({ isPreview = false }) {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const width = useWindowWidth();
  const { search: searchQuery } = useLocation();
  const { dashboard, user } = useSelector((store) => store.dashboardReducer);

  const userIsAllowed = dashboard?.indicators.filter(
    (i) => user?.keys.find((k) => k.value === i.key),
  );

  const { t } = useTranslation();
  useEffect(() => {
    if (!params.dashboardId) return;
    getDashboardById(dispatch, params.dashboardId);
  }, []);

  useEffect(() => {
    if (!isPreview && dashboard?._id) {
      const hostname = (window.location !== window.parent.location)
        ? document.referrer
        : window.location.host;
      const defaultList = ['https://bdi.bakasable.io/', 'https://bdi.bakasable.fr/'];
      const allowList = dashboard?.allowList.split(',').map(((s) => s.replaceAll(' ', ''))) || [];
      console.log(hostname, [...allowList, ...defaultList]);
      const notAllowed = ![...allowList, ...defaultList]
        .find((url) => url.includes(hostname) || (hostname.includes(url)));
      if (notAllowed) {
        navigate('/error');
      }
    }
  }, [dashboard]);

  return (
    <div className={styles.dashboard}>
      <div className={styles.container}>
      {isPreview && userIsAllowed?.length === dashboard?.indicators?.length
        && <p className={styles.backward} onClick={() => navigate(`/edit-dashboard/${dashboard._id}/indicators${searchQuery}`)}><BsArrowLeft /> {t('btn.back')}</p>
      }
      {isPreview && userIsAllowed?.length !== dashboard?.indicators?.length
        && <p className={styles.backward} onClick={() => navigate(`/dashboard-list/${searchQuery}`)}><BsArrowLeft /> {t('btn.back')}</p>
      }
        <div className={styles.title}>
          {dashboard?.logo?.path
          && <div className={styles.logo}>
            <img src={`${API_URL}/file/public/${dashboard?.logo?.path}`} alt="logo"/>
          </div>
          }
          <h2>{dashboard?.name}</h2>
        </div>
        <div className={styles.indicators}>
          {dashboard?.indicators
            ?.filter((i) => i.isPublished)
            ?.sort(
              (a, b) => a.position - b.position,
            ).map((indicator) => <div key={indicator._id}
              className={styles[indicator.size]}
              >
              <div className={styles.block}>
              <Indicator key={indicator._id + width} indicator={indicator} />
              </div>
            </div>)}
        </div>
      </div>
    </div>
  );
}
