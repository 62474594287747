/* eslint-disable import/no-named-as-default */
/* eslint-disable no-restricted-syntax */
import * as d3 from 'd3';
import { format } from 'date-fns';
import { getAssociatedLabelsAction } from '../actions/indicators';
import filterData from './filterData';
// eslint-disable-next-line import/no-cycle
import {
  colorScale,
  defaultColor, getPercantage, isValidDate, translateDate,
} from './utils';

const generateAssociatedElementsData = async (
  options = {},
  config,
  graph,
  prevData = [],
) => {
  const initData = options?.data || [];
  const aggregation = options?.aggregations;
  let elements = [];

  // const elementsLabels = options?.elementsLabels;
  const filters = config?.filters;
  const lang = localStorage.getItem('lang') || 'fr';
  // APPLY FILTERS ON DATA
  let array = filterData([...initData], filters);
  const entitiesData = options?.entitiesData || [];
  const property = config?.params?.name;
  const parent = property?.parent;

  const relative = config?.params?.relative?.value;

  const calc = config?.params?.value?.value;
  const labels = [];
  if (process.env.NODE_ENV === 'development') {
    console.log('generateAssociatedElementsData', property, options);
  }
  const result = {
    data: [],
    options: {
      property,
    },
  };
  const associatedId = config.params?.name?.associatedId || config.params?.name?.associatedDomainId;
  if (associatedId && config?.associetedConfig) {
    const associatedConfig = {
      lang,
      associatedId,
      ...config.associetedConfig,
    };
    const res = await getAssociatedLabelsAction(associatedConfig);
    res.data.forEach((v) => {
      labels.push({
        element_id: v.element_id,
        label: v.label,
      });
    });
  }

  const relativeAssociatedId = config.params?.relative?.value?.associatedId
  || config.params?.relative?.value?.associatedDomainId;
  if (relativeAssociatedId && config?.associetedConfig) {
    const associatedConfig = {
      lang,
      associatedId: relativeAssociatedId,
      ...config.associetedConfig,
    };
    const res = await getAssociatedLabelsAction(associatedConfig);
    res.data.forEach((v) => {
      labels.push({
        element_id: v.element_id,
        label: v.label,
      });
    });
  }

  if (!relative?.parent && relative?.type === 'Date') {
    if (config?.params?.relative.dateFormat) {
      array = array.sort(
        (a, b) => new Date(a[relative.value]).getTime() - new Date(b[relative.value]).getTime(),
      ).map((d) => {
        const date = new Date(d[relative.value]);
        let dateLabel = null;
        if (config?.params?.relative.dateFormat === 'date') {
          dateLabel = format(date, 'dd/MM/yyyy');
        }
        if (config?.params?.relative.dateFormat === 'month') {
          dateLabel = new Intl.DateTimeFormat(lang, { month: 'long', year: 'numeric' }).format(date);
        }
        if (config?.params?.relative.dateFormat === 'week') {
          const startDate = new Date(date.getFullYear(), 0, 1);
          const days = Math.floor((date - startDate) / (24 * 60 * 60 * 1000));
          const weekNumber = Math.ceil(days / 7);
          dateLabel = `${translateDate[lang].week} ${weekNumber} ${date.getFullYear()}`;
        }
        if (config?.params?.relative.dateFormat === 'semester') {
          const semester = date.getMonth() < 7 ? 1 : 2;
          dateLabel = `${translateDate[lang].semester} ${semester} ${date.getFullYear()}`;
        }
        if (config?.params?.relative.dateFormat === 'year') {
          dateLabel = `${date.getFullYear()}`;
        }
        return { ...d, dateLabel, date };
      });
    }
  }

  array.forEach((e, i) => {
    if (e[parent]) {
      let parentFields = e[parent];
      if (!Array.isArray(parentFields)) {
        parentFields = [parentFields];
      }
      if (relative?.parent && relative.type === 'Date') {
        if (config?.params?.relative.dateFormat) {
          parentFields = parentFields.map((d) => {
            const date = new Date(d[relative.value]);
            let dateLabel = null;
            if (config?.params?.relative.dateFormat === 'date') {
              dateLabel = format(date, 'dd/MM/yyyy');
            }
            if (config?.params?.relative.dateFormat === 'month') {
              dateLabel = new Intl.DateTimeFormat(lang, { month: 'long', year: 'numeric' }).format(date);
            }
            if (config?.params?.relative.dateFormat === 'week') {
              const startDate = new Date(date.getFullYear(), 0, 1);
              const days = Math.floor((date - startDate) / (24 * 60 * 60 * 1000));
              const weekNumber = Math.ceil(days / 7);
              dateLabel = `${translateDate[lang].week} ${weekNumber} ${date.getFullYear()}`;
            }
            if (config?.params?.relative.dateFormat === 'semester') {
              const semester = date.getMonth() < 7 ? 1 : 2;
              dateLabel = `${translateDate[lang].semester} ${semester} ${date.getFullYear()}`;
            }
            if (config?.params?.relative.dateFormat === 'year') {
              dateLabel = `${date.getFullYear()}`;
            }
            return { ...d, dateLabel, date };
          });
        }
      }
      array[i][parent] = parentFields;
      const entity = { ...e };
      parentFields.forEach((f) => {
        const elementIndex = elements.findIndex((el) => el.element_id === f.element_id);
        if (elementIndex === -1) {
          const element = { ...f, entities: [entity] };
          elements.push(element);
        } else if (!elements[elementIndex].entities.find(
          (el) => el.entity_id === entity.entity_id,
        )) {
          elements[elementIndex].entities = [...elements[elementIndex].entities, entity];
        }
      });
    }
  });

  let data = [...elements];
  let splitWords = [];
  if (property?.type === 'Textarea') {
    if (config.splitWords === 'no') {
      elements?.forEach((a) => {
        const sentence = a[property.value] || null;
        if (sentence) {
          splitWords.push({ [property.value]: sentence });
        }
      });
    }
    if (config.splitWords === 'yes') {
      elements?.forEach((a) => {
        const sentence = a[property.value] || null;
        const words = sentence?.split(' ');
        words?.forEach((w) => {
          if (w.length > 4) {
            const word = w.toLowerCase()
              .replace('(', '')
              .replace(')', '')
              .replace(',', '');
            splitWords.push({ [property.value]: word });
          }
        });
      });
    }
    if (config.splitWords === 'regex' && config.regex) {
      try {
        // eslint-disable-next-line prefer-regex-literals
        const match = config.regex.match(new RegExp('^/(.*?)/([gimy]*)$'));
        if (match) {
          const regex = new RegExp(match[1], match[2]);
          let words = '';
          elements?.forEach((a) => {
            const sentence = a[property.value] || null;
            if (sentence) {
              words += sentence;
            }
          });
          words?.split(regex)?.map((w) => ({ [property.value]: w })).forEach((w) => {
            const index = splitWords.findIndex((a) => a[property.value] === w[property.value]);
            if (index !== -1) {
              splitWords[index].value += 1;
            } else {
              splitWords.push({ ...w, value: 1 });
            }
          });
        } else {
          result.error = {
            type: 'regex',
            message: "l'expression n'est pas valide",
          };
        }
        // }
      } catch (error) {
        console.error(error);
      }
    }
    splitWords = splitWords.filter((s) => {
      const count = splitWords.filter((sp) => sp[property.value] === s[property.value])?.length;
      if (count < 3) {
        return null;
      }
      return { ...s, count };
    });
    if (config.splitWords !== 'no' && splitWords.length > 100) {
      result.error = {
        type: 'config.splitWords',
        message: `Le résultat contient ${splitWords.length} mots`,
      };
    }
    if (graph === 'word_cloud') {
      splitWords = splitWords.filter((s, i) => i < 100);
    }
  }

  // GET PREV COLOR
  function getPrevColor(id) {
    const color = prevData?.find((p) => p.id === id)?.color;
    if (Number.isInteger(color)) {
      return null;
    }
    return color;
  }

  // PREPARE DATA FOR AGGREGARTION
  const aggregatedId = property?.value?.split('.')[1];
  if (aggregatedId && property?.value.includes('aggregation.')) {
    const aggregated = aggregation
    // eslint-disable-next-line no-prototype-builtins
      && aggregation?.hasOwnProperty(aggregatedId) && aggregation[aggregatedId];

    if (aggregated?.buckets?.length === 0) {
      result.error = {
        type: 'aggregation',
        message: "l'aggrégation est vide",
      };
      return result;
    }
    if (!aggregated?.buckets) {
      return result;
    }
    aggregated?.buckets.forEach((b) => {
      let name = initData.find((e) => e.entity_id === b.key)?.Nom
      || labels.find((e) => e.element_id === b.key)?.label
      || b.key;
      const value = b.count;
      if (b.key === 'false' || b.key === 'true') {
        name = b.key === 'false' ? 'Non' : 'Oui';
      }
      if (property?.type === 'Date' && !name) {
        const date = b.key && new Date(b.key);
        if (date && isValidDate(date)) {
          if (config.dateFormat === 'date') {
            name = format(date, 'dd/MM/yyyy');
          }
          if (config.dateFormat === 'month') {
            name = new Intl.DateTimeFormat(lang, { month: 'long', year: 'numeric' }).format(date);
          }
          if (config.dateFormat === 'week') {
            const startDate = new Date(date.getFullYear(), 0, 1);
            const days = Math.floor((date - startDate) / (24 * 60 * 60 * 1000));
            const weekNumber = Math.ceil(days / 7);
            name = `${translateDate[lang].week} ${weekNumber} ${date.getFullYear()}`;
          }
          if (config.dateFormat === 'semester') {
            const semester = date.getMonth() < 7 ? 1 : 2;
            name = `${translateDate[lang].semester} ${semester} ${date.getFullYear()}`;
          }
          if (config.dateFormat === 'year') {
            name = date.getFullYear();
          }
        }
      }
      if (name && !result?.data?.find((r) => r.name === name)) {
        const aggItem = {
          name,
          value,
          key: calc,
          isOnLegend: true,
          id: name,
        };
        result.data.push(aggItem);
      }
      const index = result.data.findIndex((r) => r.name === name);
      if (property?.type === 'Date' && index !== -1) {
        result.data[index].value += b.count;
      }
    });
    let count = elements.filter((e) => e[aggregatedId]).length;
    if (graph === 'key_number') {
      data = [];
      if (calc === 'SUM_ABSOLUT') {
        count = aggregated?.buckets.map((e) => parseFloat(e.key))
          .reduce((acc, val) => acc + val, 0);
      }
      data.push({
        name: property.label,
        value: count,
        color: defaultColor,
        key: 'COUNT_ABSOLUT',
        isOnLegend: true,
        id: property.label,
      });
      result.data = data;
    }
    if (calc === 'COUNT_SEPARE_LIST') {
      result.data = result.data.map((d) => ({
        ...d, value: getPercantage(d.value, count), unit: '%',
      }));
      if (!config.ascending) {
        result.data = result.data.reverse();
      }
    }
    if (graph === 'histogram' || graph === 'word_cloud') {
      result.data = result.data.map((d) => ({
        ...d,
        color: defaultColor,
      })).sort((a, b) => a.value - b.value);
      if (!config.ascending) {
        result.data = result.data.reverse();
      }
    }
    if (graph === 'pie' || graph === 'bubbles' || graph === 'treemap') {
      const max = d3.max(result.data.map((d) => d.value));
      const min = d3.min(result.data.map((d) => d.value));
      result.data = result.data
        .sort((a, b) => (b.value - a.value))
        .map((d) => ({ ...d, color: getPrevColor(d.id) || colorScale(d.value, max, min) }));
    }
    if (graph === 'lines') {
      result.data = result.data.map((d) => ({
        ...d,
        color: getPrevColor(d.id) || defaultColor,
      })).sort((a, b) => a.value - b.value);
    }
    return result;
  }

  if (calc !== 'COUNT_RELATIVE' && property?.type === 'Date' && config.dateFormat) {
    elements = elements?.map((a) => {
      const obj = { ...a };
      const date = a[property.value] && new Date(a[property.value]);
      if (date && isValidDate(date)) {
        obj.date = date;
        if (config.dateFormat === 'date') {
          obj[property.value] = format(date, 'dd/MM/yyyy');
        }
        if (config.dateFormat === 'month') {
          obj[property.value] = new Intl.DateTimeFormat(lang, { month: 'long', year: 'numeric' }).format(date);
        }
        if (config.dateFormat === 'week') {
          const startDate = new Date(date.getFullYear(), 0, 1);
          const days = Math.floor((date - startDate) / (24 * 60 * 60 * 1000));
          const weekNumber = Math.ceil(days / 7);
          obj[property.value] = `${translateDate[lang].week} ${weekNumber} ${date.getFullYear()}`;
        }
        if (config.dateFormat === 'semester') {
          const semester = date.getMonth() < 7 ? 1 : 2;
          obj[property.value] = `${translateDate[lang].semester} ${semester} ${date.getFullYear()}`;
        }
        if (config.dateFormat === 'year') {
          obj[property.value] = date.getFullYear();
        }
      }
      return obj;
    }).sort(
      (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime(),
    );
  }
  if (property?.type === 'Boolean') {
    elements = elements?.map((a) => ({ ...a, [property.value]: a[property.value] === 'true' ? 'Oui' : 'Non' }));
  }
  if (calc === 'COUNT_ABSOLUT_BRUT') {
    data = [];
    const count = array.filter((e) => e[parent])
      .reduce((acc, obj) => acc + obj.value, 0);
    if (graph === 'key_number') {
      data.push({
        name: property.label,
        value: count,
        color: defaultColor,
        key: 'COUNT_ABSOLUT',
        isOnLegend: true,
        id: property.label,
      });
      result.data = data;
    }
  }
  if (calc === 'COUNT_ABSOLUT') {
    data = [];
    let count = array.filter(
      (e) => e[parent] && e[parent].find((p) => p[property.value]),
    ).length;
    if (property.type === 'OrderedMultipleChoiceList' || property.type === 'MultipleChoiceList') {
      count = array.filter(
        (e) => e[parent] && e[parent].find((p) => p[property.value].length > 0),
      ).length;
    }
    if (graph === 'key_number') {
      data.push({
        name: property.label,
        value: count,
        color: defaultColor,
        key: 'COUNT_ABSOLUT',
        isOnLegend: true,
        id: property.label,
      });
      result.data = data;
    }
  }
  if (calc === 'COUNT_NOT_INCLUDE') {
    data = [];
    let count = array.filter(
      (e) => !e[parent] || (e[parent] && e[parent].find((p) => p[property.value])),
    ).length;
    if (property.type === 'OrderedMultipleChoiceList' || property.type === 'MultipleChoiceList') {
      count = array.filter(
        (e) => !e[parent] || (e[parent] && e[parent].find((p) => p[property.value].length === 0)),
      ).length;
    }
    if (graph === 'key_number') {
      data.push({
        name: property.label,
        value: count,
        color: defaultColor,
        key: 'COUNT_ABSOLUT',
        isOnLegend: true,
        id: property.label,
      });
      result.data = data;
    }
  }
  if (calc === 'SUM_ABSOLUT') {
    let count = elements.map((a) => (
      parseFloat(a[property.value]) > 0 ? parseFloat(a[property.value]) : null
    )).reduce(
      (previousValue, currentValue) => previousValue + currentValue,
      0,
    );
    if (property.type === 'OrderedMultipleChoiceList' || property.type === 'MultipleChoiceList') {
      count = elements.map((a) => (
        a[property.value].length ? 1 : 0
      )).reduce(
        (previousValue, currentValue) => previousValue + currentValue,
        0,
      );
    }
    data = [];
    if (graph === 'key_number') {
      data.push({
        name: property.label,
        value: count,
        color: defaultColor,
        key: 'COUNT_ABSOLUT',
        isOnLegend: true,
        id: property.label,
      });
      result.data = data;
    }
  }
  if (calc === 'COUNT_SEPARE_LIST' || calc === 'COUNT_ABSOLUT_LIST') {
    const source = splitWords.length ? splitWords : elements;
    let total = 0;
    data = [];
    source.forEach((a, i) => {
      const sort = i;
      let unit = null;
      const label = a[property?.value];
      if (calc === 'COUNT_SEPARE_LIST') {
        unit = '%';
      }
      if (Array.isArray(a[property.value])) {
        total += a[property.value].length;
      } else {
        total += 1;
      }
      if (!label) return;
      if (property?.type === 'OrderedMultipleChoiceList' || property?.type === 'RevertedFromElement' || property.type === 'UniqueEntityLink' || property?.type === 'RevertedFromEntity' || property?.type === 'MultipleEntityLink' || property?.type === 'MultipleChoiceList' || property?.type === 'UniqueChoiceList') {
        let cumulLabel = a[property.value]?.libelle
      || a[property.value]?.label
      || a[property.value]?.CC_VALEUR
      || a[property.value]?.core_hasListName
      || a[property.value]?.title
      || initData.find((l) => l.entity_id === a[property.value])?.label
      || labels.find((l) => l.entity_id === a[property.value]?.element_id)?.label
      || labels.find((l) => l.element_id === a[property.value]?.element_id)?.label
      || a[property.value]?.element_id
      || a[property.value]?.name;
        let lists = Array.isArray(a[property.value])
          && a[property.value].map(
            (l) => initData.find((e) => e.entity_id === l.element_id)?.Nom
            || labels.find((la) => la.element_id === l?.element_id)?.label
            || l.element_id,
          );

        if (property.type === 'UniqueEntityLink') {
          cumulLabel = array?.find((d) => d.entity_id === a[property.value])?.Nom
          || entitiesData.find((e) => e.entity_id === a[property.value])?.label;
        }
        if (property.type === 'UniqueChoiceList' || property.type === 'UniqueEntityLink') {
          lists = [cumulLabel];
        }
        lists?.forEach((t) => {
          const index = data.findIndex((d) => d.id === t);
          if (index === -1) {
            data.push({
              name: t,
              value: 1,
              key: calc,
              isOnLegend: true,
              id: t,
              unit,
              sort: 1,
            });
          } else if (index >= 0) {
            data[index].value += 1;
            data[index].sort += 1;
          }
        });
      } else if (label) {
        const index = data.findIndex((d) => d.id === label);
        if (index === -1) {
          data.push({
            name: label,
            value: 1,
            key: calc,
            date: a?.date || null,
            isOnLegend: true,
            id: label,
            unit,
            sort,
          });
        } else if (index >= 0) {
          data[index].value += 1;
        }
      }
    });

    if (data.length && data[0].unit === '%') {
      data = data.map((d) => ({
        ...d, value: getPercantage(d.value, total),
      }));
    }

    if (graph === 'pie' || graph === 'bubbles') {
      const max = d3.max(data.map((d) => d.value));
      const min = d3.min(data.map((d) => d.value));
      result.data = data
        .sort((a, b) => (b.value - a.value))
        .map((d) => ({ ...d, color: getPrevColor(d.id) || colorScale(d.value, max, min) }));
    }
    if (graph === 'word_cloud') {
      result.data = data.map((d) => ({
        ...d,
        color: defaultColor,
      })).sort((a, b) => a.value - b.value);
    }
    if (graph === 'treemap') {
      result.data = data.map((d) => ({
        ...d,
        color: defaultColor,
      })).sort((a, b) => a.value - b.value);
    }
    if (graph === 'histogram' || graph === 'lines') {
      if (property?.type === 'Date' && config.dateFormat) {
        result.data = [];
        const dates = data.sort(
          (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime(),
        );
        let currentDate = new Date(dates[0]?.date);
        const lastDate = new Date(dates[dates.length - 1]?.date);
        while (currentDate.getTime() <= lastDate.getTime()) {
          let dateData = {
            value: 0,
            date: currentDate,
            color: defaultColor,
          };
          if (config.dateFormat === 'date') {
            const label = format(currentDate, 'dd/MM/yyyy');
            dateData.name = label;
            dateData.id = label;
            const foundValue = data.find(
              (d) => d.name === label,
            );
            if (foundValue) {
              dateData = {
                ...dateData,
                ...foundValue,
              };
            }
            if (!result.data.find((d) => d.name === dateData.name)) {
              result.data.push(dateData);
            }
            currentDate = new Date(currentDate.setDate(currentDate.getDate() + 1));
          }
          if (config.dateFormat === 'month') {
            const label = new Intl.DateTimeFormat(lang, { month: 'long', year: 'numeric' }).format(currentDate);
            dateData.name = label;
            dateData.id = label;
            const foundValue = data.find(
              (d) => d.name === label,
            );
            if (foundValue) {
              dateData = {
                ...dateData,
                ...foundValue,
              };
            }
            if (!result.data.find((d) => d.name === dateData.name)) {
              result.data.push(dateData);
            }
            currentDate = new Date(currentDate.setMonth(currentDate.getMonth() + 1));
          }
          if (config.dateFormat === 'week') {
            const startDate = new Date(currentDate.getFullYear(), 0, 1);
            const days = Math.floor((currentDate - startDate) / (24 * 60 * 60 * 1000));
            const weekNumber = Math.ceil(days / 7);
            const label = `${translateDate[lang].week} ${weekNumber} ${currentDate.getFullYear()}`;
            dateData.name = label;
            dateData.id = label;
            const foundValue = data.find(
              (d) => d.name === label,
            );
            if (foundValue) {
              dateData = {
                ...dateData,
                ...foundValue,
              };
            }
            if (!result.data.find((d) => d.name === dateData.name)) {
              result.data.push(dateData);
            }
            currentDate = new Date(currentDate.setDate(currentDate.getDate() + 7));
          }
          if (config.dateFormat === 'semester') {
            const semester = currentDate.getMonth() < 7 ? 1 : 2;
            const label = `${translateDate[lang].semester} ${semester} ${currentDate.getFullYear()}`;
            dateData.name = label;
            dateData.id = label;
            const foundValue = data.find(
              (d) => d.name === label,
            );
            if (foundValue) {
              dateData = {
                ...dateData,
                ...foundValue,
              };
            }
            if (!result.data.find((d) => d.name === dateData.name)) {
              result.data.push(dateData);
            }
            currentDate = new Date(currentDate.setMonth(currentDate.getMonth() + 6));
          }
          if (config.dateFormat === 'year') {
            const label = currentDate.getFullYear();
            dateData.name = label;
            dateData.id = label;
            const foundValue = data.find(
              (d) => d.name === label,
            );
            if (foundValue) {
              dateData = {
                ...dateData,
                ...foundValue,
              };
            }
            if (!result.data.find((d) => d.name === dateData.name)) {
              result.data.push(dateData);
            }
            currentDate = new Date(currentDate.getFullYear() + 1, 0, 1);
          }
        }
        if (graph === 'histogram' && config.ascending !== null) {
          result.data = result.data.sort((a, b) => a.value - b.value);
        }
        if (config.ascending) {
          result.data = result.data.reverse();
        }
        if (config.ascending === null) {
          if (config.ascendingLabel) {
            result.data = result.data.reverse();
          }
        }
      } else if (property?.type === 'Float' || property?.type === 'Numeric') {
        result.data = data.map((d) => ({
          ...d,
          color: defaultColor,
        })).sort((a, b) => a.name - b.name);
        if (graph === 'histogram') {
          result.data = result.data.sort((a, b) => a.value - b.value);
        }
        if (config.ascending) {
          result.data = result.data.reverse();
        }
        if (config.ascending === null) {
          result.data = result.data.sort((a, b) => a.name.localeCompare(b.name));
          if (config.ascendingLabel) {
            result.data = result.data.reverse();
          }
        }
      } else {
        result.data = data.map((d) => ({
          ...d,
          color: defaultColor,
        })).sort((a, b) => a.value - b.value);
        if (config.ascending) {
          result.data = result.data.reverse();
        }
        if (config.ascending === null) {
          result.data = result.data.sort((a, b) => a.name.localeCompare(b.name));
          if (config.ascendingLabel) {
            result.data = result.data.reverse();
          }
        }
      }
    }
    if (property.type === 'TripleState') {
      result.data = result.data.map((d) => {
        const da = { ...d };
        if (d.name === 'None') {
          da.name = 'Aucun';
          da.color = '#999999';
        }
        return da;
      });
    }
  }
  if (calc === 'COUNT_RELATIVE') {
    const currentLegend = config?.legend.stacked;
    const stackedLegend = [];
    if (!relative?.parent) {
      array.filter((e) => e[parent]).forEach((en) => {
        let date = null;
        let name = en[relative.value]?.label
        || en[relative.value]?.CC_VALEUR
        || en[relative.value]?.core_hasListName
        || en[relative.value]?.name
        || en[relative.value]?.title;
        if (relative.type === 'Numeric' || relative.type === 'Float' || relative.type === 'Boolean' || relative.type === 'Textarea' || relative.type === 'NonLocalizedText') {
          name = en[relative.value];
        }
        if (relative.type === 'Date') {
          const dateValue = en[relative.value] && new Date(en[relative.value]);
          if (dateValue && isValidDate(dateValue)) {
            name = en.dateLabel;
            date = dateValue;
          }
        }
        if (name && !result.data.find((d) => d.name === name)) {
          const count = 0;
          const relativeItem = {
            name,
            count,
            date,
          };
          const elementsValues = elements.filter((p) => {
            const found = p.entities.find((e) => {
              let relativeName = e[relative.value]?.label
              || e[relative.value]?.CC_VALEUR
              || e[relative.value]?.core_hasListName
              || e[relative.value]?.name
              || e[relative.value]?.title;
              if (relative.type === 'Numeric' || relative.type === 'Float' || relative.type === 'Boolean' || relative.type === 'Textarea' || relative.type === 'NonLocalizedText') {
                relativeName = e[relative.value];
              }
              if (relative.type === 'Date') {
                const dateValue = e[relative.value] && new Date(e[relative.value]);
                if (dateValue && isValidDate(dateValue)) {
                  relativeName = e.dateLabel;
                  date = dateValue;
                }
              }
              return relativeName === name;
            });
            return found ? p : null;
          }).filter((p) => p[property.value]).map((p) => p[property.value]);
          if (elementsValues) {
            elementsValues.forEach(
              (p) => {
                if (Array.isArray(p)) {
                  p.forEach((v) => {
                    const label = initData.find(
                      (i) => i.entity_id === v.element_id,
                    )?.Nom || v.element_id;
                    if (!relativeItem[label]) {
                      relativeItem[label] = 1;
                      relativeItem.count += 1;
                      const foundLegendIndex = stackedLegend.findIndex(
                        (s) => s.dataKey === label,
                      );
                      if (foundLegendIndex !== -1) {
                        if (relative.type !== 'Numeric' && relative.type !== 'Float') {
                          stackedLegend[foundLegendIndex].count += 1;
                        }
                      } else {
                        const legend = {
                          dataKey: label,
                          name: label,
                          count: 1,
                          isOnLegend: true,
                          color: currentLegend?.find((c) => c.name === label)?.color,
                        };
                        stackedLegend.push(legend);
                      }
                    } else {
                      relativeItem[label] += 1;
                      relativeItem.count += 1;
                    }
                  });
                } else {
                  let label = initData.find(
                    (i) => i.entity_id === p.element_id,
                  )?.Nom
                  || labels.find((la) => la.element_id === p[property.value]?.element_id)?.label
                  || p.element_id || p.label || p;
                  if (property?.type === 'Date' && config.dateFormat) {
                    const dateLabel = label && new Date(label);
                    if (dateLabel && isValidDate(dateLabel)) {
                      label = dateLabel;
                      if (config.dateFormat === 'date') {
                        label = format(dateLabel, 'dd/MM/yyyy');
                      }
                      if (config.dateFormat === 'month') {
                        label = new Intl.DateTimeFormat(lang, { month: 'long', year: 'numeric' }).format(dateLabel);
                      }
                      if (config.dateFormat === 'week') {
                        const startDate = new Date(dateLabel.getFullYear(), 0, 1);
                        const days = Math.floor((dateLabel - startDate) / (24 * 60 * 60 * 1000));
                        const weekNumber = Math.ceil(days / 7);
                        label = `${translateDate[lang].week} ${weekNumber} ${dateLabel.getFullYear()}`;
                      }
                      if (config.dateFormat === 'semester') {
                        const semester = dateLabel.getMonth() < 7 ? 1 : 2;
                        label = `${translateDate[lang].semester} ${semester} ${dateLabel.getFullYear()}`;
                      }
                      if (config.dateFormat === 'year') {
                        label = dateLabel.getFullYear();
                      }
                    }
                  }
                  if (!relativeItem[label]) {
                    relativeItem[label] = 1;
                    relativeItem.count += 1;
                    const foundLegendIndex = stackedLegend.findIndex(
                      (s) => s.dataKey === label,
                    );
                    if (foundLegendIndex !== -1) {
                      if (relative.type !== 'Numeric' && relative.type !== 'Float') {
                        stackedLegend[foundLegendIndex].count += 1;
                      }
                    } else {
                      const legend = {
                        dataKey: label,
                        name: label,
                        count: 1,
                        isOnLegend: true,
                        color: currentLegend?.find((c) => c.name === label)?.color,
                      };
                      stackedLegend.push(legend);
                    }
                  } else {
                    relativeItem[label] += 1;
                    relativeItem.count += 1;
                  }
                }
              },
            );
          }
          if (relativeItem) {
            result.data.push(relativeItem);
          }
        }
        if (en[relative.value] && Array.isArray(en[relative.value])) {
          en[relative.value].forEach((prop) => {
            const propertyName = prop?.label
          || prop?.CC_VALEUR
          || prop?.core_hasListName
          || prop?.name
          || prop?.title;
            if (propertyName && !result.data.find((d) => d.name === propertyName)) {
              const count = 0;
              const relativeItem = {
                name: propertyName,
                count,
                date,
              };
              const elementsValues = elements.filter((p) => {
                const found = p.entities.find((e) => {
                  const relativeNames = e[relative.value]?.length > 0
                    ? e[relative.value].map((r) => r.label
                    || r.CC_VALEUR
                    || r?.core_hasListName
                    || r?.name
                    || r?.title) : [];
                  return relativeNames?.find((re) => re === propertyName);
                });
                return found ? p : null;
              }).filter((p) => p[property.value]).map((p) => p[property.value]);
              if (elementsValues) {
                elementsValues.forEach(
                  (p) => {
                    if (Array.isArray(p)) {
                      p.forEach((v) => {
                        const label = initData.find(
                          (i) => i.entity_id === v.element_id,
                        )?.Nom
                        || labels.find((la) => la.element_id === v.element_id)?.label
                        || v.element_id
                        || v.label;
                        if (!relativeItem[label]) {
                          relativeItem[label] = 1;
                          relativeItem.count += 1;
                          const foundLegendIndex = stackedLegend.findIndex(
                            (s) => s.dataKey === label,
                          );
                          if (foundLegendIndex !== -1) {
                            if (relative.type !== 'Numeric' && relative.type !== 'Float') {
                              stackedLegend[foundLegendIndex].count += 1;
                            }
                          } else {
                            const legend = {
                              dataKey: label,
                              name: label,
                              count: 1,
                              isOnLegend: true,
                              color: currentLegend?.find((c) => c.name === label)?.color,
                            };
                            stackedLegend.push(legend);
                          }
                        } else {
                          relativeItem[label] += 1;
                          relativeItem.count += 1;
                        }
                      });
                    } else {
                      let label = initData.find(
                        (i) => i.entity_id === p.element_id,
                      )?.Nom
                      || labels.find((la) => la.element_id === p.element_id)?.label
                      || p.element_id || p.label || p;
                      if (property?.type === 'Date' && config.dateFormat) {
                        const dateLabel = label && new Date(label);
                        if (dateLabel && isValidDate(dateLabel)) {
                          label = dateLabel;
                          if (config.dateFormat === 'date') {
                            label = format(dateLabel, 'dd/MM/yyyy');
                          }
                          if (config.dateFormat === 'month') {
                            label = new Intl.DateTimeFormat(lang, { month: 'long', year: 'numeric' }).format(dateLabel);
                          }
                          if (config.dateFormat === 'week') {
                            const startDate = new Date(dateLabel.getFullYear(), 0, 1);
                            const days = Math.floor(
                              (dateLabel - startDate) / (24 * 60 * 60 * 1000),
                            );
                            const weekNumber = Math.ceil(days / 7);
                            label = `${translateDate[lang].week} ${weekNumber} ${dateLabel.getFullYear()}`;
                          }
                          if (config.dateFormat === 'semester') {
                            const semester = dateLabel.getMonth() < 7 ? 1 : 2;
                            label = `${translateDate[lang].semester} ${semester} ${dateLabel.getFullYear()}`;
                          }
                          if (config.dateFormat === 'year') {
                            label = dateLabel.getFullYear();
                          }
                        }
                      }
                      if (!relativeItem[label]) {
                        relativeItem[label] = 1;
                        relativeItem.count += 1;
                        const foundLegendIndex = stackedLegend.findIndex(
                          (s) => s.dataKey === label,
                        );
                        if (foundLegendIndex !== -1) {
                          if (relative.type !== 'Numeric' && relative.type !== 'Float') {
                            stackedLegend[foundLegendIndex].count += 1;
                          }
                        } else {
                          const legend = {
                            dataKey: label,
                            name: label,
                            count: 1,
                            isOnLegend: true,
                            color: currentLegend?.find((c) => c.name === label)?.color,
                          };
                          stackedLegend.push(legend);
                        }
                      } else {
                        relativeItem[label] += 1;
                        relativeItem.count += 1;
                      }
                    }
                  },
                );
              }
              if (relativeItem) {
                result.data.push(relativeItem);
              }
            }
          });
        }
      });
    } else {
      elements.forEach((el) => {
        let date;
        let name = el[relative.value]?.label
          || el[relative.value]?.CC_VALEUR
          || el[relative.value]?.core_hasListName
          || el[relative.value]?.name
          || labels.find((l) => l.element_id === el[relative.value]?.element_id)?.label
          || el[relative.value]?.element_id
          || el[relative.value]?.title;

        if (relative.type === 'Numeric' || relative.type === 'Float' || relative.type === 'Boolean' || relative.type === 'Textarea') {
          name = el[relative.value];
        }
        if (relative.type === 'Date') {
          const dateValue = el[relative.value] && new Date(el[relative.value]);
          if (dateValue && isValidDate(dateValue)) {
            name = el.dateLabel;
            date = dateValue;
          }
        }
        if (name && !result.data.find((d) => d.name === name)) {
          const count = 0;
          const relativeItem = {
            name,
            count,
            date,
          };
          const elementsValues = elements.filter(
            (e) => {
              let relativeName = e[relative.value]?.label
              || e[relative.value]?.CC_VALEUR
              || e[relative.value]?.core_hasListName
              || e[relative.value]?.name
              || labels.find((l) => l.element_id === e[relative.value]?.element_id)?.label
              || e[relative.value]?.element_id
              || e[relative.value]?.title;
              if (relative.type === 'Numeric' || relative.type === 'Float' || relative.type === 'Boolean' || relative.type === 'Textarea') {
                relativeName = e[relative.value];
              }
              if (relative.type === 'Date') {
                const dateValue = e[relative.value] && new Date(e[relative.value]);
                if (dateValue && isValidDate(dateValue)) {
                  relativeName = e.dateLabel;
                }
              }
              if (relativeName === name && e[property.value]) {
                return e;
              }
              return null;
            },
          ).map((p) => p[property.value]);
          if (elementsValues) {
            elementsValues.forEach(
              (p) => {
                if (Array.isArray(p)) {
                  p.forEach((v) => {
                    const label = initData.find(
                      (i) => i.entity_id === v.element_id,
                    )?.Nom
                    || labels.find((l) => l.element_id === v?.element_id)?.label
                    || v.element_id;
                    if (!relativeItem[label]) {
                      relativeItem[label] = 1;
                      relativeItem.count += 1;
                      const foundLegendIndex = stackedLegend.findIndex(
                        (s) => s.dataKey === label,
                      );
                      if (foundLegendIndex !== -1) {
                        if (relative.type !== 'Numeric' && relative.type !== 'Float') {
                          stackedLegend[foundLegendIndex].count += 1;
                        }
                      } else {
                        const legend = {
                          dataKey: label,
                          name: label,
                          count: 1,
                          isOnLegend: true,
                          color: currentLegend?.find((c) => c.name === label)?.color,
                        };
                        stackedLegend.push(legend);
                      }
                    } else {
                      relativeItem[label] += 1;
                      relativeItem.count += 1;
                    }
                  });
                } else {
                  let label = initData.find(
                    (i) => i.entity_id === p.element_id,
                  )?.Nom
                  || labels.find((l) => l.element_id === p?.element_id)?.label
                  || p.element_id
                  || p.label
                  || p;
                  if (property?.type === 'Date' && config.dateFormat) {
                    const dateLabel = label && new Date(label);
                    if (dateLabel && isValidDate(dateLabel)) {
                      label = dateLabel;
                      if (config.dateFormat === 'date') {
                        label = format(dateLabel, 'dd/MM/yyyy');
                      }
                      if (config.dateFormat === 'month') {
                        label = new Intl.DateTimeFormat(lang, { month: 'long', year: 'numeric' }).format(dateLabel);
                      }
                      if (config.dateFormat === 'week') {
                        const startDate = new Date(dateLabel.getFullYear(), 0, 1);
                        const days = Math.floor((dateLabel - startDate) / (24 * 60 * 60 * 1000));
                        const weekNumber = Math.ceil(days / 7);
                        label = `${translateDate[lang].week} ${weekNumber} ${dateLabel.getFullYear()}`;
                      }
                      if (config.dateFormat === 'semester') {
                        const semester = dateLabel.getMonth() < 7 ? 1 : 2;
                        label = `${translateDate[lang].semester} ${semester} ${dateLabel.getFullYear()}`;
                      }
                      if (config.dateFormat === 'year') {
                        label = dateLabel.getFullYear();
                      }
                    }
                  }
                  if (!relativeItem[label]) {
                    relativeItem[label] = 1;
                    relativeItem.count += 1;
                    const foundLegendIndex = stackedLegend.findIndex(
                      (s) => s.dataKey === label,
                    );
                    if (foundLegendIndex !== -1) {
                      if (relative.type !== 'Numeric' && relative.type !== 'Float') {
                        stackedLegend[foundLegendIndex].count += 1;
                      }
                    } else {
                      const legend = {
                        dataKey: label,
                        name: label,
                        count: 1,
                        isOnLegend: true,
                        color: currentLegend?.find((c) => c.name === label)?.color,
                      };
                      stackedLegend.push(legend);
                    }
                  } else {
                    relativeItem[label] += 1;
                    relativeItem.count += 1;
                  }
                }
              },
            );
          }
          if (relativeItem) {
            relativeItem.count = 0;
            result.data.push(relativeItem);
          }
        }
        if (el[relative.value] && Array.isArray(el[relative.value])) {
          el[relative.value].forEach((prop) => {
            const propertyName = initData.find(
              (i) => i.entity_id === prop.element_id,
            )?.Nom
          || prop?.label
          || prop?.CC_VALEUR
          || prop?.core_hasListName
          || labels.find((l) => l.element_id === prop?.element_id)?.label
          || prop?.name
          || prop?.title
          || prop?.element_id;
            if (propertyName && !result.data.find((d) => d.name === propertyName)) {
              const count = 0;
              const relativeItem = {
                name: propertyName,
                count,
                date,
              };
              const elementsValues = elements.filter(
                (e) => {
                  const found = e[relative.value].find((d) => {
                    const relativeName = initData.find(
                      (i) => i.entity_id === d.element_id,
                    )?.Nom
                    || d?.label
                    || d?.CC_VALEUR
                    || d?.core_hasListName
                    || d?.name
                    || d?.title
                    || labels.find((l) => l.element_id === d?.element_id)?.label
                    || d?.element_id;
                    return relativeName === propertyName;
                  });
                  if (found && e[property.value]) {
                    return e;
                  }
                  return null;
                },
              ).map((p) => p[property.value]);
              if (elementsValues) {
                elementsValues.forEach(
                  (p) => {
                    if (Array.isArray(p)) {
                      p.forEach((v) => {
                        const label = initData.find(
                          (i) => i.entity_id === v.element_id,
                        )?.Nom
                        || labels.find((la) => la.element_id === v.element_id)?.label
                        || v.element_id
                        || v.label;
                        if (!relativeItem[label]) {
                          relativeItem[label] = 1;
                          relativeItem.count += 1;
                          const foundLegendIndex = stackedLegend.findIndex(
                            (s) => s.dataKey === label,
                          );
                          if (foundLegendIndex !== -1) {
                            if (relative.type !== 'Numeric' && relative.type !== 'Float') {
                              stackedLegend[foundLegendIndex].count += 1;
                            }
                          } else {
                            const legend = {
                              dataKey: label,
                              name: label,
                              count: 1,
                              isOnLegend: true,
                              color: currentLegend?.find((c) => c.name === label)?.color,
                            };
                            stackedLegend.push(legend);
                          }
                        } else {
                          relativeItem[label] += 1;
                          relativeItem.count += 1;
                        }
                      });
                    } else {
                      let label = initData.find(
                        (i) => i.entity_id === p.element_id,
                      )?.Nom
                      || labels.find((la) => la.element_id === p.element_id)?.label
                      || p.element_id
                      || p.label
                      || p;
                      if (property?.type === 'Date' && config.dateFormat) {
                        const dateLabel = label && new Date(label);
                        if (dateLabel && isValidDate(dateLabel)) {
                          label = dateLabel;
                          if (config.dateFormat === 'date') {
                            label = format(dateLabel, 'dd/MM/yyyy');
                          }
                          if (config.dateFormat === 'month') {
                            label = new Intl.DateTimeFormat(lang, { month: 'long', year: 'numeric' }).format(dateLabel);
                          }
                          if (config.dateFormat === 'week') {
                            const startDate = new Date(dateLabel.getFullYear(), 0, 1);
                            const days = Math.floor(
                              (dateLabel - startDate) / (24 * 60 * 60 * 1000),
                            );
                            const weekNumber = Math.ceil(days / 7);
                            label = `${translateDate[lang].week} ${weekNumber} ${dateLabel.getFullYear()}`;
                          }
                          if (config.dateFormat === 'semester') {
                            const semester = dateLabel.getMonth() < 7 ? 1 : 2;
                            label = `${translateDate[lang].semester} ${semester} ${dateLabel.getFullYear()}`;
                          }
                          if (config.dateFormat === 'year') {
                            label = dateLabel.getFullYear();
                          }
                        }
                      }
                      if (!relativeItem[label]) {
                        relativeItem[label] = 1;
                        relativeItem.count += 1;
                        const foundLegendIndex = stackedLegend.findIndex(
                          (s) => s.dataKey === label,
                        );
                        if (foundLegendIndex !== -1) {
                          if (relative.type !== 'Numeric' && relative.type !== 'Float') {
                            stackedLegend[foundLegendIndex].count += 1;
                          }
                        } else {
                          const legend = {
                            dataKey: label,
                            name: label,
                            count: 1,
                            isOnLegend: true,
                            color: currentLegend?.find((c) => c.name === label)?.color,
                          };
                          stackedLegend.push(legend);
                        }
                      } else {
                        relativeItem[label] += 1;
                        relativeItem.count += 1;
                      }
                    }
                  },
                );
              }
              if (relativeItem) {
                relativeItem.count = 0;
                result.data.push(relativeItem);
              }
            }
          });
        }
      });
    }

    result.data = result.data.map((d) => {
      const item = { ...d, count: 0 };
      const entries = Object.keys(d).filter((k) => k !== 'name' && k !== 'count' && k !== 'date');
      for (const key of entries) {
        if (d[key] > 0) {
          item.count += d[key];
        }
      }
      return item;
    }).sort((a, b) => (a.count - b.count));
    result.stacked = stackedLegend.sort(
      (a, b) => (a.count - b.count),
    )
      .map((d, i) => ({
        ...d,
        color: d.color || colorScale(i, 0, stackedLegend.length),
      }));
    if (relative.type === 'Boolean' && property?.type === 'Boolean') {
      const relativeLabel = config?.params?.relative?.value?.label;
      result.data = result.data.map((d) => {
        const item = { ...d };
        if (d.name === 'true' || d.name === 'false') {
          item.name = `${property.label} : ${d.name === 'true' ? 'Oui' : 'Non'}`;
          item[`${relativeLabel} : Oui`] = item.Oui;
          item[`${relativeLabel} : Non`] = item.Non;
        }
        return item;
      });
      result.stacked = result.stacked.map((s) => {
        const item = { ...s };
        if (s.dataKey === 'Oui' || s.dataKey === 'true') {
          item.dataKey = `${relativeLabel} : Oui`;
          item.name = `${relativeLabel} : Oui`;
        }
        if (s.dataKey === 'Non' || s.dataKey === 'false') {
          item.dataKey = `${relativeLabel} : Non`;
          item.name = `${relativeLabel} : Non`;
        }
        return item;
      });
    } else if (property?.type === 'Boolean') {
      const { label } = property;
      result.data = result.data.map((d) => {
        const item = { ...d };
        if (item) {
          item[`${label} : Oui`] = item.Oui;
          item[`${label} : Non`] = item.Non;
        }
        return item;
      });
      result.stacked = result.stacked.map((s) => {
        const item = { ...s };
        if (s.dataKey === 'Oui' || s.dataKey === 'true') {
          item.dataKey = `${label} : Oui`;
          item.name = `${label} : Oui`;
        }
        if (s.dataKey === 'Non' || s.dataKey === 'false') {
          item.dataKey = `${label} : Non`;
          item.name = `${label} : Non`;
        }
        return item;
      });
    } else if (relative.type === 'Boolean') {
      result.data = result.data.map((d) => {
        const item = { ...d };
        if (d.name === 'true' || d.name === 'false') {
          item.name = `${config?.params?.relative?.value?.label} : ${d.name === 'true' ? 'Oui' : 'Non'}`;
        }
        return item;
      });
      result.stacked = result.stacked.map((s) => {
        const item = { ...s };
        return item;
      });
    }
    if (relative.type === 'Date') {
      result.data = result.data.sort((a, b) => (b.date < a.date ? 1 : -1));
    }
    if (config.ascending) {
      result.data = result.data.reverse();
    }
    if (config.ascending === null) {
      result.data = result.data.sort((a, b) => a.name.localeCompare(b.name));
      if (config.ascendingLabel) {
        result.data = result.data.reverse();
      }
    }
  }
  return result;
};

export default generateAssociatedElementsData;
