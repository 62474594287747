import React, { createRef, useEffect } from 'react';
import {
  useLocation,
  useNavigate,
  useParams,
  useOutletContext,
} from 'react-router-dom';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import { useSelector, useDispatch } from 'react-redux';
// import { RiDeleteBin6Line } from 'react-icons/ri';
import { useTranslation } from 'react-i18next';
import dots from '../../../assets/images/dots.svg';
import withModalConfirm from '../../../lib/withModalConfirm';

import { createIndicator, deleteIndicator, updateIndicator } from '../../../actions/indicators';

import {
  // Textarea,
  ErrorField,
  StyledSelect,
} from '../../../lib/HooksFormFields';

import styles from './indicators.module.scss';
import PositionalModal from '../../../lib/PositionalModal';
import { updateDashboardData } from '../../../actions/dashboards';
import { INDICATOR_RESET } from '../../../actions/types';

const ActionsBtn = ({
  _id,
  name,
  confirm,
  previewIndicator,
  duplicateIndicator,
}) => {
  const dispatch = useDispatch();
  // const navigate = useNavigate();
  const { t } = useTranslation();
  // const { search: searchQuery } = useLocation();
  const cellRef = createRef(`dashboard-${_id}`);
  const modalRef = createRef(`dashboard-modal-${_id}`);

  function openModal(e) {
    e.preventDefault();
    e.stopPropagation();
    modalRef.current.open();
  }

  function handleDeleteIndicator(e) {
    e.preventDefault();
    e.stopPropagation();
    confirm(
      () => deleteIndicator(dispatch, _id),
      `${t('dashboard.indicator.delete')} : ${name} ?`,
    );
  }
  function handleDuplicateIndicator(e) {
    e.preventDefault();
    e.stopPropagation();
    duplicateIndicator();
    modalRef.current.close();
    // duplicateDashboard(dispatch, _id);
  }

  function handlePreviewIndicator(e) {
    e.preventDefault();
    e.stopPropagation();
    previewIndicator(_id);
  }

  return (
    <div className={styles.action}>
      <PositionalModal
        ref={modalRef}
        parent={cellRef}
        alignmentX={'right'}
        alignmentY={'middle'}
      >
        <div className={styles.containerActions}>
          <div><p onClick={(e) => handlePreviewIndicator(e)}>{t('btn.preview')}</p></div>
          <div><p onClick={(e) => handleDuplicateIndicator(e)}>{t('btn.copy')}</p></div>
          <div><p onClick={(e) => handleDeleteIndicator(e)}>{t('btn.delete')}</p></div>
        </div>
      </PositionalModal>
      <div className={styles.toggle}>
        <span onClick={openModal}>
          <img src={dots} alt={`action sur le indicteur: ${name}`} />
        </span>
      </div>
      <div ref={cellRef} className={styles.positionModal}></div>
    </div>
  );
};

const Indicators = ({ confirm }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { dashboardReducer, indicatorReducer } = useSelector((store) => store);
  const { dashboard, user, updateErrors } = dashboardReducer;
  const { indicators } = indicatorReducer;
  const {
    control, errors, watch, setValue,
  } = useOutletContext();
  const { search } = useLocation();
  const { dashboardId } = useParams();

  const openIndicator = (id) => {
    navigate(`/edit-indicator/${dashboardId}/${id}/informations${search}`);
  };

  const previewIndicator = (id) => {
    navigate(`/edit-indicator/${dashboardId}/${id}/custom/preview${search}`);
  };

  const duplicateIndicator = async (indictor) => {
    const data = {
      ...indictor,
      name: `${indictor.name} (copie)`,
    };
    delete data._id;
    delete data.createdAt;
    delete data.updatedAt;
    await createIndicator(dispatch, {
      ...data,
      position: indicators.length + 1,
    });
  };

  const api = watch('api');
  const dataset = watch('dataset');

  const isNewKey = dataset?.value !== dashboard?.key;

  function handleIndicatorStatus(e, status, id) {
    e.stopPropagation();
    updateIndicator(dispatch, { isPublished: !status }, id);
  }

  function handleCreateIndicator() {
    navigate(`/edit-indicator/${dashboardId}/informations${search}`);
    dispatch({
      type: INDICATOR_RESET,
    });
  }

  useEffect(() => {
    if (api?.value) {
      setValue('requests', api.value);
    }
  }, [api]);

  useEffect(() => {
    if (dataset?.value) {
      setValue('key', dataset.value);
    }
  }, [dataset]);

  return (
    <div className={styles.indicators}>
      <div>
        <p>{t('dashboard.indicator.label')}</p>
        <div className={styles.btns}>
        <button className='secondary' onClick={() => handleCreateIndicator()}>{t('dashboard.indicator.create')}</button>
        {!isNewKey && api?.value && indicators.length > 0
          && <button
            type="button"
            onClick={() => updateDashboardData(dispatch, dataset, dashboard, isNewKey)}
          >
            {t('dashboard.updateIndicators')}
          </button>
        }
        </div>
        {(indicators.length > 0) && (
          <ul className={styles.listIndicators}>
            {indicators.sort((a, b) => (b.position < a.position ? 1 : -1)).map((d) => (
              <li key={`indicator-${d._id}`}>
                <div
                  className={`${styles.indicator} ${d.isPublished ? styles.active : ''}`}
                  onClick={() => openIndicator(d._id)}
                >
                  <p>{d.name}</p>
                  <div className={styles.actions}>
                    <button
                      type="button"
                      onClick={(e) => handleIndicatorStatus(e, d.isPublished, d._id)}
                      className={`${styles.pen}`}
                    >
                    {d.isPublished ? <AiOutlineEye/> : <AiOutlineEyeInvisible />}
                    </button>

                    <ActionsBtn
                      name={d.name}
                      _id={d._id}
                      confirm={confirm}
                      duplicateIndicator={() => duplicateIndicator(d)}
                      previewIndicator={previewIndicator}
                    />
                  </div>
                </div>
              </li>
            ))}
          </ul>
        )}
      </div>
      <div>
        {dashboard && (
          <>
           <div className={styles.containerField}>
              <StyledSelect
                name="api"
                control={control}
                label={t('dashboard.api')}
                isSearchable
                options={user?.apis}
              />

              {errors?.requests?.message && <ErrorField message={errors.requests.message} />}
            </div>
            <div className={styles.containerField}>
              <div>
                <StyledSelect
                  name="dataset"
                  control={control}
                  label={t('dashboard.dataset')}
                  isSearchable
                  options={user?.keys.filter((k) => k.api === api?.value) || []}
                />
              </div>
              {errors?.key?.message && <ErrorField message={errors.key.message} />}
            </div>
            {updateErrors?.length > 0
              && <div className={styles.errors}>
                {updateErrors.map((e, i) => <ErrorField
                  key={`${e.indicator}-${i}`} message={t('dashboard.errors', { indicator: e.indicator, value: e.value?.value || e.value })} />)}
              </div>
            }
            {isNewKey && api?.value && indicators.length > 0
              && <button
                type="button"
                onClick={() => updateDashboardData(dispatch, dataset, dashboard, isNewKey)}
              >
                {t('dashboard.update')}
              </button>
            }
          </>
        )}
      </div>
    </div>
  );
};

export default withModalConfirm(Indicators);
