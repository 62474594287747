/* eslint-disable no-dupe-keys */
const styleBase = {
  container: (provided) => ({ ...provided }),
  menu: (provided) => ({
    ...provided,
    zIndex: 10,
  }),
  menuList: (provided) => ({
    ...provided,
    paddingTop: '0px',
    paddingBottom: '0px',
  }),
  control: (provided) => ({
    ...provided,
    boxShadow: '0 0 0 0 transparent',
    borderColor: '#BDBDBD',
    borderWidth: '1px',
    backgroundColor: '#FFFFFF',
    minHeight: '50px',
    borderRadius: '5px',
    cursor: 'pointer',
    '&:hover': {
      borderColor: '',
    },
  }),
  placeholder: (provided) => ({
    ...provided,
    fontFamily: 'OpenSans-SemiBold',
    fontSize: '13px',
    color: '#939393',
  }),
  valueContainer: (provided) => ({
    ...provided,
    maxHeight: 150,
    overflowY: 'auto',
  }),
  groupHeading: (provided) => ({
    ...provided,
    fontSize: '16px',
    fontFamily: 'OpenSans-SemiBold',
    backgroundColor: '#F8F8F8',
    padding: '6px 12px',
  }),
  singleValue: (provided) => ({ ...provided, fontSize: '13px', fontFamily: 'OpenSans-SemiBold' }),
  multiValue: (provided) => ({ ...provided, fontSize: '13px' }),
  multiValueLabel: (provided) => ({ ...provided }),
  multiValueRemove: (provided) => ({ ...provided }),
  input: (provided) => ({
    ...provided,
    '&:focus': {
      boxShadow: '',
    },
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: '8px 8px',
  }),
  option: (provided, state) => {
    const style = {
      ...provided,
      fontFamily: 'OpenSans-SemiBold',
      fontSize: '13px',
      backgroundColor: state.isSelected ? '#6879C4' : '',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: state.isSelected ? '#6879C4' : '#F8F8F8',
      },
    };
    if (state.data.color) style.color = state.data.color;
    return style;
  },
  clearIndicator: (provided) => ({
    ...provided,
    padding: '8px 0 ',
  }),
  indicatorSeparator: () => ({ display: 'none' }),
  indicatorsContainer: (provided) => ({
    ...provided,
    height: '50px',
  }),
};

/* eslint-disable no-dupe-keys */
const styleNoBorder = {
  container: (provided) => ({ ...provided }),
  menu: (provided) => ({
    ...provided,
    overflow: 'hidden',
    minWidth: '100px',
  }),
  menuList: (provided) => ({
    ...provided,
    paddingTop: '0px',
    paddingBottom: '0px',
    overflow: 'hidden',
  }),
  control: (provided) => ({
    ...provided,
    boxShadow: '0 0 0 0 transparent',
    borderColor: 'transparent',
    borderWidth: '0px',
    backgroundColor: '#F8F8F8',
    minHeight: '50px',
    borderRadius: '5px',
    cursor: 'pointer',
    '&:hover': {
      borderColor: '',
    },
  }),
  placeholder: (provided) => ({
    ...provided,
    fontFamily: 'OpenSans-SemiBold',
    fontSize: '13px',
    color: '#939393',
  }),
  valueContainer: (provided) => ({
    ...provided,
  }),
  singleValue: (provided) => ({ ...provided, fontSize: '13px', fontFamily: 'OpenSans-SemiBold' }),
  multiValue: (provided) => ({ ...provided, fontSize: '13px' }),
  multiValueLabel: (provided) => ({ ...provided }),
  multiValueRemove: (provided) => ({ ...provided }),
  input: (provided) => ({
    ...provided,
    '&:focus': {
      boxShadow: '',
    },
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: '8px 8px',
  }),
  option: (provided, state) => {
    const style = {
      ...provided,
      fontFamily: 'OpenSans-SemiBold',
      fontSize: '13px',
      backgroundColor: state.isSelected ? '#6879C4' : '',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: state.isSelected ? '#6879C4' : '#F8F8F8',
      },
    };
    if (state.data.color) style.color = state.data.color;
    return style;
  },
  clearIndicator: (provided) => ({
    ...provided,
    padding: '8px 0 ',
  }),
  indicatorSeparator: () => ({ display: 'none' }),
  indicatorsContainer: (provided) => ({
    ...provided,
    height: '50px',
  }),
};

const stylePrimary = {
  container: (provided) => ({ ...provided }),
  menu: (provided) => ({
    ...provided,
    overflow: 'hidden',
  }),
  menuList: (provided) => ({
    ...provided,
    paddingTop: '0px',
    paddingBottom: '0px',
    overflow: 'hidden',
  }),
  control: (provided) => ({
    ...provided,
    boxShadow: '0px 18px 32px rgba(208, 210, 218, 0.15)',
    borderColor: '#BDBDBD',
    borderWidth: '1px',
    backgroundColor: '#FFFFFF',
    minHeight: '60px',
    borderRadius: '0',
    cursor: 'pointer',
    '&:hover': {
      borderColor: '',
    },
  }),
  placeholder: (provided) => ({
    ...provided,
    fontFamily: 'OpenSans-SemiBold',
    fontSize: '13px',
    color: '#939393',
  }),
  valueContainer: (provided) => ({
    ...provided,
    maxHeight: 150,
    overflowY: 'auto',
  }),
  singleValue: (provided) => ({ ...provided, fontSize: '13px', fontFamily: 'OpenSans-SemiBold' }),
  multiValue: (provided) => ({ ...provided, fontSize: '13px' }),
  multiValueLabel: (provided) => ({ ...provided }),
  multiValueRemove: (provided) => ({ ...provided }),
  input: (provided) => ({
    ...provided,
    '&:focus': {
      boxShadow: '',
    },
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: '8px 8px',
  }),
  option: (provided, state) => {
    const style = {
      ...provided,
      fontFamily: 'OpenSans-SemiBold',
      fontSize: '13px',
      backgroundColor: state.isSelected ? '#6879C4' : '',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: state.isSelected ? '#6879C4' : '#F8F8F8',
      },
    };
    if (state.data.color) style.color = state.data.color;
    return style;
  },
  clearIndicator: (provided) => ({
    ...provided,
    padding: '8px 0 ',
  }),
  indicatorSeparator: () => ({ display: 'none' }),
  indicatorsContainer: (provided) => ({
    ...provided,
    height: '60px',
  }),
};

const styleGrey = {
  container: (provided) => ({ ...provided }),
  menu: (provided) => ({
    ...provided,
    overflow: 'hidden',
  }),
  menuList: (provided) => ({
    ...provided,
    paddingTop: '0px',
    paddingBottom: '0px',
    overflow: 'hidden',
  }),
  control: (provided) => ({
    ...provided,
    boxShadow: '0 0 0 rgba(0, 0, 0, 0)',
    borderColor: 'transparent',
    borderWidth: '0px',
    backgroundColor: '#F8F8F8',
    minHeight: '50px',
    borderRadius: '5px',
    cursor: 'pointer',
    '&:hover': {
      borderColor: '',
    },
  }),
  placeholder: (provided) => ({
    ...provided,
    fontFamily: 'OpenSans-SemiBold',
    fontSize: '13px',
    color: '#939393',
  }),
  valueContainer: (provided) => ({
    ...provided,
    maxHeight: 150,
    overflowY: 'auto',
  }),
  singleValue: (provided) => ({ ...provided, fontSize: '13px', fontFamily: 'OpenSans-SemiBold' }),
  multiValue: (provided) => ({ ...provided, fontSize: '13px' }),
  multiValueLabel: (provided) => ({ ...provided }),
  multiValueRemove: (provided) => ({ ...provided }),
  input: (provided) => ({
    ...provided,
    '&:focus': {
      boxShadow: '',
    },
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: '8px 16px',
  }),
  option: (provided, state) => {
    const style = {
      ...provided,
      fontFamily: 'OpenSans-SemiBold',
      fontSize: '13px',
      backgroundColor: state.isSelected ? '#6879C4' : '',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: state.isSelected ? '#6879C4' : '#F8F8F8',
      },
    };
    if (state.data.color) style.color = state.data.color;
    return style;
  },
  clearIndicator: (provided) => ({
    ...provided,
    padding: '8px 0 ',
  }),
  indicatorSeparator: () => ({ display: 'none' }),
  indicatorsContainer: (provided) => ({
    ...provided,
    height: '50px',
  }),
};

const styleDark = {
  container: (provided) => ({ ...provided }),
  menu: (provided) => ({
    ...provided,
    overflow: 'hidden',
  }),
  menuList: (provided) => ({
    ...provided,
    paddingTop: '0px',
    paddingBottom: '0px',
  }),
  control: (provided) => ({
    ...provided,
    boxShadow: '0px 18px 32px rgba(208, 210, 218, 0.15)',
    borderColor: 'transparent',
    borderWidth: '0px',
    backgroundColor: '#DEE0EA',
    minHeight: '50px',
    borderRadius: '5px',
    cursor: 'pointer',
    '&:hover': {
      borderColor: '',
    },
  }),
  placeholder: (provided) => ({
    ...provided,
    fontFamily: 'OpenSans-SemiBold',
    fontSize: '13px',
    color: '#939393',
  }),
  valueContainer: (provided) => ({
    ...provided,
  }),
  singleValue: (provided) => ({ ...provided, fontSize: '13px', fontFamily: 'OpenSans-SemiBold' }),
  multiValue: (provided) => ({ ...provided, fontSize: '13px' }),
  multiValueLabel: (provided) => ({ ...provided }),
  multiValueRemove: (provided) => ({ ...provided }),
  input: (provided) => ({
    ...provided,
    '&:focus': {
      boxShadow: '',
    },
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: '8px 8px',
  }),
  noOptionsMessage: (provided) => ({
    ...provided,
    fontSize: '13px',
  }),
  option: (provided, state) => {
    const style = {
      ...provided,
      fontFamily: 'OpenSans-SemiBold',
      fontSize: '13px',
      backgroundColor: state.isSelected ? '#6879C4' : '',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: state.isSelected ? '#6879C4' : '#F8F8F8',
      },
    };
    if (state.data.color) style.color = state.data.color;
    return style;
  },
  clearIndicator: (provided) => ({
    ...provided,
    padding: '8px 0 ',
  }),
  indicatorSeparator: () => ({ display: 'none' }),
  indicatorsContainer: (provided) => ({
    ...provided,
    height: '50px',
  }),
};

export {
  styleBase,
  styleNoBorder,
  stylePrimary,
  styleDark,
  styleGrey,
};
