import React from 'react';
import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import styles from './input-email.module.scss';

const InputEmail = ({
  name,
  control,
  required = false,
  requiredMessage = 'Ce champs email est obligatoire',
  notValidMessage = 'Veuillez renseigner une adresse email valide',
  widthLabel = null,
  inline = false,
  disabled = false,
  label = null,
  placeholder = '',
  className,
}) => (
  <Controller
    name={name}
    control={control}
    rules={{
      required: required ? requiredMessage : false,
      pattern: {
        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
        message: notValidMessage,
      },
    }}
    render={({
      field: {
        // name,
        value = '',
        ref,
        onChange,
        onBlur,
      },
    }) => {
      function getStyle() {
        let resClass = styles['container-input-email'];
        if (className) {
          resClass += ` ${styles[className]}`;
        }
        if (inline) {
          resClass += ` ${styles.inline}`;
        }

        if (disabled) {
          resClass += ` ${styles.disabled}`;
        }
        return resClass;
      }

      return (
        <div
          className={getStyle()}
        >
          {(label && !disabled) && (
            <label style={widthLabel ? { minWidth: widthLabel } : {}}>{label}</label>
          )}
          <input
            ref={ref}
            value={value}
            onBlur={onBlur}
            onChange={(e) => onChange(e.target.value)}
            className={styles.input}
            type="email"
            placeholder={placeholder}
            disabled={disabled}
          />
        </div>
      );
    }}
  />
);

InputEmail.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  required: PropTypes.bool,
  requiredMessage: PropTypes.string,
  label: PropTypes.string,
  inline: PropTypes.bool,
  widthLabel: PropTypes.string,
  placeholder: PropTypes.string,
};

export default InputEmail;
