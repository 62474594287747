import axios from 'axios';
import {
  putData,
  postData,
  deleteData,
  API_URL,
} from './index.js';

import {
  INDICATOR_SET,
  INDICATOR_POST,
  INDICATOR_PUT,
  INDICATOR_DELETE,
  INDICATOR_LOADING,
  INDICATOR_ERROR,
  INDICATOR_SORT,
  INDICATORS_FIELDS,
  SET_TOAST,
  INDICATORS_ASSOCIETED_DB,
  SET_REQUEST_LOADING,
} from './types';

const setIndicator = async (dispatch, id) => {
  dispatch({
    type: INDICATOR_SET,
    payload: id,
  });
};

const getIndicatorsFieldsAction = async (dispatch, headers) => {
  const url = '/craft/fields';
  dispatch({
    type: SET_REQUEST_LOADING,
    payload: true,
  });
  const response = await postData(INDICATOR_ERROR, url, dispatch, headers, true);
  if (response?.data?.fields) {
    dispatch({
      type: INDICATORS_FIELDS,
      payload: response.data.fields,
    });
  }
  dispatch({
    type: SET_REQUEST_LOADING,
    payload: false,
  });
  return response.data.fields;
};

const getAssociatedDbAction = async (dispatch, headers) => {
  const url = '/craft/associated-data';
  const response = await postData(INDICATOR_ERROR, url, dispatch, headers, true);
  if (response?.data?.associatedDbs) {
    dispatch({
      type: INDICATORS_ASSOCIETED_DB,
      payload: response?.data?.associatedDbs,
    });
  }
  return response.data.associatedDbs;
};

const getAssociatedLabelsAction = async (data) => {
  const url = '/craft/associated-labels';
  const requestUrl = API_URL + url;
  const config = {};
  let res = null;
  const token = localStorage.getItem('token');
  config.headers = { Authorization: `${token}` };
  try {
    res = await axios.post(requestUrl, data, config);
    return res?.data;
  } catch (err) {
    console.log(err);
  }
  return res;
};

const createIndicator = async (dispatch, data) => {
  const url = '/indicators';
  dispatch({
    type: INDICATOR_LOADING,
    payload: INDICATOR_POST,
  });
  const response = await postData(INDICATOR_ERROR, url, dispatch, data, true);
  if (response.data?.indicator) {
    dispatch({
      type: INDICATOR_POST,
      payload: response.data.indicator,
    });
  }
  return response;
};

const updateIndicator = async (dispatch, data, id) => {
  const url = `/indicators/${id}`;
  dispatch({
    type: INDICATOR_LOADING,
    payload: `${INDICATOR_PUT}-${id}`,
  });
  const response = await putData(INDICATOR_ERROR, url, dispatch, data, true);

  if (response.data?.indicator) {
    dispatch({
      type: INDICATOR_PUT,
      payload: response.data,
    });
    dispatch({
      type: SET_TOAST,
      payload: {
        type: 'success',
        message: 'toaster.success.indicator.updated',
      },
    });
  }
  return response;
};

const deleteIndicator = async (dispatch, id) => {
  const url = `/indicators/${id}`;
  dispatch({
    type: INDICATOR_LOADING,
    payload: INDICATOR_DELETE,
  });
  const response = await deleteData(INDICATOR_ERROR, url, dispatch, true);
  if (response.status === 200) {
    dispatch({
      type: INDICATOR_DELETE,
      payload: id,
    });
  }
  return response;
};

const sortIndicators = async (dispatch, id, data) => {
  const url = `/indicators/sort/${id}`;
  dispatch({
    type: INDICATOR_LOADING,
    payload: INDICATOR_SORT,
  });
  const response = await postData(INDICATOR_ERROR, url, dispatch, data, true);
  if (response.data?.indicators) {
    dispatch({
      type: INDICATOR_SORT,
      payload: response.data.indicators,
    });
  }
  return response;
};

export {
  setIndicator,
  createIndicator,
  updateIndicator,
  deleteIndicator,
  sortIndicators,
  getIndicatorsFieldsAction,
  getAssociatedDbAction,
  getAssociatedLabelsAction,
};
