import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';
import FilterRow from '../../../../components/FilterRow';
import FilterModal from '../../../../components/FilterModal/FilterModal';
import Indicator from '../../../../components/Graphs/Indicator';
import { ListTags } from '../../../../lib/HooksFormFields';
import ModalStandalone from '../../../../lib/ModalStandalone';

import styles from './Filters.module.scss';
import generateEntitiesData from '../../../../utils/generateEntitiesData';
import generateAssociatedElementsData from '../../../../utils/generateAssociatedElementsData';

const Filters = () => {
  const {
    watch, options, setValue, control,
  } = useOutletContext();
  const { t } = useTranslation();
  const [filter, setFilter] = useState();
  const selectedName = watch('config.params.name');
  const selectedValue = watch('config.params.value');
  const config = watch('config');
  const graphType = watch('graph');
  const data = watch('data');
  const filters = watch('config.filters') || [];
  const indicator = watch();
  const modalRef = useRef(null);
  const isRelative = selectedValue?.value === 'COUNT_RELATIVE';

  function editFilter(values) {
    modalRef?.current.open();
    setFilter(values);
  }

  async function handleChangeData() {
    const isAssociatedDb = config?.params?.name.parent;
    let generatedData = null;
    const associatedConfig = {
      ...config,
      associetedConfig: {
        api: watch('api')?.value,
        key: watch('dataset')?.value,
      },
    };
    if (!isAssociatedDb || config.isAssociatedDb) {
      generatedData = await generateEntitiesData(
        options,
        associatedConfig,
        graphType,
        data,
      );
    } else {
      generatedData = await generateAssociatedElementsData(
        options,
        associatedConfig,
        graphType,
        data,
      );
    }
    if (generatedData?.stacked) {
      setValue('config.legend.stacked', generatedData.stacked);
    }
    if (generatedData?.data) {
      setValue('data', generatedData.data);
    }
  }

  useEffect(() => {
    if (options?.data
        && graphType
        && selectedName?.value
        && selectedValue?.value
    ) {
      handleChangeData();
    }
  }, [
    options?.data,
    config,
    filters,
    config?.reverseAxis,
    config?.ascending,
    config?.ascendingLabel,
    config?.splitWords,
    config?.dateFormat,
    config?.params?.relative,
    config?.params?.relative?.dateFormat,
    config?.regex,
    selectedName,
    selectedValue,
    graphType,
  ]);

  return (
    <div className={styles.container}>
      {!selectedName?.value?.includes('aggregation.')
        && <>
        <h2>{t('indicator.filters.title')}</h2>
        {filters.length > 0
          && <div className={styles.filters}>
            <div className={`${styles.row} ${styles.labels}`}>
              <div className={styles.col}>
                {t('indicator.filters.field')}
              </div>
              <div className={styles.col}>
                {t('indicator.filters.name')}
              </div>
              <div className={styles.col}>

              </div>
              <div className={styles.col}>

              </div>
            </div>
            {filters.map((f) => <FilterRow
              key={f.id}
              filter={f}
              styles={styles}
              editFilter={() => editFilter(f)}
              deleteFilter={() => setValue('config.filters', filters.filter((fi) => fi.id !== f.id))}
            />)}
          </div>
        }
          <button
            type="button"
            className='secondary'
            onClick={() => modalRef?.current.open()}
          >
            {t('indicator.filters.add')}
          </button>
        </>
      }
      {(graphType === 'histogram' || graphType === 'lines')
        && <>
          <h2>{t('indicator.filters.options')}</h2>
          <div className={styles.row}>
            <div className={styles.col}>
              <ListTags
                name="config.reverseAxis"
                control={control}
                label={t('indicator.params.axis')}
                tags={[
                  { label: t('options.yes'), value: true },
                  { label: t('options.no'), value: false },
                ]}
              />
            </div>
            {graphType === 'histogram'
              && <div className={styles.col}>
                <ListTags
                    name="config.ascending"
                    control={control}
                    label={isRelative ? t('indicator.params.orderValue') : t('indicator.params.order')}
                    callBack={() => setValue('config.ascendingLabel', null)}
                    tags={[
                      { label: t('options.desc'), value: true },
                      { label: t('options.asc'), value: false },
                    ]}
                  />
              </div>
            }
            <div className={styles.col}>
              <ListTags
                  name="config.ascendingLabel"
                  control={control}
                  label={graphType === 'lines' ? t('indicator.params.order') : t('indicator.params.orderLabel')}
                  tags={[
                    { label: t('options.desc'), value: true },
                    { label: t('options.asc'), value: false },
                  ]}
                  callBack={() => setValue('config.ascending', null)}
              />

            </div>
          </div>
        </>
      }
      {indicator?.data
        && <div className={styles.graph}>
            <Indicator indicator={indicator} isFilter={true} />
        </div>
      }
      <ModalStandalone ref={modalRef} closeModal={() => setFilter()}>
        <FilterModal
          indicator={watch}
          filter={filter}
          options={options}
          closeModale={() => modalRef?.current.close()}
          handleChangeFilters={(array) => setValue('config.filters', array)}
        />
      </ModalStandalone>
    </div>
  );
};

export default Filters;
