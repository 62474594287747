import * as React from 'react';

const TreemapIcon = ({ selected, ...rest }) => (
  <svg
    width={39}
    height={32}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <rect width={29} height={23} rx={1} fill={selected ? '#FFFFFF' : '#6879C4' } />
    <rect x={31} width={8} height={23} rx={1} fill={selected ? '#FFFFFF' : '#6879C4' } />
    <rect y={25} width={8} height={7} rx={1} fill={selected ? '#FFFFFF' : '#6879C4' } />
    <rect x={10} y={25} width={8} height={7} rx={1} fill={selected ? '#FFFFFF' : '#6879C4' } />
    <rect x={21} y={25} width={8} height={7} rx={1} fill={selected ? '#FFFFFF' : '#6879C4' } />
    <rect x={31} y={25} width={8} height={7} rx={1} fill={selected ? '#FFFFFF' : '#6879C4' } />
  </svg>
);

export default TreemapIcon;
